import {
  areInConflict,
  dateAdd,
  TimeInterval,
} from '../../../../utility/DateTime';
import { optionsType } from '../../SelectDropdown/types';
import { TimeRangeType } from './Dropdown/types';

const ONE_HOUR: number = 60 * 60 * 1000; /* ms */

export const START_END_SEPARATOR = ' - ';
export const ALL_DAY_VALUE = `00:00${START_END_SEPARATOR}00:00`;

export const fillWithZeros = (number: number) =>
  number < 10 ? `0${number}` : `${number}`;

export const getNextTimeSlot = (val: string) => {
  const zero = new Date(0);
  const parts = val.split(':');
  const hours = Number(parts[0]);
  const minutes = Number(parts[1]);
  zero.setHours(hours, minutes);
  const nextHour: Date = new Date(zero.getTime() + 1 * ONE_HOUR);
  return `${fillWithZeros(nextHour.getHours())}:${fillWithZeros(
    nextHour.getMinutes()
  )}`;
};
export interface TimeRangeOptions {
  day: Date;
  start?: number;
  end?: number;
  busySlots?: Array<TimeInterval>;
}

const BUSY_STYLE = { color: 'red' };
const STEP = { minutes: 30 };

// const fixSlots = (minutes: number, slots?: Array<TimeInterval>) => {
//   if (!slots) return [];
//   minutes = 0;
//   const FIX = { minutes };
//   return slots.map((s: TimeInterval) => ({
//     start: dateAdd(s.start, FIX),
//     end: dateAdd(s.end, FIX),
//   }));
// };

export const calculateOptions = (
  options: TimeRangeOptions
): Array<optionsType> => {
  const { day, start = 0, end = 24, busySlots } = options;
  const opts: Array<optionsType> = [];
  let startTime = new Date(day);
  startTime.setHours(start, 0, 0, 0);
  let endTime = dateAdd(startTime, STEP);

  const searchForConflicts = busySlots && busySlots.length !== 0;

  // const fixedSlots = fixSlots(diffMinutes, busySlots);
  const fixedSlots = busySlots ? [...busySlots] : [];

  while (endTime.getHours() < end && endTime.getDay() === day.getDay()) {
    const currentHour = startTime.getHours();
    let isInConflict: boolean = !busySlots;
    if (searchForConflicts) {
      const interval: TimeInterval = {
        end: endTime,
        start: startTime,
      };
      isInConflict = fixedSlots.some((slot: TimeInterval) =>
        // isWithinInterval(slot.end, interval) || isWithinInterval(slot.start, interval)
        areInConflict(interval, slot)
      );
    }
    const val: string = `${fillWithZeros(currentHour)}:${fillWithZeros(
      startTime.getMinutes()
    )}`;
    opts.push({
      label: val,
      value: val,
      style: isInConflict ? BUSY_STYLE : undefined,
    });
    startTime = new Date(endTime);
    endTime = dateAdd(startTime, STEP);
  }
  return opts;
};

export const ALL_DAY = {
  start: '00:00',
  end: '00:00',
};

export const initValues = (now: Date | null) => {
  if (!now) return ALL_DAY;

  const nextHour = new Date(now.getTime() + 1 * ONE_HOUR).getHours();
  const start = `${fillWithZeros(nextHour)}:00`;
  return {
    start,
    end: getNextTimeSlot(start),
  };
};
export const printValue = (value: TimeRangeType | string) => {
  const val: TimeRangeType = loadValue(value);
  return checkedAllDay(/* val */) ? 'All day ' : transformValue(val);
};

export const transformValue = (value: TimeRangeType) =>
  `${value.start}${START_END_SEPARATOR}${value.end}`;

export const loadValue = (
  value: string | TimeRangeType | null | undefined
): TimeRangeType => {
  if (!value) return ALL_DAY;
  if ((value as TimeRangeType).start) return value as TimeRangeType;
  const parts = (value as string).split(START_END_SEPARATOR);
  return {
    start: parts[0],
    end: parts[1],
  } as TimeRangeType;
};

export const getHoursAndMinutes = (value: string) => {
  const v = value.split(':');
  return {
    hours: Number(v[0]),
    minutes: Number(v[1]),
  };
};

export const checkedAllDay = () => {
  // return newValue.start === '00:00' && newValue.end === '00:00';
  // TEMPORARY FIX DISABLING ALL DAY
  return false;
};

export const getNextTimeSlice = (now?: Date) => {
  const d = now || new Date();
  if (d.getMinutes() < 30) d.setMinutes(30, 0, 0);
  else d.setUTCHours(d.getUTCHours() + 1, 0, 0, 0);
  return d;
};

export const getDay = (now?: Date) => {
  const d = now || new Date();
  d.setUTCHours(0, 0, 0, 0);
  return d;
};
