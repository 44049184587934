import { FetchResult } from 'apollo-link';
import { apolloClient } from '../../../../../utility/ApolloConfig';
import { addContactShipToToCallListMutation } from '../../../../../mutations/app/add.contact.Ship.to.to.call.list/add.contact.ship.to.to.call.list.mutation';
import { addShipToToCallListInterface } from '../../../../../mutations/app/add.ship.to.to.call.list/app.add.ship.to.to.call.list.mutation.interface';
import { UpdatableCallList } from '../../interfaces';

export const apiAddContactShipToToCallList = (
  queryParams: UpdatableCallList
): Promise<addShipToToCallListInterface | null | undefined> => {
  const { updatableCallList } = queryParams;
  return apolloClient
    .mutate({
      mutation: addContactShipToToCallListMutation,
      variables: { updatableCallList },
    })
    .then(
      (response: FetchResult<addShipToToCallListInterface>) => response.data
    );
};
