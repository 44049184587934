import React, { FC } from 'react';
import cryptoRandomString from 'crypto-random-string';
import { Box, Button, Typography } from '@mui/material';
import jwt_decode, { InvalidTokenError } from 'jwt-decode';
import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import cfg from '../config/config';

// eslint-disable-next-line no-shadow
export enum LOCAL_STORAGE_KEYS {
  AUTH_RETRY_COUNT = 'auth_retry_count',
  AUTH_ERROR = 'auth_error',
}

const RedirectHandler: FC = () => {
  const hashParams = window.location.hash;
  const codeKeyIndex = hashParams.indexOf('code=');
  const firstDividerIndex = hashParams.indexOf('&');
  const code =
    codeKeyIndex !== -1
      ? hashParams.slice(codeKeyIndex + 5, firstDividerIndex)
      : undefined;
  const idTokenKeyIndex = hashParams.indexOf('id_token=');
  const secondDividerIndex = hashParams.indexOf('&', firstDividerIndex + 1);
  const idToken =
    idTokenKeyIndex !== -1
      ? hashParams.slice(idTokenKeyIndex + 9, secondDividerIndex)
      : undefined;

  if (code && idToken) {
    localStorage.setItem('authorization_code', code);
    localStorage.setItem('id_token', idToken);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_ERROR);
  } else {
    // eslint-disable-next-line no-console
    console.warn('Missing information in auth callback');
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.AUTH_ERROR,
      `Missing data. Expected both id_token and code to be present in hashParams in callback, instead received: ${JSON.stringify(
        hashParams
      )}`
    );
  }

  const url = localStorage.getItem('previous_url');
  if (url) {
    localStorage.removeItem('previous_url');
    window.location.href = url;
  } else {
    window.location.pathname = '/';
  }

  return <Loading message="Handling redirect" />;
};

export const AuthorizationError: FC = () => {
  const errorMessage = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_ERROR);

  const clearAuthErrorInSession = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_ERROR);
    resetAuthenticationRetryCount();
    handleAuthenticationRequest();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100vh"
      p={10}
    >
      <Typography align="center" p={4}>
        {errorMessage || 'Authentication failed'}
      </Typography>
      <Button sx={{ width: 500 }} onClick={clearAuthErrorInSession}>
        Retry
      </Button>
    </Box>
  );
};

const setCurrentUrl = () => {
  const { href } = window.location;
  localStorage.setItem('previous_url', href);
};

export const handleAuthenticationRequest = () => {
  const { tenantId, clientId } = cfg.getConfig().auth;
  const redirectUri =
    localStorage.getItem('redirect_uri') || cfg.getConfig().auth.redirectUri;

  setCurrentUrl();

  const nonce = cryptoRandomString({ length: 10, type: 'url-safe' });
  const state = cryptoRandomString({ length: 10, type: 'url-safe' });

  const baseUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize`;
  const url = `${baseUrl}?client_id=${clientId}&response_type=${encodeURI(
    'code id_token'
  )}&response_mode=fragment&scope=${encodeURI(
    'openid offline_access https://graph.microsoft.com/Calendars.ReadWrite profile'
  )}&nonce=${nonce}&state=${state}&redirect_uri=${encodeURI(redirectUri)}`;

  window.location.href = url;
};

type TokenResponse = {
  preferred_username: string;
  exp: number;
  oid: string;
};

const getDecodedToken = (token: string) => {
  let decodedToken: undefined | TokenResponse;

  try {
    decodedToken = jwt_decode<TokenResponse>(token);
  } catch (e) {
    if (e instanceof InvalidTokenError) {
      decodedToken = undefined;
    } else {
      throw e;
    }
  }
  return decodedToken;
};

export const tokenIsAboutToExpire = (token: string) => {
  const decodedToken = getDecodedToken(token);

  if (!decodedToken) {
    return true;
  }

  // Token expires after one hour, but we refresh it 15 minutes before it expires so we can silently refresh it with the BFF
  return Date.now() >= (decodedToken.exp - 60 * 15) * 1000;
};

export const tokenIsExpired = (token: string) => {
  const decodedToken = getDecodedToken(token);

  if (!decodedToken) {
    return true;
  }

  const now = new Date();
  const expirationDate = new Date(decodedToken.exp * 1000);
  return now >= expirationDate;
};

const isTokenValid = () => {
  const authorizationCode = localStorage.getItem('authorization_code');
  const idToken = localStorage.getItem('id_token');

  if (!authorizationCode || !idToken || tokenIsExpired(idToken)) {
    return false;
  }

  return true;
};

export const getAndAddAuthenticationRetryCount = () => {
  const retryCount = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_RETRY_COUNT);
  const newRetryCount = retryCount ? Number(retryCount) + 1 : 1;
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.AUTH_RETRY_COUNT,
    newRetryCount.toString()
  );
  return newRetryCount;
};

const resetAuthenticationRetryCount = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_RETRY_COUNT);
};

const AuthorizationInitialization: FC = ({ children }) => {
  const validToken = isTokenValid();
  const retryCount = getAndAddAuthenticationRetryCount();

  if (!validToken && (!retryCount || Number(retryCount) < 2)) {
    handleAuthenticationRequest();
    return <Loading message="Authenticating" />;
  }

  if (!validToken) {
    return <AuthorizationError />;
  }

  setTimeout(() => resetAuthenticationRetryCount(), 10000);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const Authorization: FC = ({ children }) => {
  if (
    window.location.pathname.includes('bugfix') ||
    window.location.pathname.includes('feature')
  ) {
    // https://salestooldev-customer03.k8s.bestcorp.net/bugfix/bstn-2985-change-auth/businessTradingPartner/showBTPMyCustomerList
    // Take the hostname and the first two path segments
    const pathSegments = window.location.pathname.split('/');
    const redirectUri = `${window.location.protocol}//${window.location.hostname}/${pathSegments[1]}/${pathSegments[2]}/AuthCallback`;
    localStorage.setItem('redirect_uri', redirectUri);
  } else {
    localStorage.setItem('redirect_uri', cfg.getConfig().auth.redirectUri);
  }

  if (window.location.hash.includes('id_token')) {
    return <RedirectHandler />;
  }

  return <AuthorizationInitialization>{children}</AuthorizationInitialization>;
};

export default Authorization;
