import { StringMultiSelect } from '@bestseller-bit/sales-and-invoicing.input.string-multiselect';
import { SelectableValue } from '@bestseller-bit/sales-and-invoicing.input.string-multiselect/dist/string-multiselect';
import { difference, isEmpty } from 'lodash';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Brand } from '../../../../@types/branding/branding.types';
import { PhasePriceNotification } from './PhasePriceNotification';

type Props = {
  allPhasePrices: PhasePriceNotification[];
  setAllPhasePrices: Dispatch<SetStateAction<PhasePriceNotification[]>>;
  brandRows: Brand[];
};

const BrandMultiSelect: FC<Props> = ({
  allPhasePrices,
  setAllPhasePrices,
  brandRows,
}) => {
  const allBrandOptions: SelectableValue[] = brandRows.map((brand) => {
    return { value: brand.publicId, name: brand.name };
  });
  const [selectedValues, setSelectedValues] = useState<string[]>(
    allPhasePrices.map((elem) => elem.brand.publicId)
  );

  useEffect(() => {
    setSelectedValues(allPhasePrices.map((elem) => elem.brand.publicId));
  }, [allPhasePrices]);

  const setSelectionHandler = (payload: {
    valuesToAdd?: string[];
    valuesToRemove?: string[];
  }) => {
    const { valuesToAdd, valuesToRemove } = payload;
    const newPhasePrices = [...allPhasePrices];
    if (valuesToAdd && valuesToRemove) {
      if (valuesToAdd && !isEmpty(valuesToAdd)) {
        setSelectedValues([...selectedValues, ...valuesToAdd]);
        // Adding new brand
        const newBrand = brandRows.find(
          (brand) => brand.publicId === valuesToAdd[0]
        );
        if (newBrand) {
          setAllPhasePrices([
            ...newPhasePrices,
            { brand: newBrand, productLines: [] },
          ]);
        }
      }
      if (valuesToRemove && !isEmpty(valuesToRemove)) {
        // Removing brands
        setSelectedValues(difference(selectedValues, valuesToRemove));
        setAllPhasePrices(
          newPhasePrices.filter(
            (phasePriceRow) =>
              phasePriceRow.brand.publicId !== valuesToRemove[0]
          )
        );
      }
    }
  };

  return (
    <StringMultiSelect
      label="Brands"
      selectionState={[selectedValues, setSelectionHandler]}
      allSelectableItems={allBrandOptions}
    />
  );
};

export default BrandMultiSelect;
