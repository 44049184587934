import { ApolloQueryResult } from '@apollo/client';
import { getBrandingQuery } from '../../../../../queries/shared/organization/branding/app.branding.query';
import { getBrandingQueryResultInterface } from '../../../../../queries/shared/organization/branding/app.branding.query.result.interface';
import { apolloClient } from '../../../../../utility/ApolloConfig';

export const apiGetBranding = () => {
  return apolloClient
    .query({
      query: getBrandingQuery,
      context: {
        clientName: 'java',
      },
    })
    .then(
      (response: ApolloQueryResult<getBrandingQueryResultInterface>) =>
        response.data
    );
};
