import {
  apiRegisterContactCall,
  registerContactCallQueryProps,
} from './api/register.contact.call.api';
import {
  UpdatableCallList,
  UpdatableCallListObject,
} from '../../shared/call.list/interfaces';
import { addToCallList } from '../../../application/Shared/_utilities/call.list/call.list.utils/call.list.utils';

export const registerContactCallService = (
  queryParams: registerContactCallQueryProps
) => {
  return apiRegisterContactCall(queryParams).then((response: any) => {
    return response;
  });
};

export const registerCallCheckingCallListService = (
  lastCallParams: registerContactCallQueryProps,
  updateParams: UpdatableCallListObject,
  contactType: string,
  contactTypeDisplayName: string,
  calledFromCallList: boolean
) => {
  return new Promise((resolve) => {
    registerContactCallService(lastCallParams).then(() => {
      if (calledFromCallList === true) {
        // eslint-disable-next-line no-param-reassign
        updateParams.lastCallDate = new Date();
        const updatableCallList: UpdatableCallList = {
          updatableCallList: [updateParams],
        };
        addToCallList(
          contactType,
          contactTypeDisplayName,
          updatableCallList
        ).then((result) => {
          resolve(result);
        });
      } else {
        resolve(null);
      }
    });
  });
};
