export function isNumeric(str: string) {
  return !Number.isNaN(Number(str)) && !Number.isNaN(parseFloat(str));
}

export default function toInteger(dirtyNumber: number) {
  const number = Number(dirtyNumber);
  if (Number.isNaN(number)) {
    return number;
  }
  return number < 0 ? Math.ceil(number) : Math.floor(number);
}
