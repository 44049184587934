import gql from 'graphql-tag';

export const registerContactCallMutation = gql`
  mutation registerContactCall(
    $contactPublicId: String!
    $contactPhonePublicId: String!
  ) {
    registerContactCall(
      contactPublicId: $contactPublicId
      contactPhonePublicId: $contactPhonePublicId
    ) {
      contactPublicId
      contactPhonePublicId
      contactName
      countryCode
      phoneNumber
      lastCallDate
    }
  }
`;
