import { ApolloClient, gql } from '@apollo/client';
import {
  dateAdd,
  DateOptions,
  diffInDays,
  TIME_ZONE_NAME,
} from '../../utility/DateTime';
import logger from '../../utility/ddLogger';
import { OutlookEvent } from './types';
import {
  GetOutlookEvents,
  GetOutlookEventsVariables,
} from './__generated__/GetOutlookEvents';

const GET_OUTLOOK_EVENTS_QUERY = gql`
  query GetOutlookEvents(
    $startTime: DateTimeInputOutlook!
    $endTime: DateTimeInputOutlook!
  ) {
    getEvents(startTime: $startTime, endTime: $endTime) {
      id
      startTime
      endTime
      subject
    }
  }
`;

// NB! This is temporary! As soon as we are done with the refactor,
// the only representation of an OutlookEvent that should exist is the one
// expected by the BFF
export const convertOutlookEvent = (event: OutlookEvent) => ({
  subject: event.subject,
  startTime: {
    timeStamp: event.start.dateTime.toISOString(),
    timeZone: event.start.timeZone,
  },
  endTime: {
    timeStamp: event.end.dateTime.toISOString(),
    timeZone: event.end.timeZone,
  },
});

export default class OutlookService {
  static getEventsForADate = async (
    date: Date,
    apolloClient: ApolloClient<object>
  ): Promise<Array<OutlookEvent>> => {
    try {
      if (!date) throw new Error('Undefined date (time interval)');
      const startTime = dateAdd(DateOptions.getStartOfTheDay(date), {
        seconds: 1,
      });
      const endTime = dateAdd(startTime, {
        hours: 23,
        minutes: 59,
        seconds: 59,
      });

      const result = await apolloClient.query<
        GetOutlookEvents,
        GetOutlookEventsVariables
      >({
        query: GET_OUTLOOK_EVENTS_QUERY,
        variables: {
          startTime: {
            timeStamp: `${startTime.getTime()}`,
            timeZone: TIME_ZONE_NAME,
          },
          endTime: {
            timeStamp: `${endTime.getTime()}`,
            timeZone: TIME_ZONE_NAME,
          },
        },
      });

      const outlookEvents = result.data?.getEvents;

      if (!outlookEvents)
        throw new Error('Unexpected response from Outlook: undefined');
      return outlookEvents.map((outlookEvent: any) => {
        const startDate = new Date(parseInt(outlookEvent.startTime, 10));
        const endDate = new Date(parseInt(outlookEvent.endTime, 10));
        return {
          end: {
            dateTime: endDate,
            timeZone: TIME_ZONE_NAME,
          },
          start: {
            dateTime: startDate,
            timeZone: TIME_ZONE_NAME,
          },
          isAllDay: diffInDays(endDate, startDate) !== 0,
          subject: outlookEvent.subject,
        };
      });
    } catch (e) {
      logger.log({
        level: 'error',
        message: 'outlook.service getEventsForADate failed',
        context: {
          method: 'OutlookService.getEventsForADate',
          error: e,
        },
      });
      throw e;
    }
  };
}
