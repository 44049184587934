import { Dispatch, AnyAction } from 'redux';
import { Action } from './action.interface';

let subscriptionId = 0;

export interface callBackQueueElemement {
  subscriptionid: number;
  callback: Function;
  actions: string[];
}

let callbackOncetimeSubscriptions: callBackQueueElemement[] = [];
const callbackContinuousSubscriptions: callBackQueueElemement[] = [];

export const createOnetimeSubscription = (actions: string[], cb: Function) => {
  subscriptionId += 1;
  const subscription = {
    subscriptionid: subscriptionId,
    callback: cb,
    actions,
  };
  callbackOncetimeSubscriptions.push(subscription);
  return subscription;
};
export const creteContinuousSubscription = (
  actions: string[],
  cb: Function
) => {
  subscriptionId += 1;
  const subscription = {
    subscriptionid: subscriptionId,
    callback: cb,
    actions,
  };
  callbackContinuousSubscriptions.push(subscription);
  return subscription;
};
/*
Simple example of how to use the callback methods
const myCallBack = (subscription,actionName,action) => {
    console.log('Callback ' + actionName);
}
let sub1 = createOnetimeSubscription(['BESTONE_AUTH_GET_USER_INFO_PENDING_DONE'],myCallBack);
let sub2 = creteContinuousSubscription(['BESTONE_AUTH_GET_USER_INFO_PENDING_DONE'],myCallBack);
*/
export const subscriptionMiddleware =
  () =>
  (next: Dispatch<AnyAction>) =>
  <A extends Action>(action: A) => {
    let callbackClients = callbackOncetimeSubscriptions
      .filter(
        (subscription) => subscription.actions.indexOf(action.type) !== -1
      )
      .slice(0);
    callbackOncetimeSubscriptions = callbackOncetimeSubscriptions.filter(
      (subscription) => subscription.actions.indexOf(action.type) === -1
    );
    callbackClients.forEach((subscription) =>
      subscription.callback(subscription, action.type, action)
    );
    callbackClients = callbackContinuousSubscriptions
      .filter(
        (subscription) => subscription.actions.indexOf(action.type) !== -1
      )
      .slice(0);
    callbackClients.forEach((subscription) =>
      subscription.callback(subscription, action.type, action)
    );
    return next(action);
  };
