export const BESTONE_LOGOUT_CLICKED = 'BESTONE_LOGOUT_CLICKED';
export const IMPERSONATE_USER = 'IMPERSONATE_USER';
export const STOP_IMPERSONATE_USER = 'STOP_IMPERSONATE_USER';
export const SHOW_ALL_COUNTRIES = 'SHOW_ALL_COUNTRIES';
export const CHECK_IS_DESKTOP_SCREEN_SIZE = 'CHECK_IS_DESKTOP_SCREEN_SIZE';
export const IS_ONLINE = 'IS_ONLINE';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_ROUTE_ID = 'SET_ROUTE_ID';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
