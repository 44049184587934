import gql from 'graphql-tag';
import { CallListResultFragment } from '../../../queries/shared/call.list/app.btp.call.list.query';

// export const addBTPToCallListMutation = gql`
//   mutation addBusinessTradingPartnerToCallList($updatableCallList: [UpdatableCallList]) {
//     addBusinessTradingPartnerToCallList(updatableCallList: $updatableCallList) {
//       queryCount
//       result {
//         createdByUserName
//         createdDate
//         contactType
//         contactTypeDisplayName
//         callListRelationPublicId
//         number
//         name
//         contactPublicId
//         contactName
//         contactPhoneNumber {
//           publicId
//           countryPublicId
//           countryCode
//           phoneNumber
//           isPrimaryPhone
//           changedBy
//           changedDate
//         }
//         note
//         lastCallDate
//       }
//     }
//   }
// `;

export const addBTPToCallListMutation = gql`
  mutation addBusinessTradingPartnerToCallList(
    $updatableCallList: [UpdatableCallList]
  ) {
    addBusinessTradingPartnerToCallList(updatableCallList: $updatableCallList) {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;
