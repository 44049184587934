import { ServerError, ServerParseError } from 'apollo-link-http-common';

export const apolloGraphQLErrorHandler = (graphQLErrors: Readonly<any>) => {
  const { message, locations, path, extensions } = graphQLErrors[0];
  // eslint-disable-next-line no-console
  console.error(
    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${extensions?.code}`
  );
};

const isKnownNetworkError = (networkError: any) =>
  networkError.message.startsWith('Failed to fetch') ||
  networkError.message.startsWith('Service Unavailable');
const isCodingError = (networkError: any) =>
  networkError.message.startsWith('Bad Request') ||
  `${networkError}`.startsWith('TypeError');
const isUnauthorizedError = (message: string) =>
  message === 'Unauthorized' ||
  message.includes('Unauthorized') ||
  message.includes('401');

export const apolloApiErrorHandler: (
  networkError: Error | ServerError | ServerParseError
) => [number, string] = (networkError) => {
  const serverError = networkError as ServerError;
  const serverParseError = networkError as ServerParseError;
  let { message } = networkError;
  let statusCode = 555;
  if (serverError.response) {
    message = serverError.message;
    statusCode = serverError.statusCode;
  } else if (serverParseError.response) {
    message = serverParseError.bodyText;
    statusCode = serverParseError.statusCode;
  } else if (isUnauthorizedError(`${message}`)) {
    statusCode = 401;
  } else if (isKnownNetworkError(networkError)) {
    statusCode = 503;
  } else if (isCodingError(networkError)) {
    statusCode = 400;
  }
  // eslint-disable-next-line no-console
  console.debug(`Parsing error ${networkError} to [${statusCode}:${message}]`);
  return [statusCode, message];
};
