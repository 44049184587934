import { FetchResult } from 'apollo-link';
import { apolloClient } from '../../../../utility/ApolloConfig';
import { stopImpersonateUserMutation } from '../../../../mutations/app/user.stop.impersonate/app.user.stop.impersonate.mutation';
import { stopUserImpersonate } from '../../../../mutations/app/user.stop.impersonate/app.user.stop.impersonate.mutation.interface';

const apiStopUserImpersonate = () => {
  return apolloClient
    .mutate({
      mutation: stopImpersonateUserMutation,
    })
    .then((response: FetchResult<stopUserImpersonate>) => response.data);
};

export default apiStopUserImpersonate;
