import { Action } from '../../../middleware/helpers/action.interface';
import {
  GET_BRANDING,
  RESET_BRANDING_VALUES,
  GET_MARKET,
} from '../../../constants/shared/app.branding.constants';
import {
  Brand,
  Market,
  ProductLine,
  SubBrand,
} from '../../../@types/branding/branding.types';

export interface appBrandingStateInterface {
  brandRows: [Brand] | [];
  subBrandRows: [SubBrand] | [];
  productLineRows: [ProductLine] | [];
  marketRows: [Market] | [];
  productLinesSelected: [string] | [];
  marketSelected: string;
  priceListCountrySelected: string;
  customerGroupSelected: string;
}

export const defaultState: appBrandingStateInterface = {
  brandRows: [],
  subBrandRows: [],
  productLineRows: [],
  marketRows: [],
  productLinesSelected: [],
  marketSelected: '',
  priceListCountrySelected: '',
  customerGroupSelected: '',
};

// eslint-disable-next-line default-param-last
export const brandingReducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case GET_BRANDING: {
      return {
        ...state,
        brandRows: action.payload.BRAND ? action.payload.BRAND.result : [],
        subBrandRows: action.payload.SUB_BRAND
          ? action.payload.SUB_BRAND.result
          : [],
        productLineRows: action.payload.PRODUCT_LINE
          ? action.payload.PRODUCT_LINE.result
          : [],
      };
    }
    case GET_MARKET: {
      return {
        ...state,
        marketRows: action.payload.MARKET ? action.payload.MARKET.result : [],
      };
    }
    case RESET_BRANDING_VALUES: {
      return {
        ...state,
        brandRows: [],
        subBrandRows: [],
        productLineRows: [],
        marketSelected: '',
        priceListCountrySelected: '',
      };
    }
    default:
      return state;
  }
};
