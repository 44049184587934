import gql from 'graphql-tag';
import { CallListResultFragment } from '../../../queries/shared/call.list/app.btp.call.list.query';

export const addContactShipToToCallListMutation = gql`
  mutation addContactShipToToCallList($updatableCallList: [UpdatableCallList]) {
    addContactShipToToCallList(updatableCallList: $updatableCallList) {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;
