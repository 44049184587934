import React, { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Button,
  Checkbox,
  Typography,
  IconButton,
  SxProps,
  styled,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { green, grey } from '@mui/material/colors';
import SelectPrimaryPhone from '../contact.phonelist/mui.contact.phonelist.select';
import { muiRenderEditableNoteColumnCallList } from '../tables/render.table.columns/mui.render.editable.note.column.call.list';
import EditExternalContact from '../manage.contacts/mui.edit.external.contacts';
import { store } from '../../../../middleware/store';
import { registerContactCallService } from '../../../../services/mutations/register.contact.call/register.contact.call.services';
import { REFRESH_WHEN_REGISTER_CALL } from '../../../../constants/app/app.show.btp.mycustomers.page.constants';
import CallListActions from '../call.list/call.list.actions';
import { DateOptions } from '../../../../utility/DateTime';
import { CallListTypeForCustomTable } from '../call.list/mui.call.list.table';

type PropsType = {
  loadData: CallListTypeForCustomTable[];
  salesPersonPublicId: string;
  handleClickRedirection: (row: CallListTypeForCustomTable) => void;
  addRowToSelected: (row: CallListTypeForCustomTable) => void;
  isSelected: (callList: CallListTypeForCustomTable) => boolean;
  isDeleteting: boolean;
};

const MuiCallListPanelHelper: FC<PropsType> = ({
  loadData,
  salesPersonPublicId,
  handleClickRedirection,
  addRowToSelected,
  isSelected,
  isDeleteting,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [rowValue, setRowValue] = React.useState();

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  }));

  const EditButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
    '&:hover': {
      backgroundColor: grey[700],
    },
  }));

  const handleEdit = (rowData: any) => {
    setOpen(true);
    setRowValue(rowData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modal =
    open && rowValue !== undefined ? (
      <EditExternalContact
        rowData={rowValue}
        openModal={[open, setOpen]}
        onClose={handleClose}
      />
    ) : null;

  function handleClickToCall(
    stringToCall: string,
    phoneId: string,
    index: number,
    contactPublicId: string
  ) {
    return (
      <ColorButton
        key={`whatsapp_${index}`}
        href={`https://wa.me/${
          stringToCall.charAt(0) === '+' ? stringToCall.substr(1) : stringToCall
        }`}
        onClick={() => handleLastCall(phoneId, contactPublicId)}
      >
        <WhatsAppIcon style={{ color: 'white' }} />
      </ColorButton>
    );
  }
  function handleLastCall(phoneId: string, contactPublicId: string) {
    const lastCallParams = {
      contactPhonePublicId: phoneId,
      contactPublicId,
    };
    registerContactCallService(lastCallParams).then(() =>
      store.dispatch({ type: REFRESH_WHEN_REGISTER_CALL })
    );
  }

  return loadData.length <= 0 ? (
    <Typography variant="body1">No contacts, please add them...</Typography>
  ) : (
    <>
      {loadData.map((row: CallListTypeForCustomTable) => {
        // const removeRowRender = RenderRemoveCallListContact({ rowData: row });
        const noteCell = muiRenderEditableNoteColumnCallList({
          rowData: row,
          cellData: row.note,
        });
        const phoneString = row.contactPhoneNumber.map(
          (phone: any, index: number) => {
            if (phone.isPrimaryPhone) {
              const stringToCall = `${phone.countryCode}${phone.phoneNumber}`;
              return handleClickToCall(
                stringToCall,
                phone.publicId,
                index,
                row.contactPublicId
              );
            }
            return null;
          }
        );
        return (
          <Accordion key={row.callListRelationPublicId} sx={classes.eachRow}>
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <ExpandMoreIcon />
                </IconButton>
              }
              sx={{ padding: 0 }}
            >
              <Grid
                container
                direction="column"
                alignContent="flex-start"
                sx={{ ...classes.heading, height: 60 }}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                alignItems="center"
              >
                <Grid item xs={12} sx={classes.flex}>
                  <Checkbox
                    checked={isSelected(row)}
                    onClick={() => addRowToSelected(row)}
                    disabled={isDeleteting}
                    sx={{ minHeight: 0 }}
                  />
                  <Box sx={classes.columnInfo}>
                    <Typography variant="body2">{row.contactName}</Typography>
                    <SelectPrimaryPhone
                      contactPhoneList={row.contactPhoneNumber}
                      contactTypeDisplayName={row.contactTypeDisplayName}
                      contactPublicId={row.contactPublicId}
                      enableDropDown={false}
                      enableCall
                      salesPersonPublicId={salesPersonPublicId}
                      cellRow={row}
                      calledFromCallList
                    />
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={classes.infoDetails}>
              <Box sx={classes.columnInfo}>
                <Typography sx={classes.secondaryHeading}>
                  {row.contactTypeDisplayName}
                </Typography>
                <Typography
                  sx={classes.infoHeading}
                  onClick={() => handleClickRedirection(row)}
                >
                  {row.name}
                </Typography>
                <Typography
                  sx={classes.infoHeading}
                  onClick={() => handleClickRedirection(row)}
                >
                  {row.number}
                </Typography>
              </Box>
              <Divider />
              <Box sx={classes.infoDetails}>
                {row.lastCallDate === null ? (
                  <div style={{ fontSize: 14, color: 'grey' }}>
                    No calls registered
                  </div>
                ) : (
                  <div style={{ fontSize: 14, color: 'primary' }}>
                    Called:{' '}
                    {new Date(row.lastCallDate)
                      .toLocaleString(DateOptions.locale)
                      .substring(0, 17)}
                  </div>
                )}
              </Box>
              <Divider />
              <Box sx={classes.infoDetails}>
                {row.note === '' ? (
                  <div style={{ fontSize: 14, color: 'grey' }}>
                    Add your notes... {noteCell}
                  </div>
                ) : (
                  <div style={{ fontSize: 14, color: 'primary' }}>
                    Note: {noteCell}
                  </div>
                )}
              </Box>
              <Divider />
              <Box sx={classes.actionRow}>
                {phoneString}
                {row.contactTypeDisplayName === 'External Contact' ? (
                  <EditButton
                    style={{ color: 'white' }}
                    onClick={() => handleEdit(row)}
                  >
                    Edit
                  </EditButton>
                ) : (
                  <CallListActions row={row} />
                )}
              </Box>
              <Divider />
            </AccordionDetails>
          </Accordion>
        );
      })}
      {modal}
    </>
  );
};

export default MuiCallListPanelHelper;

const classes: { [k: string]: SxProps } = {
  root: {
    width: '100%',
    backgroundColor: '#f2f2f2',
  },
  eachRow: {
    width: '98%',
    border: '1px solid #e6e6e6',
    borderRadius: '5px',
    marginBottom: 8,
  },
  heading: {
    fontSize: 14,
    textAlign: 'left',
    fontWeight: 'bolder',
  },
  secondaryHeading: {
    fontSize: 12,
    color: 'text.secondary',
    // textAlign: 'right',
  },
  infoHeading: {
    fontSize: 14,
    color: 'text.primary',
    // textAlign: 'right',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    padding: 1,
    marginLeft: 10,
  },
  infoDetails: {
    alignItems: 'center',
  },
  columnInfo: {
    flexBasis: '100%',
    textAlign: 'left',
  },
  actionRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px',
    fontSize: 12,
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
  },
};
