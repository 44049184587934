import { AuthenticationActions } from 'react-aad-msal';

export const AAD_INITIALIZING = AuthenticationActions.Initializing;
export const AAD_INITIALIZED = AuthenticationActions.Initialized;
export const AAD_LOGIN_SUCCESS = AuthenticationActions.LoginSuccess;
export const AAD_LOGIN_FAILED = AuthenticationActions.LoginFailed;
export const AAD_LOGIN_ERROR = AuthenticationActions.LoginError;
export const AAD_CLEAR_ERROR = AuthenticationActions.ClearError;
export const AAD_LOGOUT_SUCCESS = AuthenticationActions.LogoutSuccess;
export const AAD_ACQUIRED_ID_TOKEN_SUCCESS =
  AuthenticationActions.AcquiredIdTokenSuccess;
export const AAD_ACQUIRED_ID_TOKEN_ERROR =
  AuthenticationActions.AcquiredIdTokenError;
export const AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS =
  AuthenticationActions.AcquiredAccessTokenSuccess;
export const AAD_ACQUIRED_ACCESS_TOKEN_ERROR =
  AuthenticationActions.AcquiredAccessTokenError;
export const AAD_AUTHENTICATED_STATE_CHANGED =
  AuthenticationActions.AuthenticatedStateChanged;
