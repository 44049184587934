export const escapeRegexp = (str: string) =>
  str.replace(/[-[]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

export function isEmpty(str: string | string[]) {
  return Array.isArray(str)
    ? str.every((s: string) => s.length === 0)
    : str.length === 0;
}

export const removeIllegalCharactersAndFinalDotFromPath = (
  text: string
): string => {
  let result = text.replace(/[/\\?%*:|"<>]*/g, '');
  if (result[result.length - 1] === '.') {
    result = result.slice(0, -1);
  }
  return result;
};

export type FilterFunction = (item: any) => boolean;

/**
 * Returns a function to make a search for a term in some (specified) properties of an object (item)
 * @param term the term to be found
 */
export const buildFilterItemByTermOnFields = (
  term: string,
  fields: string[]
): FilterFunction => {
  const re = new RegExp(escapeRegexp(term), 'i');
  return (item: any) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < fields.length; i++) {
      const prop: string = fields[i];
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty(prop)) {
        if (re.test(item[prop])) {
          return true;
        }
      }
    }
    return false;
  };
};
export const EMPTY_STRING = '';
export const STRING_TYPE = 'string';
