import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { rootStateInterface } from '../../../../reducers';
import {
  stopImpersonate,
  updateCallListAndFigures,
  startImpersonate,
  userImpersonateInterface,
} from './impersonate.utils';
import retry from '../../../../utility/promise.utils';
import { createErrorNotification } from '../notifications/util';
import PathConstants from '../../../../routes/pathConstants';
import { getKeyFigures } from '../../../app/_CustomerMainPage/__generated__/getKeyFigures';

type functionalProps = {
  handleClose: () => void;
  notifyError: (payload: any) => any;
  keyFiguresData: getKeyFigures | undefined;
} & userImpersonateInterface;

const StopImpersonateMenu: React.FC<functionalProps> = ({
  handleClose,
  notifyError,
  impersonateUser,
  loadCallList,
  keyFigures,
  keyFiguresData,
}) => {
  const navigate = useNavigate();
  const commonProps = useSelector<rootStateInterface, any>(
    (state) => state.application.shared.bestoneCommon
  );

  const impersonateFunctions: userImpersonateInterface = {
    impersonateUser,
    loadCallList,
    keyFigures,
  };

  const handleClick = () => {
    handleStopImpersonate(
      commonProps.currentUserWholeData.queryUserMail,
      impersonateFunctions,
      keyFiguresData,
      notifyError,
      navigate
    );
    handleClose();
  };

  return <Button onClick={handleClick}>Stop Impersonating</Button>;
};

export default StopImpersonateMenu;

export const handleStopImpersonate = async (
  queryUserMail: string,
  impersonateFunctions: userImpersonateInterface,
  keyFiguresData: getKeyFigures | undefined,
  notifyError: (payload: any) => any,
  navigate: (path: string) => void
) => {
  let somethingSucceed: boolean = false;
  const previousEmailValue = {
    emailValue: queryUserMail,
  };
  try {
    await stopImpersonate(impersonateFunctions);
    somethingSucceed = true;
    navigate(PathConstants.HOME);
  } catch (e) {
    if (!somethingSucceed) {
      notifyError(
        createErrorNotification(new Error('Stop impersonation failed'))
      );
      // eslint-disable-next-line no-console
      console.log('All has failed when trying to stop impersonation');
    } else {
      // eslint-disable-next-line no-console
      console.log(
        'Stop impersonation OK but error getting call list or key figures, rolling back...'
      );
      retry(
        [
          startImpersonate(previousEmailValue, impersonateFunctions),
          updateCallListAndFigures(impersonateFunctions, keyFiguresData),
        ],
        3
      );
      notifyError(
        createErrorNotification(new Error('Stop impersonation failed'))
      );
    }
  }
};
