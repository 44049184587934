import {
  createOrUpdatePrimaryPhone,
  createOrUpdatePrimaryPhoneQueryProps,
} from './api/set.primary.phone.number.api';

export const createOrUpdatePrimaryPhoneService = (
  queryParams: createOrUpdatePrimaryPhoneQueryProps
) => {
  return createOrUpdatePrimaryPhone(queryParams).then((response) => {
    return response;
  });
};
