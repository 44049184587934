import { FetchResult } from 'apollo-link';
import { apolloClient } from '../../../../utility/ApolloConfig';
import { createOrUpdatePrimaryPhoneNumberMutation } from '../../../../mutations/app/set.primary.phone.number/set.primary.phone.number.mutation';
import { createOrUpdatePrimaryPhoneResultInterface } from '../../../../mutations/app/set.primary.phone.number/set.primary.phone.number.mutation.interface';

export type createOrUpdatePrimaryPhoneQueryProps = {
  contactPublicId: string;
  contactPhonePublicId: string;
};

export const createOrUpdatePrimaryPhone = (
  queryParams: createOrUpdatePrimaryPhoneQueryProps
) => {
  const { contactPublicId, contactPhonePublicId } = queryParams;
  return apolloClient
    .mutate({
      mutation: createOrUpdatePrimaryPhoneNumberMutation,
      variables: { contactPublicId, contactPhonePublicId },
    })
    .then(
      (response: FetchResult<createOrUpdatePrimaryPhoneResultInterface>) =>
        response.data
    );
};
