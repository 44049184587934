import React from 'react';
import { Button, Grid, Box } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Brand } from '../../../../@types/branding/branding.types';

type stateProps = {
  listData: Array<any>;
  handleBrandClick: Function;
  customer: string;
  isMultiBrand: (brand: Brand) => boolean;
};

const BrandButtonList: React.FC<stateProps> = ({
  listData,
  handleBrandClick,
  customer,
  isMultiBrand,
}) => {
  const [brandSelected, setBrandSelected] = React.useState('');

  const handleBrandSelection = (brand: Brand) => {
    setBrandSelected(brand.name);
    handleBrandClick(brand);
  };

  const stylingBrandButton = (isSelected: boolean) => {
    return {
      backgroundColor: isSelected ? 'companyPrimary.main' : 'primary.dark',
      borderRadius: 1,
      color: 'common.white',
      width: '100%',
    };
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'auto',
        overflowX: 'unset',
        backgroundColor: 'background.paper',
        height: '60vh',
      }}
    >
      <Grid container justifyContent="center" spacing={2}>
        {listData.map((brand) => (
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            key={brand.publicId}
            sx={{ pb: { md: 2 }, whiteSpace: 'nowrap' }}
          >
            {customer === 'CSA' ? (
              <Button
                sx={stylingBrandButton(brandSelected === brand.name)}
                variant="contained"
                onClick={() => handleBrandSelection(brand)}
                key={brand.name}
              >
                {brandSelected === brand.name && (
                  <CheckRoundedIcon fontSize="small" />
                )}
                {brand.name}
              </Button>
            ) : (
              <Button
                sx={stylingBrandButton(isMultiBrand(brand))}
                variant="contained"
                onClick={() => handleBrandSelection(brand)}
                key={brand.name}
              >
                {isMultiBrand(brand) && <CheckRoundedIcon fontSize="small" />}
                {brand.name}
              </Button>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BrandButtonList;
