import { ApolloQueryResult, gql, useMutation, useQuery } from '@apollo/client';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid,
  Input,
} from '@mui/material';

import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { formatDateToYYYYMMDD } from '@bestseller-bit/sales-and-invoicing.utils.date-tools';
import { useIsMobile } from '@bestseller-bit/frontend-community.utilities.is-mobile';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import {
  CustomDialog,
  DialogButton,
} from '@bestseller-bit/sales-and-invoicing.ui-elements.dialog';
import {
  LabelValueType,
  RelationEntityType,
  RelationType,
} from '../../../queries/shared/types';
import { convertOutlookEvent } from '../../../services/outlook';
import { transformReminderToEvent } from '../../app/customerReminders/util';
import { calculateOptions } from '../DateTime/TimeRange/util';

type Props = {
  showReminderDialog: boolean;
  setShowReminderDialog: Dispatch<SetStateAction<boolean>>;
  publicIdBTP: string | undefined;
  nameBTP: string | undefined;
  refetch?: (variables?: any) => Promise<ApolloQueryResult<any>>;
};

const ReminderDialog: FC<Props> = ({
  showReminderDialog,
  setShowReminderDialog,
  publicIdBTP,
  nameBTP,
  refetch,
}) => {
  const todayDate = new Date();
  const isMobile = useIsMobile();
  const dateFormat = 'DD/MM/YYYY';

  const { data: remindersTypes } = useQuery(getRemindersTypesQuery);
  const allRemindersTypes: LabelValueType[] =
    remindersTypes?.findReminderTypes.result || [];
  const allAvailableHours = calculateOptions({ day: todayDate, busySlots: [] });

  const [typeValue, setTypeValue] = useState('digital-meeting');
  const [dateValue, setDateValue] = useState<Dayjs>(dayjs());
  const [startHourValue, setStartHourValue] = useState('07:00');
  const [endHourValue, setEndHourValue] = useState('08:00');
  const [notesValue, setNotesValue] = useState('');

  const [createReminder] = useMutation(createReminderMutation);

  const handleCreateReminder = useCallback(() => {
    const relations = {
      entityPublicId: publicIdBTP,
      entityType: 'BUSINESS_TRADING_PARTNER',
    };
    const splitedStartHour = startHourValue.split(':');
    const convertedDate = new Date(formatDateToYYYYMMDD(dateValue));
    convertedDate.setHours(
      parseInt(splitedStartHour[0], 10),
      parseInt(splitedStartHour[1], 10)
    );

    const newReminder = {
      type: typeValue,
      date: convertedDate,
      time: `${startHourValue} - ${endHourValue}`,
      notes: notesValue,
      relatedEntityName: nameBTP,
    };
    const owner: RelationType = {
      entityType: RelationEntityType.BUSINESS_TRADING_PARTNER,
      entityPublicId: publicIdBTP,
      entityName: nameBTP,
    };
    const event = transformReminderToEvent(newReminder, owner);
    const convertedEvent = convertOutlookEvent(event);
    createReminder({
      variables: {
        input: {
          reminder: newReminder,
          relations,
          outlookEvent: convertedEvent,
        },
      },
    }).then(() => {
      if (refetch) {
        refetch();
      }
    });

    setShowReminderDialog(false);
  }, [
    createReminder,
    dateValue,
    endHourValue,
    setShowReminderDialog,
    nameBTP,
    notesValue,
    publicIdBTP,
    refetch,
    startHourValue,
    typeValue,
  ]);

  const allowToSave = useMemo(() => {
    const typeValidation = allRemindersTypes.some((type) => {
      return type.value === typeValue;
    });
    const dateValidation = dateValue !== null;
    const timeValidation = startHourValue < endHourValue;
    if (typeValidation && dateValidation && timeValidation) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue, endHourValue, startHourValue, typeValue]);

  const buttons: DialogButton = {
    buttons: [
      {
        label: 'Save',
        onClick: () => handleCreateReminder(),
        disabled: !allowToSave,
      },
      { label: 'Cancel', onClick: () => setShowReminderDialog(false) },
    ],
  };

  return (
    <CustomDialog
      openState={[showReminderDialog, setShowReminderDialog]}
      title={`CREATE A NEW REMINDER FOR ${nameBTP}`}
      buttons={buttons}
      disableTitleUpperCase
      onClose={() => {
        setShowReminderDialog(false);
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={10} md={5}>
          <FormControl error={typeValue === ''} fullWidth margin="dense">
            <InputLabel>Type</InputLabel>
            <Select
              sx={{ minWidth: 150 }}
              value={typeValue}
              onChange={(e) => setTypeValue(e.target.value)}
              label="Type"
            >
              {allRemindersTypes.length > 0 &&
                allRemindersTypes.map((reminderType: LabelValueType) => (
                  <MenuItem
                    key={reminderType.value}
                    value={reminderType.value}
                    sx={{
                      fontSize: 'body2.fontSize',
                    }}
                  >
                    {reminderType.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={10} md={5} mt={2}>
          {/* <FormControl error={dateValue === ''} fullWidth>
              <InputLabel>Date</InputLabel> */}
          {/* <Input type="date" value={dateValue} onChange={handleChange1} /> */}
          <DatePicker
            sx={{ width: '100%' }}
            label="Date"
            value={dateValue}
            onChange={(newDate) => {
              if (newDate) setDateValue(newDate);
            }}
            format={dateFormat}
          />
          {/*  </FormControl> */}
        </Grid>
        {/*           <Grid item xs={12}>
            <FormControl error={dateValue === ''} fullWidth>
              <InputLabel>Date</InputLabel>
              <Input
                type="date"
                value={dateValue}
                onChange={(e) => setDateValue(e.target.value)}
              />
            </FormControl>
          </Grid> */}
        <Grid item xs={10} md={5} mt={1}>
          <FormControl
            error={startHourValue > endHourValue}
            fullWidth
            margin="dense"
          >
            <InputLabel>Start time</InputLabel>
            <Select
              sx={{ minWidth: 150 }}
              value={startHourValue}
              onChange={(e) => setStartHourValue(e.target.value)}
              label="Start time"
            >
              {allAvailableHours.length > 0 &&
                allAvailableHours.map((hour: LabelValueType) => (
                  <MenuItem
                    key={hour.value}
                    value={hour.value}
                    sx={{
                      fontSize: 'body2.fontSize',
                    }}
                  >
                    {hour.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={10} md={5} mt={1}>
          <FormControl
            error={startHourValue > endHourValue}
            fullWidth
            margin="dense"
            variant="outlined"
          >
            <InputLabel>End time</InputLabel>
            <Select
              sx={{ minWidth: 150 }}
              value={endHourValue}
              onChange={(e) => setEndHourValue(e.target.value)}
              label="End time"
            >
              {allAvailableHours.length > 0 &&
                allAvailableHours.map((hour: LabelValueType) => (
                  <MenuItem
                    key={hour.value}
                    value={hour.value}
                    sx={{
                      fontSize: 'body2.fontSize',
                    }}
                  >
                    {hour.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {startHourValue > endHourValue && (
          <Typography color="error" mt={1} fontSize="body2.fontSize">
            End time should not be before start time
          </Typography>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth={!isMobile} margin="dense">
            <InputLabel>Notes</InputLabel>
            <Input
              type="text"
              value={notesValue}
              onChange={(e: {
                target: { value: React.SetStateAction<string> };
              }) => setNotesValue(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

const getRemindersTypesQuery = gql`
  query findTypes($filter: String) {
    findReminderTypes(filter: $filter) {
      queryCount
      result {
        label
        value
      }
    }
  }
`;

const createReminderMutation = gql`
  mutation addReminder($input: AddReminderInput) {
    addReminder(input: $input) {
      publicId
      type
      date
      time
      notes
      relatedEntityName
      relations {
        entityType
        entityPublicId
      }
    }
  }
`;

export default ReminderDialog;
