import gql from 'graphql-tag';

export const createOrUpdatePrimaryPhoneNumberMutation = gql`
  mutation createOrUpdatePrimaryPhone(
    $contactPublicId: String!
    $contactPhonePublicId: String!
  ) {
    SET_PRIMARY_PHONE: createOrUpdateContactPrimaryPhoneNumber(
      contactPublicId: $contactPublicId
      contactPhonePublicId: $contactPhonePublicId
    ) {
      queryCount
      result {
        publicId
        countryPublicId
        countryCode
        phoneNumber
        isPrimaryPhone
        changedBy
        changedDate
      }
    }
  }
`;
