export const useVariantStyles = (variant: string) => {
  let root = {};
  switch (variant) {
    case 'error':
      root = {
        backgroundColor: 'error.dark',
      };
      break;
    case 'info':
      root = {
        backgroundColor: 'primary.light',
      };
      break;
    case 'success':
      root = {
        backgroundColor: 'success.dark',
      };
      break;
    case 'warning':
      root = {
        backgroundColor: 'warning.dark',
      };
      break;
    default:
      break;
  }

  return root;
};
