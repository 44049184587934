import { FetchResult } from 'apollo-link';
import { apolloClient } from '../../../../utility/ApolloConfig';
import { setImpersonateUserMutation } from '../../../../mutations/app/user.impersonate/app.user.impersonate.mutation';

import { setUserImpersonate } from '../../../../mutations/app/user.impersonate/app.user.impersonate.mutation.interface';

export type impersonateQueryProps = {
  emailValue: string;
};

export const apiSetUserImpersonate = (queryParams: impersonateQueryProps) => {
  const { emailValue } = queryParams;
  return apolloClient
    .mutate({
      mutation: setImpersonateUserMutation,
      variables: { emailValue },
    })
    .then((response: FetchResult<setUserImpersonate>) => response.data);
};
