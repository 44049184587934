import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import { useIsMobile } from '@bestseller-bit/frontend-community.utilities.is-mobile';
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFeedbackContext } from '@bestseller-bit/sales-and-invoicing.ui-elements.feedback';
import { NotificationsSubscriptionRow } from '../../../../__generated__/globalTypes';
import { Brand } from '../../../../@types/branding/branding.types';
import { rootStateInterface } from '../../../../reducers';
import { appBrandingStateInterface } from '../../../../reducers/app/branding/app.branding.reducer';
import { ProductLine } from '../../shipTo.info/types';
import AccordionCustom from '../AccordionCustom';
import BrandMultiSelect from './BrandMultiSelect';
import ProductLineList from './ProductLineList';
import {
  GetNotificationsSubscription,
  GetNotificationsSubscription_findAllUsersNotificationsSubscriptionWithCount_result,
} from './__generated__/GetNotificationsSubscription';

export type ProductLineRow = {
  productLine: ProductLine;
  isDeleteCommand: boolean;
};

export type PhasePriceNotification = {
  brand: Brand;
  productLines: ProductLineRow[];
};

const PhasePriceNotificationsAcc = () => {
  const [phasePriceNotifications, setPhasePriceNotification] = useState<
    PhasePriceNotification[]
  >([]);
  const [decodedBrandPublicId, setDecodedPublicIds] = useState<Brand[]>([]);
  const [decodedProductLinesdPublicId, setDecodedProductLinesdPublicId] =
    useState<ProductLine[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const isMobile = useIsMobile();
  const { addErrorMessage } = useFeedbackContext();
  const [snackbarLoading, setSnackbarLoading] = useState<boolean>(false);

  const brandingData = useSelector<
    rootStateInterface,
    appBrandingStateInterface
  >((state) => state.application.app.branding);

  const [createBrandingRequestMutation] = useMutation(CREATE_NOTIFICATIONS, {
    context: { clientName: 'java' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.createNotificationsSubscription) {
        setPhasePriceNotification([]);
        data.createNotificationsSubscription.forEach(
          (row: NotificationsSubscriptionRow) => {
            modifyPhasePriceUseStates(row);
          }
        );
        if (data) setSnackbarLoading(true);
        setLoading(false);
      }
    },
    onError: (e) => {
      addErrorMessage(e.message, CREATE_NOTIFICATIONS);
    },
  });

  const onClose = (): void => {
    setSnackbarLoading(false);
  };

  const [loadInitialNotifications] = useLazyQuery<GetNotificationsSubscription>(
    FIND_ALL_USERS_NOTIFICATIONS_SUBSCRIPTION,
    {
      context: { clientName: 'java' },
      fetchPolicy: 'network-only',

      onCompleted: (data) => {
        if (
          data &&
          data.findAllUsersNotificationsSubscriptionWithCount?.result
        ) {
          setLoading(true);
          data.findAllUsersNotificationsSubscriptionWithCount.result.forEach(
            (row) => {
              modifyPhasePriceUseStates(row);
            }
          );
          setLoading(false);
        }
      },
      onError: (e) => {
        addErrorMessage(e.message, FIND_ALL_USERS_NOTIFICATIONS_SUBSCRIPTION);
      },
    }
  );

  const addRowToPhasePriceNotifications = (
    row:
      | GetNotificationsSubscription_findAllUsersNotificationsSubscriptionWithCount_result
      | NotificationsSubscriptionRow,
    allPhasePrices: PhasePriceNotification[]
  ): PhasePriceNotification | undefined => {
    const findModfiedBrandIndex = allPhasePrices.findIndex(
      (selectedPhasePrice) => {
        return selectedPhasePrice.brand.publicId === row.brandPublicId;
      }
    );

    if (findModfiedBrandIndex >= 0) {
      // in case found
      const productLineIndex = allPhasePrices[
        findModfiedBrandIndex
      ].productLines.findIndex(
        (pl) => pl.productLine.publicId === row.entityPublicId
      );

      if (productLineIndex >= 0) {
        // we dont do anything ( we already have that productLine selected )
        return undefined;
      }

      const newSelectedProductLine = decodedProductLinesdPublicId.find(
        (pl) =>
          pl.publicId === row.entityPublicId &&
          pl.brandPublicId === row.brandPublicId
      );

      if (newSelectedProductLine) {
        const newProductLineRow: ProductLineRow = {
          productLine: newSelectedProductLine,
          isDeleteCommand: false,
        };

        const allProductLinesAssociatedToTheBrand =
          allPhasePrices[findModfiedBrandIndex].productLines;

        const result: PhasePriceNotification = {
          brand: allPhasePrices[findModfiedBrandIndex].brand,
          productLines: [
            ...allProductLinesAssociatedToTheBrand,
            newProductLineRow,
          ],
        };
        return result;
      }
      return undefined;
    }
    // in case its a new one we find the new brand and productline from all the decoded options
    const newSelectedBrand = decodedBrandPublicId.find(
      (elem) => elem.publicId === row.brandPublicId
    );
    const newSelectedProductLine = decodedProductLinesdPublicId.find(
      (pl) =>
        pl.publicId === row.entityPublicId &&
        pl.brandPublicId === row.brandPublicId
    );

    if (newSelectedBrand && newSelectedProductLine) {
      const newProductLineRow: ProductLineRow = {
        productLine: newSelectedProductLine,
        isDeleteCommand: false,
      };
      const res = {
        brand: newSelectedBrand,
        productLines: [newProductLineRow],
      };
      return res;
    }
    return undefined;
  };

  // as in a forEach loop we cannot directly modify the useState values ( as it is an array ) I had to use this solution
  const modifyPhasePriceUseStates = (
    row:
      | GetNotificationsSubscription_findAllUsersNotificationsSubscriptionWithCount_result
      | NotificationsSubscriptionRow
  ): void => {
    setPhasePriceNotification((prevState) => {
      const newPhasePrices = addRowToPhasePriceNotifications(row, prevState);
      if (newPhasePrices) {
        const newArr = prevState;
        const findIndex = prevState.findIndex(
          (elem) => elem.brand.publicId === newPhasePrices.brand.publicId
        );
        if (findIndex >= 0) {
          newArr[findIndex] = newPhasePrices;
          return [...newArr];
        }
        return [...prevState, newPhasePrices];
      }
      return [...prevState];
    });
  };

  useEffect(() => {
    // Once we have the branding data ready we will pass the decoded brands & productLines to our components
    if (
      brandingData &&
      brandingData.brandRows.length > 0 &&
      brandingData.productLineRows.length > 0
    ) {
      setLoading(true);
      const decodedBrands = brandingData.brandRows.map((brand) => {
        const decoded: Brand = {
          publicId: atob(brand.publicId),
          name: brand.name,
        };
        return decoded;
      });
      setDecodedPublicIds(decodedBrands);

      const decodedPLs = brandingData.productLineRows.map((pl) => {
        const decoded: ProductLine = {
          publicId: atob(pl.publicId),
          productLineName: pl.productLineName,
          shortName: pl.shortName,
          subBrandPublicId: atob(pl.subBrandPublicId),
          brandPublicId: atob(pl.brandPublicId),
        };
        return decoded;
      });

      setDecodedProductLinesdPublicId(decodedPLs);
      loadInitialNotifications();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandingData]);

  const sendCreateNotificationsMutation = (): void => {
    setLoading(true);
    const sendingPayloadArrays: NotificationsSubscriptionRow[][] =
      phasePriceNotifications
        .filter((elem) => elem.productLines.length !== 0)
        .map((br) => {
          const sendingPayloadRows: NotificationsSubscriptionRow[] =
            br.productLines.map((pl) => {
              const individualRow: NotificationsSubscriptionRow = {
                notificationType: 'PHASE_PRICE',
                brandPublicId: br.brand.publicId,
                entityType: 'PRODUCT_LINE',
                entityPublicId: pl.productLine.publicId,
                isDeleteCommand: pl.isDeleteCommand,
                secondaryEntityType: undefined,
                secondaryEntityPublicId: undefined,
              };
              return individualRow;
            });
          return sendingPayloadRows;
        });

    if (sendingPayloadArrays.length === 0) {
      addErrorMessage('Please at least select one productline');
      setLoading(false);
    } else {
      createBrandingRequestMutation({
        variables: {
          notificationsSubscriptionInput: sendingPayloadArrays.flat(),
        },
      });
    }
  };

  return loading ? (
    <Box>
      <Loading message="Loading Notifications" />
    </Box>
  ) : (
    <>
      <AccordionCustom
        accordionNameToExpand="phasePrices"
        title="PHASE PRICES"
        accordionDetails={
          <Grid>
            <Grid
              item
              xs={12}
              rowSpacing={5}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              container
              sx={{ pt: 2 }}
            >
              <Grid item xs={6}>
                <BrandMultiSelect
                  allPhasePrices={phasePriceNotifications}
                  setAllPhasePrices={setPhasePriceNotification}
                  brandRows={decodedBrandPublicId}
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={() => sendCreateNotificationsMutation()}>
                    Apply
                  </Button>
                </Box>
              </Grid>
              {phasePriceNotifications.length === 0 ? (
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    sx={{
                      display: 'flex',
                      justifyContent: isMobile ? 'center' : 'initial',
                    }}
                  >
                    There is no brand selected, please select at least one or
                    more
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    sx={{
                      display: 'flex',
                      justifyContent: isMobile ? 'center' : 'initial',
                    }}
                  >
                    Product lines
                  </Typography>
                </Grid>
              )}
              <Grid item container sx={{ width: '100%' }}>
                {phasePriceNotifications.map((row) => (
                  <Grid item container key={row.brand.publicId} xs={12}>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      xs={12}
                      rowSpacing={5}
                    >
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            display: 'flex',
                            justifyContent: isMobile ? 'center' : 'initial',
                          }}
                          variant="body1"
                        >
                          {row.brand.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                      <ProductLineList
                        phasePrice={row}
                        allPhasePrices={phasePriceNotifications}
                        setAllPhasePrices={setPhasePriceNotification}
                        productLineRows={decodedProductLinesdPublicId}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <Snackbar
        open={snackbarLoading}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={onClose} severity="success" sx={{ width: '100%' }}>
          Your subscriptions has been modified
        </Alert>
      </Snackbar>
    </>
  );
};

const FIND_ALL_USERS_NOTIFICATIONS_SUBSCRIPTION = gql`
  query GetNotificationsSubscription {
    findAllUsersNotificationsSubscriptionWithCount {
      queryCount
      result {
        notificationType
        brandPublicId
        entityType
        entityPublicId
      }
    }
  }
`;

const CREATE_NOTIFICATIONS = gql`
  mutation CreateNotificationsSubscription(
    $notificationsSubscriptionInput: [NotificationsSubscriptionRow!]!
  ) {
    createNotificationsSubscription(
      notificationsSubscriptionInput: $notificationsSubscriptionInput
    ) {
      notificationType
      brandPublicId
      entityType
      entityPublicId
      secondaryEntityType
      secondaryEntityPublicId
    }
  }
`;

export default PhasePriceNotificationsAcc;
