import { Action } from '../../../middleware/helpers/action.interface';
import {
  SHOW_BTP_SHIPTO_DETAILS,
  CLEAR_BTP_SHIPTO_DETAILS,
  SHOW_SHIPTO_CONTACTS,
  SHOW_BTP_SHIPTO_ALL_CONTACTS,
} from '../../../constants/app/app.show.btp.shipTo.details.page.constants';

export interface appShowBTPShipToDetailsPageStateInterface {
  BTPshipToDetails: any;
  BTPshipToKeyFigures: any;
  shipToContacts: any[];
  shiptoAllContacts: any[];
}

const defaultState: appShowBTPShipToDetailsPageStateInterface = {
  BTPshipToDetails: {},
  BTPshipToKeyFigures: {},
  shipToContacts: [],
  shiptoAllContacts: [],
};

export const showBTPShipToDetailsReducer = (
  // eslint-disable-next-line default-param-last
  state = defaultState,
  action: Action
) => {
  switch (action.type) {
    case SHOW_BTP_SHIPTO_DETAILS: {
      const contacts = action.payload.SHIPTO_CONTACTS.result;
      const contactArray = contacts.filter((item: any) => item.isMyContact);

      return {
        ...state,
        BTPshipToDetails: action.payload.SHIPTO_DETAILS,
        BTPshipToKeyFigures: action.payload.SHIPTO_KEY_FIGURES,
        shipToContacts: contactArray,
        shiptoAllContacts: action.payload.SHIPTO_CONTACTS.result,
      };
    }
    case SHOW_SHIPTO_CONTACTS: {
      return {
        ...state,
        shipToContacts: action.payload.ADD_MY_CONTACT.result,
      };
    }
    case SHOW_BTP_SHIPTO_ALL_CONTACTS: {
      const contacts = action.payload.SHIPTO_CONTACTS.result;
      const contactArray = contacts.filter((item: any) => item.isMyContact);
      return {
        ...state,
        shiptoAllContacts: contacts
          ? action.payload.SHIPTO_CONTACTS.result
          : action.payload.SHIPTO_CREATE_CONTACT.result,
        shipToContacts: contactArray,
      };
    }

    case CLEAR_BTP_SHIPTO_DETAILS: {
      return {
        ...state,
        BTPshipToDetails: {},
        BTPshipToKeyFigures: {},
      };
    }
    default:
      return state;
  }
};
