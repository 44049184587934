const PathConstants = {
  HOME: '/',
  BUSINESS_TRADING_PARTNERS: '/businessTradingPartner/showBTPMyCustomerList',
  BUSINESS_TRADING_PARTNERS_DETAILS:
    '/businessTradingPartner/showBTPMyCustomerList/:BTPPublicId',
  SHIP_TOS: '/showShipTos',
  SHIP_TO_DETAILS: '/showShipTos/:shipToPublicId',
  MY_PROFILE: '/myProfile',
  PROSPECTS: '/prospects',
  REMINDERS: '/showReminders',
  SUPPORT: '/support',
  CUSTOMER_SPECIFIC_ASSORTMENT: '/customerSpecificAssortment',
  FIXED_ASSORTMENT: '/customerSpecificAssortment/fixedAssortment',
  CUSTOMIZED_ASSORTMENT: '/customerSpecificAssortment/customizedAssortment',
  PACKING_INSTRUCTIONS: '/customerSpecificPackingInstructions',
  PLAN_MY_ROUTE: '/planMyRoute',
  CUSTOMER_LOOKUP: '/customerLookUp',
  MANAGE_NOTIFICATIONS: '/manageNotifications',
  PHOTO_SAMPLES: '/photoSamples',
  PAGE_NOT_FOUND: '*',
  DISTRIBUTION_SETTINGS: '/customerSpecificDistributionSettings',
};

export default PathConstants;
