import gql from 'graphql-tag';

const getBTPShipTosQuery = gql`
  query shipToFullInfo(
    $salesPersonId: String!
    $BTPPublicId: String!
    $filterShipTos: MyShipToFilter
    $currencyCode: String!
    $onlyMine: Boolean!
  ) {
    SHIPTO_INFO: myBusinessTradingPartnerShipToInfo(
      salesPersonPublicId: $salesPersonId
      businessTradingPartnerPublicId: $BTPPublicId
    ) {
      shipToCount
      myShipToCount
    }
    MY_SHIPTOS_BY_BTP: myShipToByBusinessTradingPartner(
      salesPersonPublicId: $salesPersonId
      businessTradingPartnerPublicId: $BTPPublicId
      filter: $filterShipTos
    ) {
      queryCount
      result {
        salesPersonPublicId
        publicId
        number
        name
        isMyShipTo
        city
      }
    }
    BTP_KEY_FIGURES: getBusinessTradingPartnerKeyFigures(
      businessTradingPartnerPublicId: $BTPPublicId
      salesPersonPublicId: $salesPersonId
      currencyCode: $currencyCode
    ) {
      publicId
      number
      name
      turnOverYearToDay
      turnoverLastYearToDay
      yearToDateIndex
      openOrders
      totalLastYear
      gapToLastYear
    }
    BTP_MY_CONTACTS: getContactByBusinessTradingPartner(
      businessTradingPartnerPublicId: $BTPPublicId
      onlyMine: $onlyMine
    ) {
      queryCount
      result {
        contactPublicId
        contactName
        contactPhoneList {
          publicId
          countryPublicId
          countryCode
          phoneNumber
          isPrimaryPhone
          changedBy
          changedDate
        }
        contactEmailAddress
        isMyContact
        note
        privateNote
        contactTypeDisplayName
        contactTypePublicId
        contactType
        primary
        changedBy
        changedDate
        invitation_date
        invitation_status
        more_invitation_status
        more_invitation_date
      }
    }
  }
`;

export default getBTPShipTosQuery;
