import {
  apiRemoveContactsFromCallList,
  SelectedContacts,
} from './api/bestone.remove.BTP.from.call.list.api';

const removeContactsfromCallList = (queryParams: SelectedContacts[]) => {
  return apiRemoveContactsFromCallList(queryParams).then((response) => {
    return response;
  });
};

export default removeContactsfromCallList;
