import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/en-gb';

dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);

const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';

export const DEFAULT_LOCALE = dayjs.locale('en-gb');
export const USER_LOCALE = dayjs.locale();

export const TIME_ZONE_NAME = dayjs.tz.guess();

const DEFAULT_DATE_FORMAT_OPTIONS = {
  year: 'numeric' as 'numeric',
  month: 'numeric' as 'numeric',
  day: 'numeric' as 'numeric',
};

export interface TimeInterval {
  start: Date;
  end: Date;
}
type OptionInMinutes = {
  stepInMinutes: number;
};

export const formatDateToDefault = (
  date: string | Date,
  options: Intl.DateTimeFormatOptions = DEFAULT_DATE_FORMAT_OPTIONS
) => new Date(date).toLocaleString(DateOptions.locale, options);

export type WeekDay = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export class DateOptions {
  static locale = DEFAULT_LOCALE;

  static dateFormat: string = DEFAULT_DATE_FORMAT;

  static weekDays: Array<string> = [];

  static dateTimePickerOptions = {
    locale: USER_LOCALE,
  };

  static init(locale?: string) {
    DateOptions.locale = locale || DEFAULT_LOCALE;
    // eslint-disable-next-line no-shadow
    import(`dayjs/locale/${DateOptions.locale}.js`).then(() => {
      DateOptions.dateTimePickerOptions.locale = DateOptions.locale;

      DateOptions.dateFormat = DateOptions.getDateFormatString(
        DateOptions.locale
      );

      let currentDay = DateOptions.getFirstDayOfWeek();

      DateOptions.weekDays = Array.from(Array(7)).map(() => {
        const dayLabel = dayjs(currentDay)
          .locale(DateOptions.locale)
          .format('dddd');

        currentDay = dateAdd(currentDay, { days: 1 });

        return dayLabel;
      });
    });
  }

  static getDateFormatString(locale: string) {
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case 'day':
            return 'dd';
          case 'month':
            return 'MM';
          case 'year':
            return 'yyyy';
          default:
            return obj.value;
        }
      })
      .join('');
  }

  static getStartOfTheDay(d: Date = new Date()) {
    return dayjs(d).startOf('day').toDate();
  }

  static getFirstDayOfWeek(d: Date = new Date()) {
    const date = dayjs(d).startOf('week');
    return date.toDate();
  }

  static getLastDayOfWeek(d: Date = new Date()) {
    const date = dayjs(d).endOf('week');
    return date.toDate();
  }

  static getFirstDayOfMonth(d: Date = new Date()) {
    const date = dayjs(d).startOf('month');
    return date.toDate();
  }

  static getWeekOfYear(d?: Date): number {
    return dayjs(d || new Date()).week();
  }

  static parse(dateString: string, timeZone: string = TIME_ZONE_NAME) {
    const date = new Date(dateString);
    const timeZoneForParsing = timeZone;
    return dayjs.tz(date, timeZoneForParsing);
  }
}

DateOptions.init();

export const dateAdd = (date: Date, additions: Duration): Date => {
  let result = dayjs(date);

  if (additions.years) {
    result = result.add(additions.years, 'year');
  }
  if (additions.months) {
    result = result.add(additions.months, 'month');
  }
  if (additions.weeks) {
    result = result.add(additions.weeks, 'week');
  }
  if (additions.days) {
    result = result.add(additions.days, 'day');
  }
  if (additions.hours) {
    result = result.add(additions.hours, 'hour');
  }
  if (additions.minutes) {
    result = result.add(additions.minutes, 'minute');
  }
  if (additions.seconds) {
    result = result.add(additions.seconds, 'second');
  }

  return result.toDate();
};

export const dateSub = (date: Date, additions: Duration): Date => {
  let result = dayjs(date);

  if (additions.years) {
    result = result.subtract(additions.years, 'year');
  }
  if (additions.months) {
    result = result.subtract(additions.months, 'month');
  }
  if (additions.weeks) {
    result = result.subtract(additions.weeks, 'week');
  }
  if (additions.days) {
    result = result.subtract(additions.days, 'day');
  }
  if (additions.hours) {
    result = result.subtract(additions.hours, 'hour');
  }
  if (additions.minutes) {
    result = result.subtract(additions.minutes, 'minute');
  }
  if (additions.seconds) {
    result = result.subtract(additions.seconds, 'second');
  }

  return result.toDate();
};

export const diffInMinutes = (from: Date, to: Date) => {
  const fromDate = dayjs(from);
  const toDate = dayjs(to);
  return toDate.diff(fromDate, 'minute');
};

export const diffInDays = (from: Date, to: Date) => {
  const fromDate = dayjs(from);
  const toDate = dayjs(to);
  return toDate.diff(fromDate, 'days');
};
export const diffInWeeks = (from: Date, to: Date) => {
  const fromDate = dayjs(from);
  const toDate = dayjs(to);
  return toDate.diff(fromDate, 'weeks');
};

export const formatLongWeekDay = (d: Date): string => {
  const dateObj = dayjs(d);
  return dateObj.format('dddd');
};

export const formatShortWeekDay = (d: Date): string => {
  const dateObj = dayjs(d);
  return dateObj.format('ddd');
};

// isBefore(new Date(1989, 6, 10), new Date(1987, 1, 11)) // false
export const isBefore = (thisOne: Date, thatOne: Date) =>
  dayjs(thisOne).isBefore(dayjs(thatOne));
export const isSameDay = (thisOne: Date, thatOne: Date) =>
  dayjs(thisOne).isSame(dayjs(thatOne));
export const isSameYear = (thisOne: Date, thatOne: Date) =>
  dayjs(thisOne).isSame(dayjs(thatOne), 'year');

export const isWithinInterval = (match: Date, interval: TimeInterval) => {
  return dayjs(match).isBetween(dayjs(interval.start), dayjs(interval.end));
};

export const areIntervalsOverlapping = (
  interval1: TimeInterval,
  interval2: TimeInterval
) => {
  const start1 = dayjs(interval1.start);
  const end1 = dayjs(interval1.end);
  const start2 = dayjs(interval2.start);
  const end2 = dayjs(interval2.end);

  return (
    (start1.isSame(start2) || start1.isBefore(end2)) &&
    (end1.isSame(end2) || end1.isAfter(start2))
  );
};
export const areInConflict = (
  interval1: TimeInterval,
  interval2: TimeInterval
) => {
  return areIntervalsOverlapping(interval1, interval2);
};

export const getDatesForInterval = (
  interval: TimeInterval,
  options: OptionInMinutes
) => {
  const { start, end } = interval;
  const { stepInMinutes } = options;

  const dates = [];
  let current = dayjs(start);

  while (current.isBefore(end)) {
    dates.push(current.toDate());
    current = current.add(stepInMinutes, 'minute');
  }

  return dates;
};

export const getTimeZone = () => {
  const offset = dayjs().utcOffset();
  const sign = offset < 0 ? '-' : '+';
  const hours = Math.floor(Math.abs(offset) / 60)
    .toString()
    .padStart(2, '0');
  const minutes = (Math.abs(offset) % 60).toString().padStart(2, '0');
  return `${sign}${hours}:${minutes}`;
};

export interface Duration {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}
