import { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions';
import { apolloClient } from '../../utility/ApolloConfig';

export const query = async (
  queryToExecute: any,
  variables?: Object,
  context?: { clientName: string }
) => {
  const response = await apolloClient.query({
    query: queryToExecute,
    variables,
    context,
  });
  return response.data;
};
export const mutation = async (
  mutationToExecute: any,
  variables?: Object,
  options?: Omit<MutationBaseOptions, 'mutation' | 'variables'>
) => {
  const response = await apolloClient.mutate({
    mutation: mutationToExecute,
    variables,
    ...options,
  });
  return response.data;
};
