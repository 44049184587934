import { apiAddMyContactBTPToCallList } from './api/bestone.add.contact.BTP.to.call.list.api';
import { UpdatableCallList } from '../interfaces';
import { addMyContactBTPToCallListInterface } from '../../../../mutations/app/add.contact.BTP.to.call.list/add.contact.BTP.to.call.list.mutation.interface';

const addMyContactBTPToCallList = (
  queryParams: UpdatableCallList
): Promise<addMyContactBTPToCallListInterface | null | undefined> => {
  return apiAddMyContactBTPToCallList(queryParams).then((response) => {
    return response;
  });
};

export default addMyContactBTPToCallList;
