import { DateOptions } from '../../../../utility/DateTime';

export const getDayId = (date: Date) =>
  date.toLocaleString(DateOptions.locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

const FIELDS_TO_FILTER_ON_TEXT = ['title', 'description', 'subtitle'];

export const getFilteredFieldsFromFilterKey = (key: string): string[] => {
  switch (key) {
    case 'text':
      return FIELDS_TO_FILTER_ON_TEXT;
    case 'relatedEntityName':
      return ['title'];
    case 'type':
      return ['subtitle'];
    default:
      return [];
  }
};
