import gql from 'graphql-tag';
import { CallListResultFragment } from '../../../queries/shared/call.list/app.btp.call.list.query';

export const addShipToToCallListMutation = gql`
  mutation addShipToToCallListMutation(
    $updatableCallList: [UpdatableCallList]
  ) {
    addShipToToCallList(updatableCallList: $updatableCallList) {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;
