import { Action } from '../../../middleware/helpers/action.interface';
import {
  SHOW_BTP_SHIPTOS,
  SEARCH_SHIPTOS_BY_NAME_AND_NUMBER,
  CLEAR_SHIPTOS_SEARCH_RESULT,
  CLEAR_BTP_SHIPTOS,
  SELECT_PRIMARY_CONTACT,
  SHOW_BTP_SHIPTOS_MY_CONTACTS,
  SHOW_BTP_SHIPTOS_ALL_MY_BTP_CONTACTS,
} from '../../../constants/app/app.show.btp.shipTos.page.constants';

import { BTPKeyFigures, BTPMyContact, ShipToInfo } from './types';
import { shipToFullInfo_MY_SHIPTOS_BY_BTP_result } from '../../../queries/app/shipTos/BTP.shipTos/__generated__/shipToFullInfo';

export interface appShowBTPShipTosPageStateInterface {
  BTPshipTosRows: shipToFullInfo_MY_SHIPTOS_BY_BTP_result[];
  BTPshipToCounts: ShipToInfo;
  BTPshipTosSearchText: string;
  BTPshipTosKeyFigures: BTPKeyFigures;
  primaryContact: string;
  BTPMyContacts: BTPMyContact[];
  BTPAllBTPContacts: BTPMyContact[];
}

export const defaultState: appShowBTPShipTosPageStateInterface = {
  BTPshipTosRows: [],
  BTPshipTosSearchText: '',
  // FIXME: This is a hack to get around the fact that these are not being set correctly
  BTPshipToCounts: {} as ShipToInfo,
  BTPshipTosKeyFigures: {} as BTPKeyFigures,
  primaryContact: '',
  BTPMyContacts: [],
  BTPAllBTPContacts: [],
};

// eslint-disable-next-line default-param-last
export const showBTPShipTosReducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case SHOW_BTP_SHIPTOS: {
      const contacts = action.payload.BTP_MY_CONTACTS.result;
      const contactArray = contacts.filter((item: any) => item.isMyContact);
      return {
        ...state,
        BTPshipTosRows: action.payload.MY_SHIPTOS_BY_BTP.result,
        BTPshipToCounts:
          action.payload.SHIPTO_INFO !== null
            ? action.payload.SHIPTO_INFO
            : { shipToCount: 0, myShipToCount: 0 },
        BTPshipTosKeyFigures: action.payload.BTP_KEY_FIGURES,
        BTPMyContacts: contactArray,
        BTPAllBTPContacts: action.payload.BTP_MY_CONTACTS.result,
      };
    }
    case SHOW_BTP_SHIPTOS_MY_CONTACTS: {
      return {
        ...state,
        BTPMyContacts: action.payload.ADD_MY_CONTACT.result,
      };
    }
    case SHOW_BTP_SHIPTOS_ALL_MY_BTP_CONTACTS: {
      const contacts = action.payload.BTP_MY_CONTACTS.result;
      const contactArray = contacts.filter((item: any) => item.isMyContact);

      return {
        ...state,
        BTPAllBTPContacts: action.payload.BTP_MY_CONTACTS
          ? action.payload.BTP_MY_CONTACTS.result
          : action.payload.BTP_CREATE_MY_CONTACT.result,
        BTPMyContacts: contactArray,
      };
    }
    case CLEAR_BTP_SHIPTOS: {
      return {
        ...state,
        BTPshipTosRows: [],
        BTPshipToCounts: [],
        BTPshipTosKeyFigures: {},
        BTPMyContacts: [],
      };
    }
    case SEARCH_SHIPTOS_BY_NAME_AND_NUMBER: {
      return {
        ...state,
        BTPshipTosSearchText: action.payload,
      };
    }
    case CLEAR_SHIPTOS_SEARCH_RESULT: {
      return {
        ...state,
        BTPshipTosSearchText: '',
      };
    }
    case SELECT_PRIMARY_CONTACT: {
      return {
        ...state,
        primaryContact: action.payload,
      };
    }
    default:
      return state;
  }
};
