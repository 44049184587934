export interface ResultWithCount<T> {
  queryCount: number;
  result: [T];
}

export type RelationType = {
  entityType: RelationEntityType;
  entityPublicId?: string;
  entityName?: string;
};

// eslint-disable-next-line no-shadow
export enum RelationEntityType {
  SHIP_TO = 'SHIP_TO',
  BUSINESS_TRADING_PARTNER = 'BUSINESS_TRADING_PARTNER',
  USER = 'USER',
  PROSPECT = 'PROSPECT',
  OUTLOOK_EVENT = 'OUTLOOK_EVENT',
}

export type LabelValueType = {
  label: string;
  value: string;
};

export const EMPTY_LIST = { queryCount: 0, result: [] as unknown };
