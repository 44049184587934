import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { rootStateInterface } from '../../../reducers';
import PhasePriceNotifications from './phasePriceNotification/PhasePriceNotification';

const ManageNotifications = () => {
  const commonProps = useSelector(
    (state: rootStateInterface) => state.application.shared.bestoneCommon
  );

  return (
    <>
      <Typography variant="h1" sx={{ mb: 4 }}>
        MANAGE NOTIFICATIONS - {commonProps.currentUser}
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <PhasePriceNotifications />
        </Grid>
      </Grid>
    </>
  );
};

export default ManageNotifications;
