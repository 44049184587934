import gql from 'graphql-tag';

export const getAllBTPCustomersQuery = gql`
  query getAllBTPCustomers($take: Int, $textSearch: String, $filterBy: String) {
    getAllBusinessTradingPartners(
      take: $take
      textSearch: $textSearch
      filterBy: $filterBy
    ) {
      queryCount
      result {
        publicId
        number
        name
      }
    }
  }
`;
