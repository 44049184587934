import {
  FILTER_BTP,
  RESET_FILTER_BTP,
} from '../../../constants/app/app.filtering.constants';
import {
  BUSINESS_TRADING_PARTNER_MY_CUSTOMERS_SELECTION_CHANGED,
  SORT_BY_COLUMN_AND_DIRECTION,
} from '../../../constants/app/app.show.btp.mycustomers.page.constants';
import { Action } from '../../../middleware/helpers/action.interface';

import {
  BusinessTradingPartnerComparableKeys,
  Order,
} from '../../../@types/businessTradingParter/businessTradingPartner.types';
import { ObjectLiteral } from '../../../application/Shared/_utilities/filtering/types';

export interface appShowBTPMycustomersPageStateInterface {
  selectedRowIds: string[];
  filter: ObjectLiteral;
  fullFilter: ObjectLiteral;
  openFilter: boolean;
  textSearch: string;
  sortBy: BusinessTradingPartnerComparableKeys;
  sortDirection: Order;
}

export const defaultState: appShowBTPMycustomersPageStateInterface = {
  selectedRowIds: [],
  filter: {},
  fullFilter: { filter: {} },
  openFilter: false,
  textSearch: '',
  sortBy: 'name' as BusinessTradingPartnerComparableKeys,
  sortDirection: 'asc',
};

export const showBTPMycustomersReducer = (
  // eslint-disable-next-line default-param-last
  state = defaultState,
  action: Action
) => {
  switch (action.type) {
    case BUSINESS_TRADING_PARTNER_MY_CUSTOMERS_SELECTION_CHANGED: {
      return {
        ...state,
        selectedRowIds: action.payload,
      };
    }
    case FILTER_BTP: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case RESET_FILTER_BTP: {
      return {
        ...state,
        filter: {},
        fullFilter: { filter: {} },
      };
    }
    case SORT_BY_COLUMN_AND_DIRECTION: {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }

    default:
      return state;
  }
};
