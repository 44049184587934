import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { ProductLine } from '../../../../@types/branding/branding.types';
import { rootStateInterface } from '../../../../reducers';
import { appBrandingStateInterface } from '../../../../reducers/app/branding/app.branding.reducer';
import {
  PhasePriceNotification,
  ProductLineRow,
} from './PhasePriceNotification';

type Props = {
  phasePrice: PhasePriceNotification;
  allPhasePrices: PhasePriceNotification[];
  setAllPhasePrices: Dispatch<SetStateAction<PhasePriceNotification[]>>;
  productLineRows: ProductLine[];
};

const ProductLineList: FC<Props> = ({
  phasePrice,
  allPhasePrices,
  setAllPhasePrices,
  productLineRows,
}) => {
  const brandingData = useSelector<
    rootStateInterface,
    appBrandingStateInterface
  >((state) => state.application.app.branding);

  const allBrandProductLineOptions: ProductLine[] = productLineRows.filter(
    (pl) => pl.brandPublicId === phasePrice.brand.publicId
  );

  const [checked, setchecked] = useState<boolean>(false);

  // initial select all value ( as the redux value at first is empty I had to use useEffect )
  useEffect(() => {
    setchecked(
      allBrandProductLineOptions.length === phasePrice.productLines.length
    );
  }, [
    brandingData.productLineRows,
    // Im just adding these two to avoid the eslint error
    allBrandProductLineOptions.length,
    phasePrice.productLines.length,
  ]);

  const checkSelectedProductLine = (row: ProductLine): boolean => {
    const findSelectedProductLineIndex = phasePrice.productLines.findIndex(
      (elem) => elem.productLine === row
    );
    if (findSelectedProductLineIndex >= 0) {
      if (
        !phasePrice.productLines[findSelectedProductLineIndex].isDeleteCommand
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const nonDeletedProductLines = phasePrice.productLines.filter(
    (pl) => !pl.isDeleteCommand
  );

  const isIndeterminate =
    nonDeletedProductLines.length > 0 &&
    nonDeletedProductLines.length !== allBrandProductLineOptions.length;

  const handleOnClickSelect = (productLine: ProductLine) => {
    const newPhasePrices = [...allPhasePrices];
    const findModfiedBrandIndex = newPhasePrices.findIndex(
      (selectedPhasePrice) => selectedPhasePrice === phasePrice
    );
    const findModifiedProductLineIndex = phasePrice.productLines.findIndex(
      (selectedProductLine) =>
        selectedProductLine.productLine.publicId === productLine.publicId
    );
    // in case we have found the desired element, we delete/add productline
    // deleting in this case means that we convert the isDeletedCommand flag to true
    if (findModifiedProductLineIndex >= 0) {
      if (
        phasePrice.productLines[findModifiedProductLineIndex].isDeleteCommand
      ) {
        // means that we already have a productline in the list which was already selected
        newPhasePrices[findModfiedBrandIndex].productLines[
          findModifiedProductLineIndex
        ].isDeleteCommand = false;

        if (
          // in case we all of the options are selected we are converting selectAll to marked
          newPhasePrices[findModfiedBrandIndex].productLines.filter(
            (elem) => !elem.isDeleteCommand
          ).length === allBrandProductLineOptions.length
        ) {
          setchecked(true);
        }
      } else {
        // In case we find a productline which it was already selected and we want to deselect them we need to convert it to true
        newPhasePrices[findModfiedBrandIndex].productLines[
          findModifiedProductLineIndex
        ].isDeleteCommand = true;

        if (
          // in case not all of the productLines are selected, we set the select all checkbox to false
          newPhasePrices[findModfiedBrandIndex].productLines.length !==
            allBrandProductLineOptions.length ||
          newPhasePrices.every(
            (elem) =>
              elem.productLines[findModifiedProductLineIndex] &&
              elem.productLines[findModifiedProductLineIndex].isDeleteCommand
          )
        ) {
          setchecked(false);
        }
      }
    } else {
      // In case we have a new productline which didnt exist from before
      newPhasePrices[findModfiedBrandIndex].productLines.push({
        productLine,
        isDeleteCommand: false,
      });

      if (
        // in case we have selected all of them you need to find which one is false
        newPhasePrices[findModfiedBrandIndex].productLines.filter(
          (elem) => !elem.isDeleteCommand
        ).length === allBrandProductLineOptions.length
      ) {
        setchecked(true);
      }
    }
    setAllPhasePrices(newPhasePrices);
  };

  return (
    <List sx={{ width: '100%' }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {allBrandProductLineOptions.length > 1 && (
          <Grid item lg={3} md={4} xs={6} key="select all">
            <ListItem key="select all" disablePadding>
              <ListItemButton
                role={undefined}
                onClick={() => {
                  setchecked(!checked);
                  const newPhasePrices = [...allPhasePrices];
                  const findModifiedBrandIndex = newPhasePrices.findIndex(
                    (selectedPhasePrice) => selectedPhasePrice === phasePrice
                  );
                  if (!checked) {
                    // selecting all the productlines
                    newPhasePrices[findModifiedBrandIndex].productLines =
                      allBrandProductLineOptions.map((elem) => {
                        const allSelectedProductLines: ProductLineRow = {
                          productLine: elem,
                          isDeleteCommand: false,
                        };
                        return allSelectedProductLines;
                      });
                  } else {
                    // deleting all the productlines when a user has deselected selectall
                    const allDeletedProductLines: ProductLineRow[] =
                      newPhasePrices[findModifiedBrandIndex].productLines.map(
                        (pr) => {
                          return {
                            productLine: pr.productLine,
                            isDeleteCommand: true,
                          };
                        }
                      );
                    newPhasePrices[findModifiedBrandIndex].productLines =
                      allDeletedProductLines;
                  }
                  setAllPhasePrices(newPhasePrices);
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked}
                    tabIndex={-1}
                    disableRipple
                    indeterminate={isIndeterminate}
                  />
                </ListItemIcon>
                <ListItemText
                  id="select all"
                  primary={
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Select all
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </Grid>
        )}
        <Grid item xs={12} />
        {allBrandProductLineOptions.map((productLine) => {
          return (
            <Grid
              item
              lg={3}
              md={4}
              xs={6}
              key={`${productLine.publicId}GridItem`}
            >
              <ListItem key={productLine.publicId} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => handleOnClickSelect(productLine)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checkSelectedProductLine(productLine)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={productLine.productLineName}
                    primary={
                      <Typography>{productLine.productLineName}</Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </Grid>
          );
        })}
      </Grid>
    </List>
  );
};

export default ProductLineList;
