import gql from 'graphql-tag';

export const findQuery = gql`
  query findRemindersQuery($filter: FindRemindersInput) {
    findReminders(filter: $filter) {
      queryCount
      result {
        publicId
        type
        date
        time
        notes
        relatedEntityName
        relations {
          entityType
          entityPublicId
        }
      }
    }
  }
`;

export const addItemQuery = gql`
  mutation addReminderMutation($input: AddReminderInput) {
    addReminder(input: $input) {
      publicId
      type
      date
      time
      notes
      relatedEntityName
      relations {
        entityType
        entityPublicId
      }
    }
  }
`;

export const deleteItemQuery = gql`
  mutation deleteReminderMutation($input: DeleteReminderInput) {
    deleteReminder(input: $input) {
      publicId
      type
      date
      time
      notes
      relatedEntityName
    }
  }
`;

export const updateItemQuery = gql`
  mutation updateReminder($input: UpdateReminderInput) {
    updateReminder(input: $input) {
      publicId
      type
      date
      time
      notes
      relatedEntityName
    }
  }
`;
export const shareItemQuery = gql`
  mutation shareReminder($input: ShareReminderInput) {
    shareReminder(input: $input)
  }
`;

export const findTypesQuery = gql`
  query findTypesQuery($filter: String) {
    findReminderTypes(filter: $filter) {
      queryCount
      result {
        label
        value
      }
    }
  }
`;
