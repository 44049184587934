import React, { FC, useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Button,
  Tooltip,
  Chip,
  Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useIsMobile } from '@bestseller-bit/frontend-community.utilities.is-mobile';
import { SearchOptionByNameOrNumber } from '@bestseller-bit/sales-and-invoicing.input.autocomplete';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { PageOnlyAvailableForDesktop } from '@bestseller-bit/sales-and-invoicing.ui-elements.page-only-available-for-desktop';
import { rootStateInterface } from '../../../reducers';
import BrandButtonList from './BrandButtonList';
import { GetBusinessTradingPartner } from '../../../queries/app/businessTradingPartners/btp.mycustomers/app.btp.all.customers.query.result.interface';
import { Brand } from '../../../@types/branding/branding.types';
import CSATitle from './CSA.title';
import { getAllBTPCustomersQuery } from '../../../queries/app/businessTradingPartners/btp.mycustomers/app.btp.all.customers.query';
import { isNumeric } from '../../../utility/stringIsNumber';
import {
  getAllBTPCustomers,
  getAllBTPCustomersVariables,
  getAllBTPCustomers_getAllBusinessTradingPartners_result,
} from '../../../queries/app/businessTradingPartners/btp.mycustomers/__generated__/getAllBTPCustomers';
import PathConstants from '../../../routes/pathConstants';

export interface CSACustomerPropType {
  selectedCustomerPublicId: string;
  selectedBrandPublicId: string;
}

export type AssortmentType = 'FIXED' | 'CUSTOMIZED' | 'SKU' | 'CSA';
export const FIXED = 'FIXED';
export const CUSTOMIZED = 'CUSTOMIZED';
export const SKU = 'SKU';
export const CSA = 'CSA';

const ShowCSA: FC = () => {
  const navigate = useNavigate();

  const [selectedCustomer, setSelectedCustomer] =
    useState<GetBusinessTradingPartner | null>(null);
  const [blockSearch, setBlockSearch] = useState<boolean>(true);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const brandingData = useSelector<rootStateInterface, any>(
    (state) => state.application.app.branding.brandRows
  );
  const isMobile = useIsMobile();

  useEffect(() => {
    const checkAllSelected = () => {
      if (
        selectedCustomer &&
        selectedCustomer.name !== '' &&
        selectedBrand &&
        selectedBrand.name !== ''
      ) {
        setBlockSearch(false);
      } else {
        setBlockSearch(true);
      }
    };
    checkAllSelected();
  }, [selectedCustomer, selectedBrand]);

  const handleBrandClick = (brand: any) => {
    setSelectedBrand(brand);
  };

  const [getData] = useLazyQuery<
    getAllBTPCustomers,
    getAllBTPCustomersVariables
  >(getAllBTPCustomersQuery);

  const getDataWrapper = (take: number, value: string) => {
    return getData({
      variables: {
        textSearch: value,
        filterBy: isNumeric(value) ? 'number' : 'name',
        take,
      },
    });
  };

  const onSelectCustomer = (
    customer: getAllBTPCustomers_getAllBusinessTradingPartners_result | null
  ): void => {
    setSelectedCustomer(customer);
  };
  const isBrandSelected = (brand: Brand) =>
    brandingData.includes(brand.publicId);

  const showIfSelectedCustomer = () => {
    if (selectedCustomer && selectedCustomer.name !== '') {
      return (
        <Chip
          size="medium"
          label={selectedCustomer.name}
          onDelete={handleRemoveCustomerChoice}
          sx={{
            backgroundColor: 'companyPrimary.main',
            color: 'common.white',
            fontSize: 16,
            mt: 4,
            width: '100%',
            borderRadius: 1,
          }}
        />
      );
    }

    return (
      <SearchOptionByNameOrNumber
        label="BTP name or number"
        onSelect={onSelectCustomer}
        bgcolor="white"
        size="small"
        getData={getDataWrapper}
      />
    );
  };

  const handleRemoveCustomerChoice = () => {
    setSelectedCustomer(null);
  };

  const onClickGoToAssortment = (assortmentName: AssortmentType) => {
    if (selectedCustomer && selectedBrand) {
      if (assortmentName === FIXED) {
        navigate(PathConstants.FIXED_ASSORTMENT, {
          state: { selectedCustomer, selectedBrand },
        });
      } else if (assortmentName === CUSTOMIZED) {
        navigate(PathConstants.CUSTOMIZED_ASSORTMENT, {
          state: { selectedCustomer, selectedBrand },
        });
      } else if (assortmentName === SKU) {
        navigate(PathConstants.FIXED_ASSORTMENT, {
          state: { selectedCustomer, selectedBrand },
        });
      }
    }
  };
  if (isMobile) {
    return <PageOnlyAvailableForDesktop />;
  }

  return (
    <>
      <CSATitle
        assortmentType="CSA"
        selectedBrandName={selectedBrand?.name}
        selectedCustomerName={selectedCustomer?.name}
      />
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            sx={{
              p: 4,
              textAlign: 'center',
              height: '70vh',
            }}
          >
            <Typography variant="h3" sx={{ m: 2, textTransform: 'uppercase' }}>
              Select BTP by name or number
            </Typography>
            <Box
              sx={{
                height: '60vh',
              }}
            >
              {showIfSelectedCustomer()}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Paper
            sx={{
              p: 4,
              textAlign: 'center',
              height: '70vh',
            }}
          >
            <Typography
              variant="h3"
              sx={{ m: 2, textTransform: 'uppercase', mb: 6 }}
            >
              Select a brand
            </Typography>
            <BrandButtonList
              listData={brandingData}
              handleBrandClick={handleBrandClick}
              customer="CSA"
              isMultiBrand={isBrandSelected}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={{ xs: 'center', md: 'flex-end' }}
        spacing={2}
        sx={{ pt: 2 }}
      >
        <Grid item>
          <Tooltip
            title={
              blockSearch
                ? 'Select a customer and a brand to continue'
                : 'Go to customized assortments'
            }
            arrow
            placement="bottom"
          >
            <span>
              <Button
                disabled={blockSearch}
                onClick={() => onClickGoToAssortment(CUSTOMIZED)}
              >
                Customized Assortments
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              blockSearch
                ? 'Select a customer and a brand to continue'
                : 'Go to fixed assortments'
            }
            arrow
            placement="bottom"
          >
            <span>
              <Button
                disabled={blockSearch}
                onClick={() => onClickGoToAssortment(FIXED)}
              >
                Fixed Assortments
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

export default ShowCSA;
