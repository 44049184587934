import React, { FC } from 'react';
import {
  Fab,
  Zoom,
  useScrollTrigger,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollTop: FC = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // "back-to-top-anchor" is the id set in the top element we want to reach when pressing this button.
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  if (isMobile)
    return (
      <Zoom in={trigger}>
        <Box
          onClick={handleClick}
          sx={{
            bottom: 90,
            right: 35,
            position: 'fixed',
            opacity: 0.6,
            zIndex: 101,
          }}
        >
          <Fab color="secondary" size="small">
            <KeyboardArrowUpIcon style={{ color: 'white' }} />
          </Fab>
        </Box>
      </Zoom>
    );
  return null;
};

export default ScrollTop;
