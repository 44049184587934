import React, { FC, useState } from 'react';
import {
  Typography,
  InputBase,
  Tooltip,
  Zoom,
  TextField,
  Hidden,
  SxProps,
} from '@mui/material';
import { useIsMobile } from '@bestseller-bit/frontend-community.utilities.is-mobile';
import { CustomDialog } from '@bestseller-bit/sales-and-invoicing.ui-elements.dialog';
import { store } from '../../../../../middleware/store';
import {
  addBTPtoCallListAndUpdateList,
  addShipToToCallListAndUpdateList,
  addMyContactBTPToCallListAndUpdateList,
  addContactShipToToCallListAndUpdateList,
  addExternalContactToCallListAndUpdateList,
} from '../../call.list/call.list.utils/call.list.utils';
import { UpdatableCallList } from '../../../../../services/shared/call.list/interfaces';

const MAX_NOTE_LENGHT: number = 20;

const classes: { [k: string]: SxProps } = {
  InputBase: {
    marginTop: '0px',
    width: '100%',
    textOverflow: 'ellipsis',
    fontSize: 12,
    overflow: 'hidden',
    paddingRight: '1.2rem',
  },
  popper: {
    '& .MuiTooltip-popper': {
      color: 'blue',
    },
  },
};

type EditTextCellPageProps = {
  cellRow?: any;
  cellData?: string;
};

const EditNoteCallList: FC<EditTextCellPageProps> = ({ cellRow, cellData }) => {
  const [oldValue, setOldValue] = useState(cellData);
  const [newValue, setNewValue] = useState(oldValue);
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();

  const handleEdit = (noteValue: string) => {
    setNewValue(noteValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setNewValue(oldValue);
    setOpen(false);
  };

  const onEdit = () => {
    const updateParams: UpdatableCallList = {
      updatableCallList: [
        {
          ownerPublicId: cellRow.callListRelationPublicId, // TODO: Type for cellRow and cellData
          note: newValue,
          lastCallDate: cellRow.lastCallDate,
        },
      ],
    };
    if (cellRow.contactType === 'BUSINESS_TRADING_PARTNER') {
      addBTPtoCallListAndUpdateList(store.dispatch, updateParams);
    } else if (cellRow.contactType === 'SHIP_TO') {
      addShipToToCallListAndUpdateList(store.dispatch, updateParams);
    } else if (
      cellRow.contactType === 'CONTACT' &&
      cellRow.contactTypeDisplayName === 'BTP'
    ) {
      addMyContactBTPToCallListAndUpdateList(store.dispatch, updateParams);
    } else if (
      cellRow.contactType === 'CONTACT' &&
      cellRow.contactTypeDisplayName === 'Ship To'
    ) {
      addContactShipToToCallListAndUpdateList(store.dispatch, updateParams);
    } else if (
      cellRow.contactType === 'CONTACT' &&
      cellRow.contactTypeDisplayName === 'External Contact'
    ) {
      addExternalContactToCallListAndUpdateList(store.dispatch, updateParams);
    }
    setOldValue(newValue);
    handleClickClose();
  };

  return (
    <>
      {oldValue && oldValue.length > 0 ? (
        <Hidden mdUp implementation="js">
          <Typography onClick={handleClickOpen}>{oldValue}</Typography>
        </Hidden>
      ) : (
        <Hidden mdUp implementation="js">
          <InputBase inputComponent="input" onClick={handleClickOpen} />
        </Hidden>
      )}
      <Hidden mdDown implementation="js">
        <Tooltip
          title={oldValue ? (oldValue.length > 0 ? oldValue || '' : '') : ''}
          TransitionComponent={Zoom}
          sx={classes.popper}
          disableHoverListener={oldValue ? oldValue.length === 0 : true}
          disableFocusListener
        >
          <InputBase
            inputComponent="input"
            onClick={handleClickOpen}
            sx={
              (classes.InputBase,
              {
                caretColor: 'transparent',
              })
            }
            type="text"
            name={cellRow.name}
            value={
              oldValue !== null
                ? oldValue !== undefined && oldValue.length > MAX_NOTE_LENGHT
                  ? `${oldValue.substring(0, MAX_NOTE_LENGHT)} ...`
                  : oldValue
                : ''
            }
            margin="dense"
          />
        </Tooltip>
      </Hidden>
      <CustomDialog
        maxWidth="sm"
        minHeight="auto"
        openState={[open, setOpen]}
        onClose={handleClickClose}
        title="Edit note for"
        buttons={{
          buttons: [
            {
              label: 'Cancel',
              onClick: handleClickClose,
            },
            {
              label: 'Save',
              onClick: onEdit,
            },
          ],
        }}
      >
        <Typography variant="h3">
          {cellRow.name ? cellRow.name : cellRow.contactName}
        </Typography>
        <TextField
          id="standard-multiline-flexible"
          autoFocus
          onFocus={(e) => {
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            );
          }}
          fullWidth
          multiline
          maxRows={isMobile ? '15' : '6'}
          value={newValue || ''}
          onChange={(event) => handleEdit(event.target.value)}
          rows="6"
          variant="outlined"
        />
      </CustomDialog>
    </>
  );
};

export default EditNoteCallList;
