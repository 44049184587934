import { ReactElement } from 'react';

export interface GridOptions {
  slices: Date[];
  height: number;
  view: EventViewType;
}
// eslint-disable-next-line no-shadow
export enum EventViewType {
  DAY = 'view_day',
  MONTH = 'view_month',
  WEEK = 'view_week',
}

export interface EventInterface {
  id: string;
  start: Date;
  end: Date;
  duration: number;
  isAllDay: boolean;
  title: string;
  subtitle?: string | ReactElement;
  description?: string;
  color: string;
}

export interface EventProps extends EventInterface {
  view: string;
}

export interface OneDayEventsInterface {
  allDay: EventInterface[];
  timed: EventInterface[];
}

export interface AllDaysEventsInterface {
  [name: string]: OneDayEventsInterface;
}

export interface EventsInfo {
  events: AllDaysEventsInterface;
  subtitles: string[];
  titles: string[];
  maxAllDayEvents: number; // usefull to calculate the max Height of the view
}

export interface EventViewerProps {
  heightIncrement: number; // height increment for allDayEvents
  gridHeight: number; // height to render grids
  view: EventViewType;
  start: Date; // starting datetime for the view
  end: Date; // ending datetime for the view
  stepInMinutes: number; // minutes per step in the view
  events: AllDaysEventsInterface;
  navigateTo?: (day: Date, view: EventViewType) => void;
}

export interface EventViewProps {
  title?: {
    dow: string;
    number: number;
  };
  renderTimeRuler?: () => ReactElement;
  renderDayGrid?: () => ReactElement;
  minuteHeight?: number;
  heightIncrement: number;
  start: Date;
  end?: Date; // for complete week Vs. work week
  events: AllDaysEventsInterface;
  navigateTo?: (day: Date, view: EventViewType) => void;
}
