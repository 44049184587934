import { combineReducers } from 'redux';
import {
  appShowBTPMycustomersPageStateInterface,
  showBTPMycustomersReducer,
} from './businessTradingPartners/app.show.btp.mycustomers.page.reducer';
import {
  showBTPShipTosReducer,
  appShowBTPShipTosPageStateInterface,
} from './BTP.shipTos/app.show.btp.shipTos.page.reducer';
import {
  showBTPShipToDetailsReducer,
  appShowBTPShipToDetailsPageStateInterface,
} from './BTP.shipTos.details/app.show.btp.shipTo.details.page.reducer';
import {
  showShipTosReducer,
  appShowShipTosPageStateInterface,
} from './shipTos/app.show.shipTos.page.reducer';
import {
  brandingReducer,
  appBrandingStateInterface,
} from './branding/app.branding.reducer';
import { bestOneTypeBestOneMainPageAndCommon } from '..';

export const appReducer = combineReducers({
  BTP: showBTPMycustomersReducer,
  BTPShipTos: showBTPShipTosReducer,
  BTPShipToDetails: showBTPShipToDetailsReducer,
  shipTos: showShipTosReducer,
  branding: brandingReducer,
});

export type appCustomersMainPageStateInterface = {
  currentUser: string | null;
  authToken: string | null | undefined;
  currentUserPublicId: string;
} & bestOneTypeBestOneMainPageAndCommon;

export interface appStateInterface {
  appMainPage: appCustomersMainPageStateInterface;
  BTP: appShowBTPMycustomersPageStateInterface;
  BTPShipTos: appShowBTPShipTosPageStateInterface;
  BTPShipToDetails: appShowBTPShipToDetailsPageStateInterface;
  shipTos: appShowShipTosPageStateInterface;
  branding: appBrandingStateInterface;
}
