import React from 'react';
import { Provider } from 'react-redux';
import * as dotenv from 'dotenv';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from '@bestseller-bit/sales-and-invoicing.configuration.theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FeedbackContextWrapper } from '@bestseller-bit/sales-and-invoicing.ui-elements.feedback';
import { ApolloProvider } from '@apollo/client';
import CurrentUserContainer from './application/app/_MainApp/CurrentUserContainer';
import { store } from './middleware/store';
import Authorization from './utility/Authorization';
import { apolloClient } from './utility/ApolloConfig';
import MainRouter from './application/app/_MainApp/MainRouter';

dotenv.config();

const ZINDEX = 1300;

const googlePlacesDropStyles = {
  MuiCssBaseline: {
    styleOverrides: {
      '.pac-container': { zIndex: ZINDEX + 1 },
    },
  },
  MuiDialog: {
    defaultProps: {
      style: {
        zIndex: ZINDEX,
      },
    },
  },
};

const App: React.FC = () => {
  /* CUSTOM SALESTOOL THEME */
  theme.components = {
    ...theme.components,
    // This is needed to show the Google Places Autocomplete dropdown on a Dialog
    ...googlePlacesDropStyles,
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Authorization>
        <ApolloProvider client={apolloClient}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Provider store={store}>
              <FeedbackContextWrapper>
                <CurrentUserContainer>
                  <MainRouter />
                </CurrentUserContainer>
              </FeedbackContextWrapper>
            </Provider>
          </LocalizationProvider>
        </ApolloProvider>
      </Authorization>
    </ThemeProvider>
  );
};

export default App;
