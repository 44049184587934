import { ApolloQueryResult } from '@apollo/client';
import { apolloClient } from '../../../../utility/ApolloConfig';
import getBTPShipTosQuery from '../../../../queries/app/shipTos/BTP.shipTos/app.btp.shiptos.query';
import { getBTPShipTosQueryResultInterface } from '../../../../queries/app/shipTos/BTP.shipTos/app.btp.shiptos.query.result.interface';

export type BTPshipTosQueryProps = {
  textSearch?: string;
  salesPersonId: string;
  BTPPublicId: string;
  onlyMine: boolean;
};

export const apiGetBTPShipTos = (queryParams: BTPshipTosQueryProps) => {
  const { textSearch, salesPersonId, BTPPublicId, onlyMine } = queryParams;
  const filterShipTos = { columns: ['number', 'name'], value: textSearch };
  const currencyCode =
    localStorage.getItem('currency') !== null
      ? localStorage.getItem('currency')
      : 'EUR';
  return apolloClient
    .query({
      query: getBTPShipTosQuery,
      variables: {
        filterShipTos,
        salesPersonId,
        BTPPublicId,
        currencyCode,
        onlyMine,
      },
    })
    .then(
      (response: ApolloQueryResult<getBTPShipTosQueryResultInterface>) =>
        response.data
    );
};
