import React, { Dispatch, FC, SetStateAction, useContext } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Link,
  Grid,
  Typography,
  SxProps,
} from '@mui/material';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import CheckIcon from '@mui/icons-material/Check';
import { useIsMobile } from '@bestseller-bit/frontend-community.utilities.is-mobile';
import { CustomDialog } from '@bestseller-bit/sales-and-invoicing.ui-elements.dialog';
import { createOrUpdatePrimaryPhoneService } from '../../../../services/mutations/set.primary.phone.number/set.primary.phone.number.services';
import getBTPShipTos from '../../../../services/shipTos/BTP.shipTos/bestone.BTP.shipTos.services';
import { store } from '../../../../middleware/store';
import { SHOW_BTP_SHIPTOS_ALL_MY_BTP_CONTACTS } from '../../../../constants/app/app.show.btp.shipTos.page.constants';
import { updateBTPCallList } from '../call.list/call.list.utils/call.list.utils';
import { registerCallCheckingCallListService } from '../../../../services/mutations/register.contact.call/register.contact.call.services';
import {
  REFRESH_WHEN_REGISTER_CALL,
  SHOW_CALL_LIST,
} from '../../../../constants/app/app.show.btp.mycustomers.page.constants';
import { UpdatableCallListObject } from '../../../../services/shared/call.list/interfaces';
import { ShipToInfoContext } from '../../../app/shipTo.info/context/ShipToInfo.context';

const classes: { [k: string]: SxProps } = {
  typography: {
    color: 'black',
  },
  typographyGray: {
    color: 'lightGray',
    fontSize: 14,
  },
  typographyGreen: {
    color: 'green',
  },
  checkIcon: {
    paddingTop: 10,
  },
};

interface DialogProps {
  open: [boolean, Dispatch<SetStateAction<boolean>>];
  selectedValue: any;
  onClose: (value: any) => void;
  primarySet: (value: string) => void;
  phoneList: any[];
}

type StatePros = {
  contactPhoneList: any[];
  contactPublicId: string;
  contactTypeDisplayName: string;
  btpId?: string;
  shiptoId?: string;
  enableDropDown: boolean;
  enableCall?: boolean;
  salesPersonPublicId: string;
  calledFromCallList?: boolean;
  cellRow: any;
};

const ContactPhoneListDropDown: FC<DialogProps> = ({
  onClose,
  primarySet,
  selectedValue,
  open,
  phoneList,
}) => {
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: any) => {
    onClose(value.phoneConcat);
    primarySet(value.listItemPublicId);
  };

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      openState={open}
      title="Set primary phone"
      maxWidth="sm"
      minHeight="auto"
    >
      <List>
        {phoneList.map((phone: any) => {
          const listItemClicked = {
            phoneConcat: `${phone.countryCode}${phone.phoneNumber}`,
            listItemPublicId: phone.publicId,
          };

          return (
            <ListItem
              button
              onClick={() => handleListItemClick(listItemClicked)}
              key={phone.publicId}
            >
              <ListItemText
                primary={
                  <Typography
                    sx={
                      phone.isPrimaryPhone
                        ? classes.typographyGreen
                        : classes.typography
                    }
                  >
                    {`${phone.countryCode} - ${phone.phoneNumber}`}
                    {phone.isPrimaryPhone ? (
                      <CheckIcon sx={classes.checkIcon} />
                    ) : null}
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </CustomDialog>
  );
};

const SelectPrimaryPhone: FC<StatePros> = ({
  contactPhoneList,
  contactPublicId,
  shiptoId,
  salesPersonPublicId,
  btpId,
  cellRow,
  calledFromCallList: calledFromCallListFromProps,
  enableDropDown,
  enableCall,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [contactPhoneId, setContactPhoneId] = React.useState('');
  const [primaryContactPhoneId, setPrimaryContactPhoneId] = React.useState('');
  const [primaryPhoneSelected, setPrimaryPhoneSelected] = React.useState('');
  const [isPrimaryPhone, setIsPrimaryPhone] = React.useState<boolean>();
  const { refetchShipToInfoData } = useContext(ShipToInfoContext);

  const isMobile = useIsMobile();
  React.useEffect(() => {
    if (
      contactPhoneList.length === 1 &&
      contactPhoneList[0].isPrimaryPhone === false
    ) {
      const primaryPhoneProps = {
        contactPublicId,
        contactPhonePublicId: contactPhoneList[0].publicId,
      };
      if (contactPublicId) {
        createOrUpdatePrimaryPhoneService(primaryPhoneProps);
      }
      setSelectedValue(
        `${contactPhoneList[0].countryCode}${contactPhoneList[0].phoneNumber}`
      );
      setPrimaryPhoneSelected(
        `${contactPhoneList[0].countryCode}${contactPhoneList[0].phoneNumber}`
      );
    } else if (contactPhoneList.length === 0) {
      setSelectedValue(`No phone`);
    } else if (contactPhoneList.length > 0) {
      contactPhoneList.forEach((phoneList) => {
        if (phoneList.isPrimaryPhone === true) {
          setSelectedValue(`${phoneList.countryCode}${phoneList.phoneNumber}`);
          setPrimaryPhoneSelected(
            `${phoneList.countryCode}${phoneList.phoneNumber}`
          );
          setIsPrimaryPhone(true);
          setContactPhoneId(phoneList.publicId);
        }
      });
    }
  }, [contactPhoneList, contactPublicId]);

  React.useEffect(() => {
    if (
      isPrimaryPhone === false &&
      contactPhoneList.length > 0 &&
      contactPhoneList[0].isPrimaryPhone === false
    ) {
      const primaryPhoneProps = {
        contactPublicId,
        contactPhonePublicId: contactPhoneList[0].publicId,
      };
      if (contactPublicId) {
        createOrUpdatePrimaryPhoneService(primaryPhoneProps);
      }
    }
  }, [isPrimaryPhone, contactPhoneList, contactPublicId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handlePrimary = (phoneId: string) => {
    setPrimaryContactPhoneId(phoneId);
  };

  React.useEffect(() => {
    if (primaryContactPhoneId !== '') {
      const primaryPhoneProps = {
        contactPublicId,
        contactPhonePublicId: primaryContactPhoneId,
      };
      const queryParamsBTPShipTos = {
        salesPersonId:
          salesPersonPublicId && salesPersonPublicId !== undefined
            ? salesPersonPublicId
            : '',
        BTPPublicId: btpId && btpId !== undefined ? btpId : '',
        textSearch: '',
        onlyMine: true,
      };
      if (shiptoId) {
        createOrUpdatePrimaryPhoneService(primaryPhoneProps)
          .then(() => {
            refetchShipToInfoData();
          })
          .then(() => updateBTPCallList(store.dispatch));
      } else {
        createOrUpdatePrimaryPhoneService(primaryPhoneProps)
          .then(() => getBTPShipTos(queryParamsBTPShipTos))
          .then((result: any) =>
            store.dispatch({
              type: SHOW_BTP_SHIPTOS_ALL_MY_BTP_CONTACTS,
              payload: result,
            })
          )
          .then(() => updateBTPCallList(store.dispatch));
      }
    }
  }, [
    primaryContactPhoneId,
    btpId,
    contactPublicId,
    salesPersonPublicId,
    shiptoId,
    refetchShipToInfoData,
  ]);

  function handleClickToCall(phone: string, phoneId: string) {
    return (
      <Link
        href={
          isMobile
            ? `msteams://teams.microsoft.com/l/call/0/0?users=4:00${phone}`
            : `tel:00${phone}`
        }
        onClick={() => handleLastCall(phoneId)}
        target="_blank"
      >
        {phone}
      </Link>
    );
  }
  function handleLastCall(phoneId: string) {
    const updateParams: UpdatableCallListObject = {
      ownerPublicId: cellRow.callListRelationPublicId,
      note: cellRow.note,
    };

    const { contactType, contactTypeDisplayName } = cellRow;
    const calledFromCallList: boolean = calledFromCallListFromProps || false;

    const lastCallParams = {
      contactPhonePublicId: phoneId,
      contactPublicId,
    };

    registerCallCheckingCallListService(
      lastCallParams,
      updateParams,
      contactType,
      contactTypeDisplayName,
      calledFromCallList
    ).then((result) => {
      if (result) {
        store.dispatch({ type: SHOW_CALL_LIST, payload: result });
      }
      store.dispatch({ type: REFRESH_WHEN_REGISTER_CALL });
    });
  }

  return (
    <Grid container direction="row">
      <Grid item>
        {(enableDropDown && selectedValue !== 'No phone') ||
        (enableCall && selectedValue !== 'No phone') ? (
          handleClickToCall(selectedValue, contactPhoneId)
        ) : (
          <Typography
            component="span"
            sx={
              selectedValue === 'No phone'
                ? classes.typographyGray
                : classes.typography
            }
          >
            {selectedValue}
          </Typography>
        )}
      </Grid>
      <Grid item style={{ marginTop: -5 }}>
        {contactPhoneList !== null &&
        contactPhoneList.length > 1 &&
        enableDropDown ? (
          <ExpandMoreSharpIcon onClick={handleClickOpen} />
        ) : null}
      </Grid>
      {contactPhoneList !== null &&
      contactPhoneList.length > 0 &&
      primaryPhoneSelected === '' ? (
        <>
          <Typography sx={classes.typographyGray}>Set primary phone</Typography>
          <ContactPhoneListDropDown
            phoneList={contactPhoneList}
            selectedValue={selectedValue}
            open={[open, setOpen]}
            onClose={handleClose}
            primarySet={handlePrimary}
          />
        </>
      ) : (
        <ContactPhoneListDropDown
          phoneList={contactPhoneList}
          selectedValue={selectedValue}
          open={[open, setOpen]}
          onClose={handleClose}
          primarySet={handlePrimary}
        />
      )}
    </Grid>
  );
};

export default SelectPrimaryPhone;
