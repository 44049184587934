import gql from 'graphql-tag';

export const getUserListQuery = gql`
  query getUserList($search: UserSearch) {
    users(search: $search) {
      queryCount
      result {
        publicId
        employeePublicId
        name
        email
        title
      }
    }
  }
`;
