export const BESTONE_AUTH_BEGIN_LOGIN_PENDING =
  'BESTONE_AUTH_BEGIN_LOGIN_PENDING';
export const BESTONE_AUTH_LOAD_TOKEN_PENDING =
  'BESTONE_AUTH_LOAD_TOKEN_PENDING';
export const BESTONE_AUTH_LOAD_TOKEN_DONE = 'BESTONE_AUTH_LOAD_TOKEN_DONE';
export const BESTONE_AUTH_LOAD_TOKEN_DONE_NO_TOKEN =
  'BESTONE_AUTH_LOAD_TOKEN_DONE_NO_TOKEN';
export const BESTONE_AUTH_LOAD_TOKEN_FAILED = 'BESTONE_AUTH_LOAD_TOKEN_FAILED';
export const BESTONE_AUTH_PENDING = 'BESTONE_AUTH_PENDING';
export const BESTONE_AUTH_PENDING_DONE = 'BESTONE_AUTH_PENDING_DONE';
export const BESTONE_AUTH_PENDING_FAILED = 'BESTONE_AUTH_PENDING_FAILED';
export const BESTONE_AUTH_GET_USER_INFO_PENDING =
  'BESTONE_AUTH_GET_USER_INFO_PENDING';
export const BESTONE_AUTH_GET_USER_INFO_PENDING_DONE =
  'BESTONE_AUTH_GET_USER_INFO_PENDING_DONE';
export const BESTONE_AUTH_GET_USER_INFO_PENDING_FAILED =
  'BESTONE_AUTH_GET_USER_INFO_PENDING_FAILED';
export const BESTONE_AUTH_LOGOUT_PENDING = 'BESTONE_AUTH_LOGOUT_PENDING';
export const BESTONE_AUTH_LOGOUT_PENDING_DONE =
  'BESTONE_AUTH_LOGOUT_PENDING_DONE';
export const BESTONE_AUTH_LOGOUT_PENDING_FAILED =
  'BESTONE_AUTH_LOGOUT_PENDING_FAILED';
