import { createContext } from 'react';
import {
  ShipToContact,
  ShipToKeyFigures,
  ShipToDetails,
  ProductLine,
} from '../types';

export type ShipToInfoContextType = {
  details: ShipToDetails;
  keyFigures: ShipToKeyFigures;
  contacts: ShipToContact[];
  myContacts: ShipToContact[];
  productLines: ProductLine[];
  refetchShipToInfoData: () => void;
};

export const ShipToInfoContext = createContext<ShipToInfoContextType>(
  {} as ShipToInfoContextType
);
ShipToInfoContext.displayName = 'Activate Card Context';
