import { ApolloQueryResult } from '@apollo/client';
import { apolloClient } from '../../../../utility/ApolloConfig';
import { getUserListQuery } from '../../../../queries/shared/user.list/bestone.user.list.query';
import { getUserListQueryResultInterface } from '../../../../queries/shared/user.list/bestone.user.list.query.result.interface';

export type userListqueryProps = {
  searchValue: string;
  searchOnlyWithBTP: boolean | undefined;
  isIncludingMyself: boolean | undefined;
};

export type userSearch = {
  user: userColumns;
  onlyWithBTP?: boolean;
  isIncludingMyself?: boolean;
};

export type userColumns = {
  name?: String;
  email?: String;
  title?: String;
};

export const apiUserList = (userListQueryParams: userListqueryProps) => {
  const { searchValue, searchOnlyWithBTP, isIncludingMyself } =
    userListQueryParams;
  const search: userSearch = {
    user: { name: searchValue },
  };
  if (searchOnlyWithBTP !== undefined) {
    search.onlyWithBTP = searchOnlyWithBTP;
  }
  if (isIncludingMyself !== undefined) {
    search.isIncludingMyself = isIncludingMyself;
  }
  return apolloClient
    .query({
      query: getUserListQuery,
      variables: { search },
    })
    .then(
      (response: ApolloQueryResult<getUserListQueryResultInterface>) =>
        response.data
    );
};
