import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import { Badge, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { CustomDialog } from '@bestseller-bit/sales-and-invoicing.ui-elements.dialog';
import { useFeedbackContext } from '@bestseller-bit/sales-and-invoicing.ui-elements.feedback';
import { removeContactsFromCallListMutation } from '../../../../mutations/app/remove.BTP.from.call.list/app.remove.BTP.from.call.list.mutation';
import { rootStateInterface } from '../../../../reducers';
import CallListTable, {
  CallListTypeForCustomTable,
} from './mui.call.list.table';
import CreateExternalContact from '../create.external.contact/mui.create.external.contact';
import MuiCallListPanelHelper from '../panels/mui.callist.panel.helper';
import { handleStopImpersonate } from '../impersonate/mui.stop.impersonate.menu';
import {
  IMPERSONATE_USER,
  SET_NOTIFICATION,
} from '../../../../constants/shared/bestone.global.constants';
import {
  SHOW_CALL_LIST,
  SHOW_KEY_FIGURES,
} from '../../../../constants/app/app.show.btp.mycustomers.page.constants';
import { userImpersonateInterface } from '../impersonate/impersonate.utils';
import removeContactsfromCallList from '../../../../services/shared/call.list/remove.BTP.from.call.list/bestone.remove.BTP.from.call.list.services';
import { updateBTPCallList } from './call.list.utils/call.list.utils';
import { store } from '../../../../middleware/store';
import { SelectedContacts } from '../../../../services/shared/call.list/remove.BTP.from.call.list/api/bestone.remove.BTP.from.call.list.api';
import PathConstants from '../../../../routes/pathConstants';
import { UpdatedContactPhone } from '../../../../__generated__/globalTypes';

export type CallListType = {
  contactName: string;
  contactPhoneNumber: UpdatedContactPhone[];
  contactPublicId: string;
  contactType: string; // TODO: Change to callListRelationName (both UI and BFF return)
  contactTypeDisplayName: string;
  lastCallDate: Date;
  name: string;
  note: string;
  number: string;
  callListRelationPublicId: string;
  actions?: any;
  createdByUserName?: string;
  createdDate?: string;
  entityPublicId?: string;
};

const CallList: FC = () => {
  const {
    callListRows,
    callListCount,
    currentQueryUserName,
    currentUserQueryId,
    impersonating,
    currentUserWholeData,
  } = useSelector(
    (state: rootStateInterface) => state.application.shared.bestoneCommon
  );
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openCreateContact, setOpenCreateContact] = useState<boolean>(false);
  const [prevRow, setPrevRow] = useState(callListRows);
  const [selected, setSelected] = useState<CallListTypeForCustomTable[]>([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const { addErrorMessage } = useFeedbackContext();
  const addRowToSelected = (row: CallListTypeForCustomTable) => {
    if (selected.includes(row)) {
      setSelected((previousValue) =>
        previousValue.filter((value) => {
          return value !== row;
        })
      );
    } else setSelected((previousValue) => [...previousValue, row]);
  };
  const isSelected = (row: CallListTypeForCustomTable) =>
    selected
      .map((selection) => selection.contactPublicId)
      .includes(row.contactPublicId);

  if (callListRows !== prevRow) {
    setPrevRow(callListRows);
  }

  const handleOpenCreateContact = () => {
    setOpenCreateContact(true);
  };

  const handleRemoveContacts = () => {
    const deleteCallList: SelectedContacts[] = selected.map((callList) => ({
      callListRelationPublicId: callList.callListRelationPublicId,
      callListRelationName: callList.contactType,
    }));
    removeContactsfromCallList(deleteCallList).then((result) => {
      if (result) {
        store.dispatch({ type: SHOW_CALL_LIST, payload: result });
      } else {
        addErrorMessage(
          'There was an error deleting the selected contacts.',
          removeContactsFromCallListMutation
        );
      }
    });
    setSelected([]);
    setConfirmDelete(false);
  };

  const handleStopImpersonateClick = () => {
    const notifyError = (payload: any) =>
      dispatch({ type: SET_NOTIFICATION, payload });
    const impersonateUser = (payload: any) =>
      dispatch({ type: IMPERSONATE_USER, payload });
    const loadCallList = (payload: any) =>
      dispatch({ type: SHOW_CALL_LIST, payload });
    const keyFigures = (payload: any) =>
      dispatch({ type: SHOW_KEY_FIGURES, payload });
    const impersonateFunctions: userImpersonateInterface = {
      impersonateUser,
      loadCallList,
      keyFigures,
    };
    handleStopImpersonate(
      currentUserWholeData.queryUserMail,
      impersonateFunctions,
      notifyError,
      navigate
    );
    setOpen(false);
  };

  const handleClickRedirection = (row: CallListType) => {
    if (row.contactTypeDisplayName === 'BTP' && row.entityPublicId) {
      const path = PathConstants.BUSINESS_TRADING_PARTNERS_DETAILS.replace(
        ':BTPPublicId',
        row.entityPublicId
      );
      navigate(path);
    } else if (row.contactTypeDisplayName === 'Ship To' && row.entityPublicId) {
      const path = PathConstants.SHIP_TO_DETAILS.replace(
        ':shipToPublicId',
        row.entityPublicId
      );
      navigate(path);
    }
    setOpen(false);
  };

  useEffect(() => {
    updateBTPCallList(store.dispatch);
  }, [currentUserQueryId]);

  const confirmationButtons = [
    {
      label: 'Confirm',
      onClick: handleRemoveContacts,
    },
    {
      label: 'Cancel',
      onClick: () => setConfirmDelete(false),
    },
  ];

  const removeButton = {
    label:
      selected.length !== 0
        ? `Remove contacts (${selected.length})`
        : 'Remove contacts',
    onClick: () => setConfirmDelete(true),
    disabled: selected.length < 1,
  };

  const getButtons = () => {
    if (confirmDelete) {
      return confirmationButtons;
    }
    if (impersonating) {
      return [
        removeButton,
        (isDesktop || !confirmDelete) && {
          label: 'Stop Impersonating',
          onClick: handleStopImpersonateClick,
        },
        {
          label: 'Create contact',
          onClick: handleOpenCreateContact,
        },
        {
          label: 'Cancel',
          onClick: () => setOpen(false),
        },
      ];
    }
    return [
      removeButton,
      {
        label: 'Create contact',
        onClick: handleOpenCreateContact,
      },
      {
        label: 'Cancel',
        onClick: () => setOpen(false),
      },
    ];
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)} sx={{ color: 'common.white' }}>
        <Badge
          key={callListCount}
          color="secondary"
          badgeContent={callListCount}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          overlap="circular"
          showZero={false}
        >
          <PhoneIcon />
        </Badge>
      </IconButton>
      {open && (
        <CustomDialog
          maxWidth="lg"
          minHeight="auto"
          openState={[open, setOpen]}
          onClose={() => setOpen(false)}
          title={`Call List from ${currentQueryUserName}`}
          buttons={{
            text: confirmDelete ? 'Are you sure?' : undefined,
            buttons: getButtons(),
          }}
        >
          {(isDesktop || !confirmDelete) && (
            <CreateExternalContact
              openDialogState={[openCreateContact, setOpenCreateContact]}
            />
          )}
          {callListCount < 1 ? (
            'No contacts to call...'
          ) : isDesktop ? (
            <CallListTable
              loadData={callListRows}
              salesPersonPublicId={currentUserQueryId}
              handleClickRedirection={handleClickRedirection}
              selectedState={[selected, setSelected]}
            />
          ) : (
            <MuiCallListPanelHelper
              loadData={callListRows as CallListTypeForCustomTable[]}
              salesPersonPublicId={currentUserQueryId}
              handleClickRedirection={handleClickRedirection}
              addRowToSelected={addRowToSelected}
              isSelected={isSelected}
              isDeleteting={confirmDelete}
            />
          )}
        </CustomDialog>
      )}
    </>
  );
};

export default CallList;
