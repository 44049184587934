import { FetchResult } from 'apollo-link';
import { apolloClient } from '../../../../../utility/ApolloConfig';
import { addMyContactBTPToCallListMutation } from '../../../../../mutations/app/add.contact.BTP.to.call.list/add.contact.BTP.to.call.list.mutation';
import { addMyContactBTPToCallListInterface } from '../../../../../mutations/app/add.contact.BTP.to.call.list/add.contact.BTP.to.call.list.mutation.interface';
import { UpdatableCallList } from '../../interfaces';

export const apiAddMyContactBTPToCallList = (
  queryParams: UpdatableCallList
): Promise<addMyContactBTPToCallListInterface | null | undefined> => {
  const { updatableCallList } = queryParams;
  return apolloClient
    .mutate({
      mutation: addMyContactBTPToCallListMutation,
      variables: { updatableCallList },
    })
    .then(
      (response: FetchResult<addMyContactBTPToCallListInterface>) =>
        response.data
    );
};
