import { Typography } from '@mui/material';
import React from 'react';
import { CSA, CUSTOMIZED, FIXED, SKU } from './ShowCSA';
import { CSATypeProps } from './customerSelected/ShowCSAType';

export interface CSATitleProps extends CSATypeProps {
  selectedCustomerName?: string;
  selectedBrandName?: string;
}

const CSATitle = (props: CSATitleProps) => {
  const { assortmentType, selectedBrandName, selectedCustomerName } = props;

  let title: string = '';
  if (assortmentType === FIXED) {
    title = 'Fixed Assortments ';
  } else if (assortmentType === CUSTOMIZED) {
    title = 'Customized Assortment ';
  } else if (assortmentType === SKU) {
    title = 'SKU Assortments ';
  } else if (assortmentType === CSA) {
    title = 'Customer Specific Assortments';
  }
  if (selectedCustomerName && selectedCustomerName !== '') {
    title = `${title} - ${selectedCustomerName}`;
  }
  if (selectedBrandName && selectedBrandName !== '') {
    title = `${title} - ${selectedBrandName}`;
  }

  return <Typography variant="h1">{title}</Typography>;
};

export default CSATitle;
