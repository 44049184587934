import gql from 'graphql-tag';

export const CallListResultFragment = gql`
  fragment CallListResult on MyCallListResult {
    queryCount
    result {
      createdByUserName
      createdDate
      contactType
      contactTypeDisplayName
      callListRelationPublicId
      number
      name
      contactPublicId
      contactName
      entityPublicId
      contactPhoneNumber {
        publicId
        countryPublicId
        countryCode
        phoneNumber
        isPrimaryPhone
        changedBy
        changedDate
      }
      note
      lastCallDate
    }
  }
`;

export const getBTPCallListQuery = gql`
  query getBTPCallList {
    BTP_CALL_LIST: getMyCallList {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;
