const retry = async (promises: Array<Promise<any>>, retries: number) => {
  if (retries > 0) {
    try {
      promises.forEach(async (promise) => promise);
    } catch (e) {
      retry(promises, retries - 1);
    }
  }
};

export default retry;
