import { FetchResult } from 'apollo-link';
import { apolloClient } from '../../../../../utility/ApolloConfig';
import { addBTPToCallListMutation } from '../../../../../mutations/app/add.BTP.to.call.list/app.add.BTP.to.call.list.mutation';
import { addBTPToCallListInterface } from '../../../../../mutations/app/add.BTP.to.call.list/app.add.BTP.to.call.list.mutation.interface';
import { UpdatableCallList } from '../../interfaces';

export const apiAddBTPToCallList = (
  queryParams: UpdatableCallList
): Promise<addBTPToCallListInterface | null | undefined> => {
  const { updatableCallList } = queryParams;
  return apolloClient
    .mutate({
      mutation: addBTPToCallListMutation,
      variables: { updatableCallList },
    })
    .then((response: FetchResult<addBTPToCallListInterface>) => response.data);
};
