import React from 'react';
import EditNoteCallList from '../../notes/editCell/edit.note.call.list';

export const muiRenderEditableNoteColumnCallList = ({
  rowData,
  cellData,
}: {
  rowData: any;
  cellData: any;
}) => {
  return (
    <EditNoteCallList key={cellData} cellRow={rowData} cellData={cellData} />
  );
};
