import Transport from 'winston-transport';
import { datadogLogs } from '@datadog/browser-logs';
import config from '../../config/config';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const configSetting = config.getConfig();

class DataDogTransport extends Transport {
  datadogLogger: any;

  service: string;

  host: string;

  constructor(opts: any) {
    super(opts);
    datadogLogs.init(configSetting.dataDog);
    this.datadogLogger = datadogLogs.logger;
    this.datadogLogger.setLevel('debug');
    this.service = process.env.REACT_APP_SERVICE as string;
    this.host = process.env.REACT_APP_HOST as string;
  }

  log(info: any, callback: Function): void {
    const { level, message, context, timestamp } = info;

    const contextUser =
      context && context.user
        ? context.user
        : { userName: 'unknown', name: 'unknown' };
    let contextEmail;
    try {
      // eslint-disable-next-line prefer-destructuring
      contextEmail = context.email.split('@')[0];
    } catch (e) {
      contextEmail = 'unknown email';
    }

    const loggedMessage = `[${contextUser || contextEmail}]: ${message}`;
    if ((process.env.NODE_ENV as string) === 'production') {
      try {
        this.datadogLogger.log(
          loggedMessage,
          {
            context,
            timestamp: timestamp || new Date(),
            // TODO set ENV variables for host and service
            host: 'bestone-bi4-sales-salestool-ui', // configSetting.host,
            service: 'bestone-bi4-sales-salestool-ui', // configSetting.service,
          },
          level
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    } else if (isLocalhost) {
      // eslint-disable-next-line no-console
      console.log(loggedMessage);
    }
    setImmediate(() => {
      this.emit('logged', info);
    });

    // Perform the writing to the remote service
    callback();
  }
}

export default DataDogTransport;
