import { inspect } from 'util';
import { ApolloClient } from '@apollo/client';
import {
  FindRemindersRelatedToInput,
  Reminder,
} from '../../../services/reminders/types';
import { TimeRangeType } from '../../components/DateTime/TimeRange/Dropdown/types';
import {
  ALL_DAY,
  getDay,
  getHoursAndMinutes,
  loadValue,
} from '../../components/DateTime/TimeRange/util';
import { OutlookEvent } from '../../../services/outlook/types';
import {
  dateAdd,
  DateOptions,
  dateSub,
  diffInMinutes,
  isSameDay,
  TimeInterval,
  TIME_ZONE_NAME,
} from '../../../utility/DateTime';
import {
  RelationEntityType,
  RelationType,
} from '../../../queries/shared/types';
import { renderLabel } from '../../components/SelectDropdown';
import { Service } from '../../../services/reminders/services';
import OutlookService from '../../../services/outlook';
import { ShowRemindersInterface } from './types';
import {
  EventInterface,
  EventsInfo,
  EventViewType,
  OneDayEventsInterface,
} from './overview/views/types';
import { getDayId } from './overview/util';
import { EMPTY_STRING } from '../../../utility/stringUtils';
import logger from '../../../utility/ddLogger';

export const REMINDER_TYPE_COLOURS = {
  showroomAppointment: '#ffbb99',
  shopVisits: '#99ff99',
  others: '#99e6e6',
  digitalMeeting: 'rgb(240 182 230)',
};

export const DEFAULT_SUBJECT = 'Customer Reminder';
export const getDefaultReminder = (): Reminder => ({
  date: getDay(new Date()),
});
const MAX_LENGTH = 6;
export const parseNotes = (value?: string, maxLength: number = MAX_LENGTH) =>
  !value
    ? ''
    : value.length > maxLength
    ? `${value.substring(0, maxLength)}...`
    : value;

export const getStartAndEndDateTimes = (
  date: Date,
  time?: string
): TimeInterval => {
  const start = new Date(date);
  let end = null;
  const timeRange: TimeRangeType = loadValue(time);
  if (timeRange === ALL_DAY) {
    start.setHours(0, 0, 0, 0);
    end = new Date(start);
    end.setDate(start.getDate() + 1);
  } else {
    const hmStart = getHoursAndMinutes(timeRange.start);
    start.setHours(hmStart.hours, hmStart.minutes, 0, 0);
    end = new Date(date);
    const hmEnd = getHoursAndMinutes(timeRange.end);
    end.setHours(hmEnd.hours, hmEnd.minutes, 0, 0);
  }
  return {
    start,
    end,
  };
};

export const getCustomerPublicId = (reminder: Reminder): string | undefined => {
  if (!reminder.relations || !reminder.relations.length) {
    logger.log({
      level: 'error',
      message: `Unable to get reminder customer publicId. No relations found ${inspect(
        reminder
      )}`,
    });
    return EMPTY_STRING;
  }
  const btp = reminder.relations.find(
    (e) => e.entityType === RelationEntityType.BUSINESS_TRADING_PARTNER
  );
  if (!btp) {
    logger.log({
      level: 'error',
      message: `Unable to get reminder customer publicId from relations ${inspect(
        reminder.relations
      )}`,
    });
    return EMPTY_STRING;
  }
  return btp.entityPublicId || EMPTY_STRING;
};

export const getSubject = (reminder: Reminder, ownerName?: string) => {
  const owner = ownerName || reminder.relatedEntityName;
  return reminder.type
    ? `${renderLabel(Service.getTypeOptions(), reminder.type)} ${owner}`
    : `${DEFAULT_SUBJECT} ${owner}`;
};
export const getTitle = (reminder: Reminder, ownerName?: string) => {
  const owner = ownerName || reminder.relatedEntityName;
  return reminder.type
    ? `${owner} ${renderLabel(Service.getTypeOptions(), reminder.type)}`
    : `${owner} ${DEFAULT_SUBJECT}`;
};

export const getColor = (reminder: Reminder): string => {
  switch (reminder.type) {
    case 'showroom-appointment':
      return REMINDER_TYPE_COLOURS.showroomAppointment;
    case 'shop-visits':
      return REMINDER_TYPE_COLOURS.shopVisits;
    case 'digital-meeting':
      return REMINDER_TYPE_COLOURS.digitalMeeting;
    default:
      return REMINDER_TYPE_COLOURS.others;
  }
};

export const transformReminderToEvent = (
  reminder: Reminder,
  owner?: RelationType
): OutlookEvent => {
  const { start, end } = getStartAndEndDateTimes(reminder.date, reminder.time);

  const entityName = reminder.relatedEntityName
    ? reminder.relatedEntityName
    : owner
    ? owner.entityName || ''
    : '';

  const event: OutlookEvent = {
    isAllDay: start === end,
    start: {
      dateTime: start,
      timeZone: TIME_ZONE_NAME,
    },
    end: {
      dateTime: end,
      timeZone: TIME_ZONE_NAME,
    },
    subject: getSubject(reminder, entityName),
  };
  return event;
};

const resetDate = (view: string): Date => {
  return view === EventViewType.WEEK
    ? DateOptions.getFirstDayOfWeek()
    : view === EventViewType.MONTH
    ? DateOptions.getFirstDayOfMonth()
    : DateOptions.getStartOfTheDay();
};

export const calculateChanges = (view: string) => {
  return view === EventViewType.WEEK
    ? { weeks: 1 }
    : view === EventViewType.MONTH
    ? { months: 1 }
    : { days: 1 };
};

export const buildFilterForPageAndView = (
  currentFilter: ShowRemindersInterface,
  currentPage: number,
  newPage: number,
  newView: string
): ShowRemindersInterface => {
  const increment = newPage - currentPage;
  const changes = calculateChanges(newView); // days fortward or backwards
  let from;
  let to;
  if (newPage === 0) {
    from = resetDate(newView);
    to = dateAdd(from, changes);
  } else {
    // page forward or backwards
    from = currentFilter.from!;
    if (newView === EventViewType.MONTH) {
      if (from!.getDate() > 1) {
        // we need to fix the from as it is pointing to the previous month
        from = DateOptions.getFirstDayOfMonth(dateAdd(from, { months: 1 }));
      } // now, from points to 1st of <month>
    }
    if (increment > 0) {
      from = dateAdd(from, changes);
    } else {
      from = dateSub(from, changes);
    }
    to = dateAdd(from, changes); // increase the from with the right amount of days
  }
  if (newView === EventViewType.MONTH) {
    // from should point to start of the week where the month starts
    from = DateOptions.getFirstDayOfWeek(from);
    // to points to 1st of next month... if end-of-month === end-of-week
    to = isSameDay(DateOptions.getFirstDayOfWeek(to), to)
      ? dateSub(to, { days: 1 }) // we move "to" to last day of the month/week
      : DateOptions.getLastDayOfWeek(to); // to points somewhere not at the end-of-week
  }
  const newFilter: ShowRemindersInterface = {
    ...currentFilter,
    from,
    to,
  };
  return newFilter;
};

export const transformFilter = (
  filter: ShowRemindersInterface
): FindRemindersRelatedToInput => {
  const theFilter: FindRemindersRelatedToInput = {
    filter: {
      fromDueDate: filter.from!.toISOString(),
      toDueDate: filter.to!.toISOString(),
      relations: null,
    },
  };
  return theFilter;
};

export const getDateFromHour = (hour: number, date?: Date) => {
  const d = date ? new Date(date) : new Date();
  d.setHours(hour, 0, 0, 0);
  return d;
};

export const mapRemindersToEventsInfo = (reminders: Reminder[]): EventsInfo => {
  const ret: EventsInfo = {
    maxAllDayEvents: 1,
    events: {},
    titles: [],
    subtitles: [],
  };
  const setTitles = new Set();
  const setSubtitles = new Set();
  reminders.forEach((r: Reminder) => {
    const startEndTimes = getStartAndEndDateTimes(r.date, r.time);
    const duration = diffInMinutes(startEndTimes.end, startEndTimes.start);
    const event = {
      id: r.publicId!,
      title: r.relatedEntityName,
      duration,
      color: getColor(r),
      isAllDay: !r.time,
      subtitle: renderLabel(Service.getTypeOptions(), r.type!),
      description: r.notes,
      ...startEndTimes,
    } as EventInterface;
    setTitles.add(event.title);
    setSubtitles.add(event.subtitle);
    const dayID = getDayId(event.start);
    let todaysEvents: OneDayEventsInterface = ret.events[dayID];
    if (!todaysEvents) {
      todaysEvents = {
        allDay: [],
        timed: [],
      } as OneDayEventsInterface;
    }
    if (event.isAllDay) {
      todaysEvents.allDay.push(event);
    } else {
      todaysEvents.timed.push(event);
    }
    if (todaysEvents.allDay.length > ret.maxAllDayEvents) {
      ret.maxAllDayEvents = todaysEvents.allDay.length;
    }
    ret.events[dayID] = todaysEvents;
    return event;
  });
  ret.titles = Array.from(setTitles) as string[];
  ret.subtitles = Array.from(setSubtitles) as string[];
  return ret;
};

export const updateBusySlots = async (
  date: Date,
  apolloClient: ApolloClient<object>
): Promise<void> => {
  const day: Date = new Date(date);
  // const rt: ReservedTime = Service.getBusySlots();
  // if (rt && rt.day === day) return; // don't need to request it again
  const outlook_events: Array<OutlookEvent> =
    await OutlookService.getEventsForADate(day, apolloClient);
  if (outlook_events.length === 0) {
    Service.setBusySlots(day, []);
    // } else if (outlook_events.some((e) => e.isAllDay === true)) {
    //   Service.setBusySlots(day);
  } else {
    const events: Array<TimeInterval> = outlook_events.map(
      (e: OutlookEvent) => {
        const start = DateOptions.parse(
          e.start.dateTime.toISOString()
        ).toDate();
        const end = DateOptions.parse(e.end.dateTime.toISOString()).toDate();
        return {
          start,
          end,
        };
      }
    );
    Service.setBusySlots(day, events);
  }
};
