import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Notification from './Notification';
import { rootStateInterface } from '../../../../reducers';
import { INotification } from '../../../../reducers/shared/bestone.common.reducer';
import { RESET_NOTIFICATION } from '../../../../constants/shared/bestone.global.constants';

// import { doResetAll } from 'Redux/Actions/app';

const Notifications: FC = () => {
  const dispatch = useDispatch();
  const onNotificationClose = (notificationId: string) => {
    dispatch({ type: RESET_NOTIFICATION, payload: notificationId });
  };

  const notifications = useSelector<rootStateInterface, Array<any> | null>(
    (state) => state.application.shared.bestoneCommon.notifications
  );

  return !notifications
    ? null
    : notifications.map((notification: INotification) => (
        <Notification
          opened
          key={notification.id}
          variant={notification.type}
          message={
            // TODO: use shape {id: message-id, defaultMessage: ''} to translate
            typeof notification.data.message === 'string'
              ? {
                  id: notification.data.message,
                  defaultMessage: notification.data.message,
                }
              : notification.data.message
          }
          values={notification.data.values}
          details={notification.data.details}
          onClose={() => onNotificationClose(notification.id)}
          autoHideDuration={notification.duration || null}
        />
      ));
};

export default Notifications;
