import { AnyAction, Reducer } from 'redux';

import {
  BESTONE_AUTH_PENDING,
  BESTONE_AUTH_PENDING_DONE,
  BESTONE_AUTH_PENDING_FAILED,
  BESTONE_AUTH_GET_USER_INFO_PENDING,
  BESTONE_AUTH_GET_USER_INFO_PENDING_FAILED,
} from '../../constants/shared/bestone.auth.constants';
import { BESTONE_SPLASHSCREEN_LOGIN_PENDING_DONE } from '../../constants/shared/bestone.splashscreen.constants';
import { BESTONE_LOGIN_PAGE_UNLOADED } from '../../constants/shared/bestone.login.page.constants';

export interface bestOneLoginPageStateInterface {
  inProgress: boolean;
  inProgressGetUserInfo: boolean;
  email: string;
  password: string;
  rememberMe: boolean;
  error?: string | Error;
  errorType?: string;
}

export const defaultState: bestOneLoginPageStateInterface = {
  inProgress: false,
  inProgressGetUserInfo: false,
  email: '',
  password: '',
  rememberMe: true,
};

export const bestoneLoginPageReducer: Reducer<
  bestOneLoginPageStateInterface | {}
  // eslint-disable-next-line default-param-last
> = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case BESTONE_SPLASHSCREEN_LOGIN_PENDING_DONE:
      return { ...state, inProgress: false, error: null, errorType: null };
    case BESTONE_AUTH_PENDING:
      return { ...state, inProgress: true, error: null, errorType: null };
    case BESTONE_AUTH_PENDING_DONE:
      return { ...state, inProgress: false, error: null, errorType: null };
    case BESTONE_AUTH_PENDING_FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload.error,
        errorType: action.payload.errorType,
      };
    case BESTONE_AUTH_GET_USER_INFO_PENDING:
      return {
        ...state,
        inProgressGetUserInfo: true,
        error: null,
        errorType: null,
      };
    case BESTONE_AUTH_GET_USER_INFO_PENDING_FAILED:
      return {
        ...state,
        inProgressGetUserInfo: false,
        error: action.payload.error,
        errorType: action.payload.errorType,
      };
    case BESTONE_LOGIN_PAGE_UNLOADED:
      return { ...defaultState };
    default:
      return state;
  }
};
