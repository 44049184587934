import { INotificationSimple } from '../../../../reducers/shared/bestone.common.reducer';
import { hashCode } from '../string';

const GENERIC_ERROR_MESSAGE =
  'Something went wrong, please contact IT support if it happens again';

export const createErrorNotification = (e?: any, message?: string) => {
  const notif: INotificationSimple = {
    id: `error-notification-${hashCode(message)}`,
    type: 'error',
    duration: 6000,
    message: buildMessage(message),
    details: e,
  };
  return notif;
};
export const createWarningNotification = (message: string, id?: string) => {
  const notif: INotificationSimple = {
    id: `warning-notification-${id || hashCode(message)}`,
    type: 'warning',
    duration: 6000,
    message,
  };
  return notif;
};
export const createInfoNotification = (message: string, id?: string) => {
  const notif: INotificationSimple = {
    id: `info-notification-${id || hashCode(message)}`,
    type: 'info',
    duration: 6000,
    message,
  };
  return notif;
};
export const createSuccessNotification = (message: string, id?: string) => {
  const notif: INotificationSimple = {
    id: `success-notification-${id || hashCode(message)}`,
    type: 'success',
    duration: 6000,
    message,
  };
  return notif;
};

const buildMessage = (message?: string) => {
  return message || GENERIC_ERROR_MESSAGE;
};
