import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  Slide,
  Snackbar,
  SnackbarContent,
  SxProps,
} from '@mui/material';
import { useVariantStyles } from './styles';
import variantIcons from './variants';

const TransitionUp = (props: any) => {
  return <Slide {...props} direction="up" />;
};

type MessageType = {
  id: string;
  defaultMessage: string;
};

type NotificationType = {
  classes?: { [k: string]: SxProps };
  variant: string;
  message: MessageType;
  values: Array<any> | null;
  details: any | undefined;
  onClose: () => any;
  autoHideDuration: number | null;
  position?: any;
};

const Notification: FC<NotificationType> = ({
  classes,
  message,
  details,
  onClose,
  variant,
  position: positionGiven,
  ...other
}) => {
  const position = positionGiven || { vertical: 'top', horizontal: 'center' };
  const variantClassName = useVariantStyles(variant);
  const Icon = variantIcons[variant];
  const theDetails = !details
    ? []
    : Array.isArray(details)
    ? details
    : typeof details === 'string'
    ? [`${details}`]
    : details instanceof Error
    ? [`${details.name}:${details.message}`]
    : Object.keys(details).map((d) => `${d}: ${details[d]}`);

  const handleClose = () => {
    onClose();
  };

  const renderMessage = (
    infoMessage: string | MessageType
  ): React.ReactNode => {
    if (typeof infoMessage === 'string') {
      return infoMessage;
    }
    if (typeof infoMessage === 'object' && 'defaultMessage' in infoMessage) {
      return infoMessage.defaultMessage || infoMessage.id;
    }
    return '';
  };

  return (
    <Snackbar
      open
      anchorOrigin={position}
      TransitionComponent={TransitionUp}
      sx={classes}
      onClose={handleClose}
      {...other}
    >
      <SnackbarContent
        sx={variantClassName}
        message={
          <Box component="span" sx={{ display: 'grid' }}>
            <Box
              component="span"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon sx={{ opacity: 0.9, marginRight: 1 }} />
              {renderMessage(message)}
            </Box>
            {theDetails.length > 0 && (
              <Box
                sx={{
                  marginTop: 1,
                }}
              >
                {theDetails.map((d) => (
                  <Box key={d} sx={{ fontStyle: 'italic' }}>
                    {d}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default Notification;
