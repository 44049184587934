import gql from 'graphql-tag';

export const getBrandingQuery = gql`
  query getBranding {
    BRAND: brands {
      queryCount
      result {
        publicId
        name
        shortName
        brandHousePublicId
      }
    }
    SUB_BRAND: subBrands {
      queryCount
      result {
        publicId
        name
        shortName
        brandPublicId
      }
    }
    PRODUCT_LINE: productLines {
      queryCount
      result {
        publicId
        productLineName
        shortName
        subBrandPublicId
        brandPublicId
      }
    }
  }
`;
