import { combineReducers } from 'redux';
import { sharedReducer, sharedStateInterface } from './shared';
import {
  appReducer,
  appStateInterface,
  appCustomersMainPageStateInterface,
} from './app';
import { bestOneCommonStateInterface } from './shared/bestone.common.reducer';
import { bestOneMainPageStateInterface } from './shared/bestone.main.page.reducer';
import { appShowShipTosPageStateInterface } from './app/shipTos/app.show.shipTos.page.reducer';
import { appShowBTPMycustomersPageStateInterface } from './app/businessTradingPartners/app.show.btp.mycustomers.page.reducer';
import { appShowBTPShipTosPageStateInterface } from './app/BTP.shipTos/app.show.btp.shipTos.page.reducer';
import { appShowBTPShipToDetailsPageStateInterface } from './app/BTP.shipTos.details/app.show.btp.shipTo.details.page.reducer';
import { bestOneCommonAuthStateInterface } from './shared/bestone.common.auth.reducer';
/*
    They are combined in the store found in the middleware
*/

export const rootReducer = combineReducers({
  shared: sharedReducer,
  app: appReducer,
});

export interface rootStateInterface {
  application: {
    shared: sharedStateInterface;
    app: appStateInterface;
  };
}

export const mapStateToPropsAppMainPage = (
  state: rootStateInterface
): appCustomersMainPageStateInterface => ({
  ...state.application.app.appMainPage,
  ...state.application.shared.bestoneMainPage,
  ...state.application.shared.bestoneCommon,
  currentUser: state.application.shared.bestoneCommon.currentUser,
  currentUserPublicId:
    state.application.shared.bestoneCommon.currentUserWholeData.sourceId,
});

export function mapStateToPropsBestOneCommon(
  state: rootStateInterface
): bestOneCommonStateInterface {
  return {
    ...state.application.shared.bestoneCommon,
  };
}

export type bestOneCommonQueryParamsStateInterface =
  bestOneCommonStateInterface &
    bestOneMainPageStateInterface &
    appShowShipTosPageStateInterface &
    appShowBTPMycustomersPageStateInterface;

export type bestOneCommonQueryParamsAndAuthStateInterface =
  bestOneCommonStateInterface &
    bestOneMainPageStateInterface &
    bestOneCommonAuthStateInterface &
    appShowShipTosPageStateInterface &
    appShowBTPMycustomersPageStateInterface;

export type bestOneProspectStateInterface = bestOneCommonStateInterface &
  bestOneMainPageStateInterface;

export function mapStateToPropsCommonBTP(
  state: rootStateInterface
): appShowBTPMycustomersPageStateInterface & bestOneCommonStateInterface {
  return {
    ...state.application.app.BTP,
    ...state.application.shared.bestoneCommon,
  };
}
export function mapStateToPropsCommonShipTo(
  state: rootStateInterface
): appShowShipTosPageStateInterface & bestOneCommonStateInterface {
  return {
    ...state.application.app.shipTos,
    ...state.application.shared.bestoneCommon,
  };
}

export function mapStateToPropsProspect(
  state: rootStateInterface
): bestOneProspectStateInterface {
  return {
    ...state.application.shared.bestoneCommon,
    ...state.application.shared.bestoneMainPage,
    ...state.application.app.prospect,
    ...state.application.app.branding,
  };
}
export function mapStateToPropsBestOneCommonWithQueryParamsAndAuth(
  state: rootStateInterface
): bestOneCommonQueryParamsAndAuthStateInterface {
  return {
    ...state.application.app.BTP,
    ...state.application.app.shipTos,
    ...state.application.shared.bestoneCommon,
    ...state.application.shared.bestoneCommonAuth,
    ...state.application.shared.bestoneMainPage,
  };
}
export function mapStateToPropsBestOneCommonWithQueryParams(
  state: rootStateInterface
): bestOneCommonQueryParamsStateInterface {
  return {
    ...state.application.app.BTP,
    ...state.application.app.shipTos,
    ...state.application.shared.bestoneCommon,
    ...state.application.shared.bestoneMainPage,
    ...state.application.app.branding,
  };
}

export type bestOneBTPAndShipToMainInterface =
  appShowShipTosPageStateInterface & appShowBTPMycustomersPageStateInterface;

export function mapStateToPropsBestOneBTPAndShipTos(
  state: rootStateInterface
): bestOneBTPAndShipToMainInterface {
  return {
    ...state.application.app.BTP,
    ...state.application.app.shipTos,
  };
}

export type bestOneCommonBTPShipToStateInterface =
  appShowBTPShipTosPageStateInterface &
    appShowBTPShipToDetailsPageStateInterface &
    bestOneMainPageStateInterface &
    bestOneCommonStateInterface;

export function mapStateToPropsBestOneCommonAndBTPShipTo(
  state: rootStateInterface
): bestOneCommonBTPShipToStateInterface {
  return {
    ...state.application.app.BTPShipTos,
    ...state.application.app.BTPShipToDetails,
    ...state.application.shared.bestoneCommon,
    ...state.application.shared.bestoneMainPage,
  };
}

export const mapStateToPropsBestOneMainPage = (
  state: rootStateInterface
): bestOneMainPageStateInterface => ({
  ...state.application.shared.bestoneMainPage,
});

export type bestOneTypeBestOneMainPageAndCommon =
  bestOneMainPageStateInterface & bestOneCommonStateInterface;

export const mapStateToPropsBestOneMainPageAndCommon = (
  state: rootStateInterface
): bestOneTypeBestOneMainPageAndCommon => ({
  ...state.application.shared.bestoneMainPage,
  ...state.application.shared.bestoneCommon,
});
