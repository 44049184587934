import { Reducer } from 'redux';
import { AuthenticationState } from 'react-aad-msal';
import { IdToken } from 'msal/lib-commonjs/IdToken';
import {
  BESTONE_AUTH_PENDING,
  BESTONE_AUTH_GET_USER_INFO_PENDING_DONE,
  BESTONE_AUTH_PENDING_FAILED,
  BESTONE_AUTH_LOGOUT_PENDING_DONE,
} from '../../constants/shared/bestone.auth.constants';
import { BESTONE_AUTH_MIDDLEWARE_INVALID_TOKEN } from '../../constants/shared/bestone.auth.middleware.constants';
import { Action } from '../../middleware/helpers/action.interface';
import {
  AAD_INITIALIZING,
  AAD_INITIALIZED,
  AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS,
  AAD_ACQUIRED_ID_TOKEN_SUCCESS,
  AAD_ACQUIRED_ACCESS_TOKEN_ERROR,
  AAD_LOGIN_FAILED,
  AAD_LOGIN_ERROR,
  AAD_LOGOUT_SUCCESS,
  AAD_AUTHENTICATED_STATE_CHANGED,
  AAD_ACQUIRED_ID_TOKEN_ERROR,
} from '../../constants/shared/auth.react-aad-msal.constants';

export interface bestOneCommonAuthStateInterface {
  authPending: boolean;
  loggedIn: boolean;
  loginError: boolean;
  tokenIsValid: boolean;
  pendingRefreshingToken: boolean;
  authToken?: null | string;

  aadInitializing: boolean;
  aadInitialized: boolean;
  idToken: IdToken | null;
  aadState: AuthenticationState;
}

const defaultState: bestOneCommonAuthStateInterface = {
  authPending: false,
  loggedIn: false,
  loginError: false,
  authToken: null,
  tokenIsValid: false,
  pendingRefreshingToken: false,
  aadInitializing: false,
  aadInitialized: false,
  idToken: null,
  aadState: AuthenticationState.Unauthenticated,
};

export const bestoneCommonAuthReducer: Reducer<
  bestOneCommonAuthStateInterface
  // eslint-disable-next-line default-param-last
> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case AAD_INITIALIZING:
      return {
        ...state,
        initializing: true,
        initialized: false,
      };
    case AAD_INITIALIZED:
      return {
        ...state,
        initializing: false,
        initialized: true,
      };
    case AAD_ACQUIRED_ID_TOKEN_SUCCESS:
      return {
        ...state,
        idToken: action.payload,
      };
    case AAD_LOGIN_FAILED:
      return {
        ...state,
      };
    case AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS:
    case AAD_ACQUIRED_ACCESS_TOKEN_ERROR:
    case AAD_LOGIN_ERROR:
    case AAD_ACQUIRED_ID_TOKEN_ERROR:
    case AAD_LOGOUT_SUCCESS:
      localStorage.removeItem('id_token');
      localStorage.removeItem('authorization_code');
      localStorage.removeItem('redirect_uri');

      return { ...state, idToken: null, account: null };
    case AAD_AUTHENTICATED_STATE_CHANGED:
      return {
        ...state,
        aadState: action.payload,
      };
    case BESTONE_AUTH_PENDING:
      return { ...state, authPending: true };
    case BESTONE_AUTH_GET_USER_INFO_PENDING_DONE:
      return {
        ...state,
        authPending: false,
        loggedIn: true,
        loginError: false,
        currentUser: action.payload?.currentUser?.userName || null,
      };
    case BESTONE_AUTH_PENDING_FAILED:
      return {
        ...state,
        authPending: false,
        loggedIn: false,
        loginError: true,
      };
    case BESTONE_AUTH_LOGOUT_PENDING_DONE:
      localStorage.removeItem('id_token');
      localStorage.removeItem('authorization_code');
      localStorage.removeItem('redirect_uri');

      return { ...state, authToken: null, refreshToken: null, loggedIn: false };
    case BESTONE_AUTH_MIDDLEWARE_INVALID_TOKEN:
      return { ...state, tokenIsValid: false };
    default:
      return state;
  }
};
