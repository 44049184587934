import { MiddlewareAPI, Dispatch, AnyAction } from 'redux';
import { Action } from './action.interface';
import {
  BESTONE_ASYNC_START,
  BESTONE_ASYNC_END,
} from '../../constants/shared/bestone.async.constrants';

export const promiseMiddleware =
  (store: MiddlewareAPI<any>) =>
  (next: Dispatch<AnyAction>) =>
  <A extends Action>(action: A) => {
    if (isPromise(action.payload) || isAFunction(action.payload)) {
      store.dispatch({ type: BESTONE_ASYNC_START, subtype: action.type });

      const promise = action.payload;
      if (isPromise(action.payload)) {
        action.payload.then(
          (res: any) => handleSuccessResponse(store, action, res),
          (error: any) => handleErrorResonse(store, action, error, promise)
        );
      } else {
        action.payload().then(
          (res: any) => handleSuccessResponse(store, action, res),
          (error: any) => handleErrorResonse(store, action, error, promise)
        );
      }
      return;
    }
    next(action);
  };

const handleSuccessResponse = (
  store: MiddlewareAPI<any>,
  action: Action,
  result: any
) => {
  const currentView = store.getState().viewChangeCounter;
  const { skipTracking } = action;
  const currentState = store.getState();
  if (!skipTracking && currentState.viewChangeCounter !== currentView) {
    return;
  }
  // eslint-disable-next-line no-param-reassign
  action.payload = result;
  store.dispatch({ type: BESTONE_ASYNC_END, promise: action.payload });
  store.dispatch(action);
};
const handleErrorResonse = (
  store: MiddlewareAPI<any>,
  action: Action,
  error: any,
  replayFunction: Function
) => {
  const currentView = store.getState().viewChangeCounter;
  const { skipTracking } = action;
  const currentState = store.getState();
  if (!skipTracking && currentState.viewChangeCounter !== currentView) {
    return;
  }
  const payloadObject: Action = { type: action.type };

  payloadObject.replay = replayFunction;
  payloadObject.error = true;
  if (error.response && error.response.data) {
    payloadObject.actionErrorType = 'serverResonseError';
    payloadObject.payload = error.response.data;
  } else {
    payloadObject.actionErrorType = 'serverConnectionError';
    payloadObject.payload = error;
  }
  store.dispatch({ type: BESTONE_ASYNC_END, payload: payloadObject });
  // if (!action.skipTracking) {
  //   store.dispatch({ type: BESTONE_ASYNC_END, promise: action.payload });
  // }
  // store.dispatch(action);
};

function isPromise(v: any) {
  return v && typeof v.then === 'function';
}
function isAFunction(v: any) {
  return v && typeof v === 'function';
}
