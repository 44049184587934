export const hashCode = (str?: string) => {
  if (!str) return '(empty)';
  let hash = 0;
  let i;
  let chr;
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
