import gql from 'graphql-tag';

export const getMarketQuery = gql`
  query getMarket {
    MARKET: markets {
      queryCount
      result {
        publicId
        marketName
        isFileCardActive
      }
    }
  }
`;
