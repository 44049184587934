import getBTPCallList from '../../../../../services/call.list/bestone.btp.call.list.services';
import addBTPtoCallList from '../../../../../services/shared/call.list/add.BTP.to.call.list/bestone.add.BTP.to.call.list.services';
import addShipToToCallList from '../../../../../services/shared/call.list/add.ship.to.to.call.list/bestone.add.ship.to.to.call.list.services';
import addMyContactBTPToCallList from '../../../../../services/shared/call.list/add.contact.BTP.to.call.list/bestone.add.contact.BTP.to.call.list.services';
import addContactShipToToCallList from '../../../../../services/shared/call.list/add.contact.ship.to.to.call.list/bestone.add.contact.ship.to.to.call.list.services';
import { SHOW_CALL_LIST } from '../../../../../constants/app/app.show.btp.mycustomers.page.constants';
import addExternalContactToCallListService from '../../../../../services/mutations/add.external.contact.to.call.list/bestone.add.external.contact.to.call.list.services';
import { UpdatableCallList } from '../../../../../services/shared/call.list/interfaces';
import addBTPToCallListInterface from '../../../../../mutations/app/add.BTP.to.call.list/app.add.BTP.to.call.list.mutation.interface';
import { addShipToToCallListInterface } from '../../../../../mutations/app/add.ship.to.to.call.list/app.add.ship.to.to.call.list.mutation.interface';
import { addMyContactBTPToCallListInterface } from '../../../../../mutations/app/add.contact.BTP.to.call.list/add.contact.BTP.to.call.list.mutation.interface';
import { addExternalContactToCallListInterface } from '../../../../../mutations/app/add.external.contact.to.call.list/add.external.contact.to.call.list.mutation.interface';

const addBTPtoCallListAndUpdateList = (
  dispatch: any,
  selectedBTP: UpdatableCallList
): void => {
  addBTPtoCallList(selectedBTP).then((result) => {
    dispatch({ type: SHOW_CALL_LIST, payload: result });
  });
};

const addShipToToCallListAndUpdateList = (
  dispatch: any,
  selectedShipTos: UpdatableCallList
): void => {
  addShipToToCallList(selectedShipTos).then((result: any) => {
    dispatch({ type: SHOW_CALL_LIST, payload: result });
  });
};

const addMyContactBTPToCallListAndUpdateList = (
  dispatch: any,
  contactFromBTP: UpdatableCallList
): void => {
  addMyContactBTPToCallList(contactFromBTP).then((result: any) => {
    dispatch({ type: SHOW_CALL_LIST, payload: result });
  });
};

const addContactShipToToCallListAndUpdateList = (
  dispatch: any,
  contactFromBTP: UpdatableCallList
): void => {
  addContactShipToToCallList(contactFromBTP).then((result: any) => {
    dispatch({ type: SHOW_CALL_LIST, payload: result });
  });
};

const addExternalContactToCallListAndUpdateList = (
  dispatch: any,
  externalContact: UpdatableCallList
): void => {
  addExternalContactToCallListService(externalContact).then((result) => {
    dispatch({ type: SHOW_CALL_LIST, payload: result });
  });
};

const updateBTPCallList = (dispatch: Function): void => {
  getBTPCallList().then((result) => {
    dispatch({ type: SHOW_CALL_LIST, payload: result });
  });
};

const addToCallList = (
  contactType: string,
  contactTypeDisplayName: string,
  updateParams: UpdatableCallList
) => {
  return new Promise((resolve, reject) => {
    let promiseBuilder: (
      queryParams: UpdatableCallList
    ) => Promise<
      | addBTPToCallListInterface
      | addShipToToCallListInterface
      | addMyContactBTPToCallListInterface
      | addExternalContactToCallListInterface
      | null
      | undefined
    >;
    const EMPTY = () => Promise.resolve(undefined); // Initial value - do nothing
    promiseBuilder = EMPTY;

    if (isBTP(contactType)) {
      promiseBuilder = addBTPtoCallList;
    } else if (isShipTo(contactType)) {
      promiseBuilder = addShipToToCallList;
    } else if (isContact(contactType)) {
      promiseBuilder = isBTP(contactTypeDisplayName)
        ? addMyContactBTPToCallList
        : isShipTo(contactTypeDisplayName)
        ? addContactShipToToCallList
        : isExternalContact(contactTypeDisplayName)
        ? addExternalContactToCallListService
        : EMPTY;
    }
    if (promiseBuilder) {
      promiseBuilder(updateParams).then((result: any) => {
        resolve(result);
      });
    } else {
      reject(new Error('Error trying to add to the call list'));
    }
  });
};

export const getContactType = (contactType: string) => {
  const normalize = contactType
    .toUpperCase()
    .replaceAll('_', '')
    .replaceAll(' ', '');
  return normalize;
};
export const isBTP = (contactType: string) => {
  const ctype = getContactType(contactType);
  return ctype === 'BUSINESSTRADINGPARTNER' || ctype === 'BTP';
};
export const isShipTo = (contactType: string) =>
  getContactType(contactType) === 'SHIPTO';
export const isProspect = (contactType: string) =>
  getContactType(contactType) === 'PROSPECT';
export const isContact = (contactType: string) =>
  getContactType(contactType) === 'CONTACT';
export const isExternalContact = (contactType: string) =>
  getContactType(contactType) === 'EXTERNALCONTACT';

export const toText = (item: any) => `${item.name} (${item.number})`;

export {
  updateBTPCallList,
  addToCallList,
  addBTPtoCallListAndUpdateList,
  addShipToToCallListAndUpdateList,
  addMyContactBTPToCallListAndUpdateList,
  addContactShipToToCallListAndUpdateList,
  addExternalContactToCallListAndUpdateList,
};
