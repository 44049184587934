import {
  apiSetUserImpersonate,
  impersonateQueryProps,
} from './api/bestone.user.impersonate.api';

const setUserImpersonate = (queryParams: impersonateQueryProps) => {
  return apiSetUserImpersonate(queryParams).then((response) => {
    return response;
  });
};

export default setUserImpersonate;
