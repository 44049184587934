import { Reducer } from 'redux';
import { ReactNode } from 'react';
import { Action } from '../../middleware/helpers/action.interface';

import {
  BESTONE_AUTH_LOAD_TOKEN_PENDING,
  BESTONE_AUTH_LOAD_TOKEN_DONE,
  BESTONE_AUTH_LOAD_TOKEN_DONE_NO_TOKEN,
  BESTONE_AUTH_LOAD_TOKEN_FAILED,
  BESTONE_AUTH_GET_USER_INFO_PENDING,
  BESTONE_AUTH_GET_USER_INFO_PENDING_DONE,
  BESTONE_AUTH_GET_USER_INFO_PENDING_FAILED,
  BESTONE_AUTH_LOGOUT_PENDING_DONE,
  BESTONE_AUTH_BEGIN_LOGIN_PENDING,
} from '../../constants/shared/bestone.auth.constants';

import { BESTONE_REDIRECT } from '../../constants/shared/bestone.redirect.constrants';
import {
  BESTONE_SPLASHSCREEN_LOGIN_PENDING,
  BESTONE_SPLASHSCREEN_LOGIN_PENDING_DONE,
} from '../../constants/shared/bestone.splashscreen.constants';
import {
  IMPERSONATE_USER,
  SHOW_ALL_COUNTRIES,
  CHECK_IS_DESKTOP_SCREEN_SIZE,
  IS_ONLINE,
  RESET_NOTIFICATION,
  SET_NOTIFICATION,
  SET_ROUTE_ID,
  SET_COUNTRY_CODE,
} from '../../constants/shared/bestone.global.constants';
import {
  SET_MY_PROFILE,
  SHOW_ALL_CURRENCIES,
} from '../../constants/shared/bestone.myProfile.constrants';
import {
  FIND_USER_TEXT_SEARCH,
  FIND_USER,
  CLEAR_USER_FIND_RESULT,
} from '../../constants/app/user.list.constants';
import {
  SHOW_CALL_LIST,
  NEW_EXTERNAL_CONTACT_FOR_CALL_LIST,
  RESET_NEW_EXTERNAL_CONTACT,
  SHOW_KEY_FIGURES,
} from '../../constants/app/app.show.btp.mycustomers.page.constants';
import { BESTONE_ASYNC_END } from '../../constants/shared/bestone.async.constrants';
import {
  AAD_INITIALIZED,
  AAD_INITIALIZING,
} from '../../constants/shared/auth.react-aad-msal.constants';
import { DateOptions } from '../../utility/DateTime';
import { CountryCode } from '../../application/Shared/_utilities/create.mycontact/mui.create.mycontacts';
import { CallListType } from '../../application/Shared/_utilities/call.list/CallList';
import { Currency } from '../../pages/prospects/fileCard/selectors/ExpectedYearlyTurnover';

export interface INotification {
  id: string;
  type: string;
  data: INotificationData;
  duration: number;
}
export interface INotificationData {
  message: string;
  values: Array<string> | null;
  details: any;
}
export interface INotificationSimple {
  message: string | ReactNode;
  id: string;
  details?: any;
  type: string;
  duration?: number;
}

export interface bestOneCommonStateInterface {
  appName: string;
  token: string | null;
  currentUser: string | null;
  currentUserSourceId: string;
  currentQueryUserName: string | null;
  queryUserMail: string | null;
  currentUserQueryId: any;
  currentUserWholeData: any;
  impersonating: boolean;
  currency?: Currency[];
  countries: any;
  columnSetUp: any;
  viewChangeCounter: number;
  bestone_getUserInfoInProgress: boolean;
  bestone_refreshTokenInProgress: boolean;
  bestone_showSpalshScreen: boolean;
  bestone_backgroundCheckAuthStateInProgress: boolean;
  bestone_backgroundLoginInProgress: boolean;
  bestone_checkAuthNoToken: boolean;
  bestone_checkAuthStatusInProgress: boolean;
  bestone_checkAuthFailed: boolean;
  bestone_getUserInfoError: boolean;
  bestone_refreshTokenFailed: boolean;
  bestone_redirect_to: any;
  bestone_redirect_params: any;
  userData: any[];
  userQueryCount: number;
  searchUserValue: string;
  callListRows: CallListType[];
  callListCount: number;
  newExternalContact: string;
  isDesktop: boolean;
  aadInitializing: boolean;
  isOnline: boolean;
  notifications: Array<INotification> | null;
  indexYTM: number;
  indexMonth: number;
  turnOverFYTM: number;
  financialIndexYearToDate: number;
  financialTotalYearToDate: number;
  routeId: string;
  countryCodeArray: Array<CountryCode>;
}

export const defaultState: bestOneCommonStateInterface = {
  appName: 'B4U - Best4You',
  token: null,
  currentUser: 'Local user',
  currentUserSourceId: '',
  currentQueryUserName: null,
  queryUserMail: null,
  currentUserQueryId: null,
  currentUserWholeData: {
    sourceId: '',
  },
  impersonating: false,
  currency: [],
  countries: [],
  columnSetUp: {},
  viewChangeCounter: 0,
  bestone_showSpalshScreen: false,
  bestone_backgroundLoginInProgress: false,
  bestone_backgroundCheckAuthStateInProgress: false,
  bestone_checkAuthNoToken: false,
  bestone_checkAuthStatusInProgress: false,
  bestone_refreshTokenInProgress: false,
  bestone_getUserInfoInProgress: false,
  bestone_checkAuthFailed: false,
  bestone_getUserInfoError: false,
  bestone_refreshTokenFailed: false,
  bestone_redirect_to: '',
  bestone_redirect_params: '',
  userData: [],
  userQueryCount: 0,
  searchUserValue: '',
  callListRows: [],
  callListCount: 0,
  newExternalContact: '',
  isDesktop: true,
  aadInitializing: false,
  isOnline: true,
  notifications: null,
  indexYTM: 0,
  indexMonth: 0,
  turnOverFYTM: 0,
  financialIndexYearToDate: 0,
  financialTotalYearToDate: 0,
  routeId: '',
  countryCodeArray: [],
};
export const bestoneCommonReducer: Reducer<bestOneCommonStateInterface> = (
  // eslint-disable-next-line default-param-last
  state = defaultState,
  action: Action
) => {
  switch (action.type) {
    case AAD_INITIALIZING:
      return {
        ...state,
        aadInitializing: true,
      };
    case AAD_INITIALIZED:
      return {
        ...state,
        aadInitializing: false,
      };
    case BESTONE_REDIRECT: {
      const url = action.payload.url ? action.payload.url : action.payload;
      const fixedUrl = url.match(/^\/(feature|bugfix)/)
        ? url
        : `${process.env.PUBLIC_URL}${url}`;
      return {
        ...state,
        bestone_redirect_to: fixedUrl,
        bestone_redirect_params: action.payload.state
          ? action.payload.state
          : '',
      };
    }
    case BESTONE_AUTH_BEGIN_LOGIN_PENDING:
      return {
        ...state,
        bestone_backgroundCheckAuthStateInProgress: false,
        bestone_backgroundLoginInProgress: true,
      };
    case BESTONE_SPLASHSCREEN_LOGIN_PENDING:
      return {
        ...state,
        bestone_showSpalshScreen: true,
        bestone_backgroundLoginInProgress: true,
      };
    case BESTONE_SPLASHSCREEN_LOGIN_PENDING_DONE:
      return {
        ...state,
        bestone_showSpalshScreen: false,
        bestone_backgroundLoginInProgress: false,
      };
    case BESTONE_AUTH_LOAD_TOKEN_PENDING:
      return {
        ...state,
        bestone_checkAuthStatusInProgress: true,
        bestone_checkAuthFailed: false,
      };
    case BESTONE_AUTH_LOAD_TOKEN_FAILED:
      return {
        ...state,
        bestone_checkAuthStatusInProgress: false,
        bestone_checkAuthFailed: true,
      };
    case BESTONE_AUTH_LOAD_TOKEN_DONE:
      return {
        ...state,
        bestone_checkAuthStatusInProgress: false,
        bestone_checkAuthFailed: false,
        bestone_checkAuthNoToken: false,
      };
    case BESTONE_AUTH_LOAD_TOKEN_DONE_NO_TOKEN:
      return {
        ...state,
        bestone_checkAuthStatusInProgress: false,
        bestone_checkAuthFailed: false,
        bestone_checkAuthNoToken: true,
      };
    case BESTONE_AUTH_GET_USER_INFO_PENDING:
      return {
        ...state,
        bestone_getUserInfoInProgress: true,
        bestone_getUserInfoError: false,
      };

    case BESTONE_AUTH_GET_USER_INFO_PENDING_DONE:
      return {
        ...state,
        bestone_getUserInfoInProgress: false,
        bestone_getUserInfoError: false,
        token: action.token || null,
        currentUser: action.payload?.currentUser?.userName || null,
        currentQueryUserName: action.payload
          ? action.payload.currentUser.queryUserName
          : null,
        currentUserQueryId: action.payload
          ? action.payload.currentUser.queryUserId
          : null,
        currentUserWholeData: action.payload
          ? action.payload.currentUser
          : null,
        impersonating: action.payload
          ? action.payload.currentUser.sourceId !==
            action.payload.currentUser.queryUserId
          : false,
        queryUserMail: action.payload
          ? action.payload.currentUser.queryUserMail
          : null,
      };
    case BESTONE_AUTH_GET_USER_INFO_PENDING_FAILED:
      return {
        ...state,
        bestone_getUserInfoInProgress: false,
        bestone_getUserInfoError: true,
      };
    case BESTONE_AUTH_LOGOUT_PENDING_DONE:
      return { ...state, redirectTo: '/', token: null, currentUser: null };
    case IMPERSONATE_USER: {
      return {
        ...state,
        currentUser: action.payload?.impersonateUser?.userName || null,
        currentQueryUserName: action.payload
          ? action.payload.impersonateUser.queryUserName
          : null,
        currentUserQueryId: action.payload
          ? action.payload.impersonateUser.queryUserId
          : null,
        currentUserWholeData: action.payload
          ? action.payload.impersonateUser
          : null,
        impersonating: action.payload
          ? action.payload.impersonateUser.sourceId !==
            action.payload.impersonateUser.queryUserId
          : false,
        queryUserMail: action.payload
          ? action.payload.impersonateUser.queryUserMail
          : null,
      };
    }
    case SET_MY_PROFILE: {
      DateOptions.init(action.payload.locale);
      return {
        ...state,
        myProfile: action.payload,
      };
    }
    case SHOW_ALL_CURRENCIES: {
      return {
        ...state,
        currency: action.payload ? action.payload.getCurrencies.result : null,
      };
    }
    case SHOW_ALL_COUNTRIES: {
      return {
        ...state,
        countries: action.payload
          ? action.payload.countriesByColumn.result
          : null,
      };
    }
    case FIND_USER_TEXT_SEARCH: {
      return {
        ...state,
        searchUserValue: action.payload,
      };
    }
    case FIND_USER: {
      return {
        ...state,
        userData: action.payload.users.result,
        userQueryCount: action.payload.users.queryCount,
      };
    }
    case CLEAR_USER_FIND_RESULT: {
      return {
        ...state,
        userData: [],
        searchUserValue: '',
      };
    }
    case SHOW_CALL_LIST: {
      const callListData = () => {
        let contactsData;
        if (action.payload.BTP_CALL_LIST) {
          contactsData = action.payload.BTP_CALL_LIST;
        } else if (action.payload.addBusinessTradingPartnerToCallList) {
          contactsData = action.payload.addBusinessTradingPartnerToCallList;
        } else if (action.payload.addShipToToCallList) {
          contactsData = action.payload.addShipToToCallList;
        } else if (action.payload.addMyContactBTPToCallList) {
          contactsData = action.payload.addMyContactBTPToCallList;
        } else if (action.payload.addContactShipToToCallList) {
          contactsData = action.payload.addContactShipToToCallList;
        } else if (action.payload.removeFromCallList) {
          contactsData = action.payload.removeFromCallList;
        } else if (action.payload.AddExternalContactToCallList) {
          contactsData = action.payload.AddExternalContactToCallList;
        }
        return contactsData;
      };
      if (action.payload != null) {
        return {
          ...state,
          callListRows: callListData().result,
          callListCount: callListData().queryCount,
        };
      }
      return state;
    }
    case NEW_EXTERNAL_CONTACT_FOR_CALL_LIST: {
      const contactPublicId =
        action.payload.CREATE_CONTACT.result[0] !== undefined
          ? action.payload.CREATE_CONTACT.result[0].contactPublicId
          : '';
      return {
        ...state,
        newExternalContact: contactPublicId,
      };
    }
    case RESET_NEW_EXTERNAL_CONTACT: {
      return {
        ...state,
        newExternalContact: '',
      };
    }
    case CHECK_IS_DESKTOP_SCREEN_SIZE: {
      return {
        ...state,
        isDesktop: action.payload,
      };
    }
    case IS_ONLINE: {
      return {
        ...state,
        isOnline: action.payload,
      };
    }
    case SET_NOTIFICATION: {
      const currentNotifications = state.notifications
        ? state.notifications
        : [];
      const { payload } = action;
      if (!payload.type) payload.type = payload.error ? 'error' : 'info';
      const newNotifData: INotificationData = {
        message: payload.message,
        values: null,
        details:
          process.env.NODE_ENV === 'development' ? payload.details : null,
      };
      const newNotif: INotification = {
        data: newNotifData,
        id: `notification-${payload.type}-${payload.id}`,
        type: payload.type,
        duration: payload.duration || 6000,
      };
      return {
        ...state,
        notifications: currentNotifications.concat(newNotif),
      };
    }
    case RESET_NOTIFICATION: {
      if (state.notifications) {
        const pos = state.notifications
          ? state.notifications.findIndex((e) => e.id === action.payload)
          : -1;
        if (pos > -1) {
          const notifications = [
            ...state.notifications.slice(0, pos),
            ...state.notifications.slice(pos + 1),
          ];
          return {
            ...state,
            notifications,
          };
        }
        // eslint-disable-next-line no-console
        console.error(
          'Unable to find index for notificationId: ',
          action.payload
        );
        return state;
      }
      // eslint-disable-next-line no-console
      console.error(
        'No notifications to delete. RESET_NOTIFICATIONS should not have been sent'
      );
      return state;
    }

    case BESTONE_ASYNC_END: {
      const { payload } = action;
      const isLocalDevelopment = process.env.NODE_ENV === 'development';
      if (payload && isLocalDevelopment) {
        const notifications = state.notifications ? state.notifications : [];
        const newNotifData: INotificationData = {
          message: payload.payload.message,
          values: null,
          details: action.payload,
        };
        const newNotif: INotification = {
          data: newNotifData,
          id: 'notif',
          type: payload.error ? 'error' : 'info',
          duration: 6000,
        };
        return {
          ...state,
          notifications: notifications.concat(newNotif),
        };
      }
      return state;
    }
    case SHOW_KEY_FIGURES: {
      const index =
        action.payload.myBusinessTradingPartnersSimpleKeyFigures.result[0];
      return {
        ...state,
        indexMonth: index?.indexMonth ? index.indexMonth : 0,
        financialIndexYearToDate: index?.financialIndexYearToDate
          ? index.financialIndexYearToDate
          : 0,
        financialTotalYearToDate: index?.financialTotalYearToDate
          ? index.financialTotalYearToDate
          : 0,
      };
    }
    case SET_ROUTE_ID:
      return {
        ...state,
        routeId: action.payload,
      };
    case SET_COUNTRY_CODE:
      return {
        ...state,
        countryCodeArray: action.payload,
      };
    default:
      return state;
  }
};
