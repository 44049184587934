import { Reducer } from 'redux';
import { BESTONE_SHOW_USER_SETTINGS_MENU_CLICKED } from '../../constants/shared/bestone.menu.clicked.constants';
import {
  BESTONE_AUTH_LOGOUT_PENDING_DONE,
  BESTONE_AUTH_GET_USER_INFO_PENDING_DONE,
} from '../../constants/shared/bestone.auth.constants';
import { Action } from '../../middleware/helpers/action.interface';

export interface bestOneMainPageStateInterface {
  currentUser: null | string;
  currentUserData: any;
  bestone_showImpersonateDialog: boolean;
  bestone_showApplicationLinkMenu: boolean;
  bestone_applicationLinkMenu: any;
  bestone_showUserSettingMenu: boolean;
  bestone_userSettingMenu: any;
  bestone_userSettingMenu_menuItems: any | null;
}

export const defaultState: bestOneMainPageStateInterface = {
  currentUser: null,
  currentUserData: null,
  bestone_showImpersonateDialog: false,
  bestone_showApplicationLinkMenu: false,
  bestone_applicationLinkMenu: null,
  bestone_showUserSettingMenu: false,
  bestone_userSettingMenu: null,
  bestone_userSettingMenu_menuItems: null,
};
export const bestOneMainPageReducer: Reducer<bestOneMainPageStateInterface> = (
  // eslint-disable-next-line default-param-last
  state = defaultState,
  action: Action
) => {
  // This is not completely accurate, as we can technically have these values set and have something
  // happen on the BFF that causes us to become unauthorized (e.g. the refresh token runs out), however
  // this is the best we can do at the moment and it'll be accurate in 95 % of cases
  const isAuthenticated =
    localStorage.getItem('id_token') &&
    localStorage.getItem('authorization_code');
  if (!isAuthenticated) {
    return { ...state, bestone_showUserSettingMenu: false };
  }
  switch (action.type) {
    case BESTONE_SHOW_USER_SETTINGS_MENU_CLICKED:
      return { ...state, bestone_showUserSettingMenu: action.payload };
    case BESTONE_AUTH_LOGOUT_PENDING_DONE:
      return { ...state, bestone_showUserSettingMenu: false };
    case BESTONE_AUTH_GET_USER_INFO_PENDING_DONE:
      return {
        ...state,
        currentUser: action.payload?.currentUser?.userName || null,
        currentUserData: action.payload ? action.payload.currentUser : null,
      };
    default:
      return state;
  }
};
