import { Action } from '../../../middleware/helpers/action.interface';
import {
  SHIPTOS_SELECTION_CHANGED,
  GET_SHIPTO_SCROLL_VALUE,
} from '../../../constants/app/app.show.shipTos.page.constants';

import {
  FILTER_SHIPTOS,
  RESET_FILTER_SHIPTOS,
} from '../../../constants/app/app.filtering.constants';
import { SHIPTOS_SEARCH_BY_NAME_AND_NUMBER } from '../../../constants/app/app.search.constants';
import { SHIPTO_SORT_BY_COLUMN_AND_DIRECTION } from '../../../constants/app/app.sortBy.constants';
import { REFRESH_WHEN_REGISTER_CALL } from '../../../constants/app/app.show.btp.mycustomers.page.constants';
import { ObjectLiteral } from '../../../application/Shared/_utilities/filtering/types';

export interface appShowShipTosPageStateInterface {
  shipTosSelectedRowIds: string[];

  skipValue: number;
  takeValue: number;

  filter: ObjectLiteral;
  fullFilter: ObjectLiteral;
  openFilter: boolean;

  textSearch: string;

  sortBy: string;
  sortDirection: string;

  ShipToScrollValue: number;

  lastCallFlag: boolean;
}

export const defaultState: appShowShipTosPageStateInterface = {
  shipTosSelectedRowIds: [],

  skipValue: 0,
  takeValue: 20,

  filter: {},
  fullFilter: { filter: {} },
  openFilter: false,

  textSearch: '',

  sortBy: 'name',
  sortDirection: 'ASC',

  ShipToScrollValue: 0,

  lastCallFlag: false,
};

// eslint-disable-next-line default-param-last
export const showShipTosReducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case SHIPTOS_SELECTION_CHANGED: {
      return {
        ...state,
        shipTosSelectedRowIds: action.payload,
      };
    }

    case FILTER_SHIPTOS: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case RESET_FILTER_SHIPTOS: {
      return {
        ...state,
        filter: {},
        fullFilter: { filter: {} },
      };
    }
    case SHIPTOS_SEARCH_BY_NAME_AND_NUMBER: {
      return {
        ...state,
        textSearch: action.payload,
      };
    }
    case SHIPTO_SORT_BY_COLUMN_AND_DIRECTION: {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    case GET_SHIPTO_SCROLL_VALUE: {
      const scrollYValue = action.payload;
      const takeScroll = state.takeValue + scrollYValue * 10;
      return {
        ...state,
        ShipToScrollValue: action.payload,
        takeValue: takeScroll,
      };
    }
    case REFRESH_WHEN_REGISTER_CALL: {
      const toggleFlag = state.lastCallFlag;
      const lastCall = () => {
        return !toggleFlag;
      };
      return {
        ...state,
        lastCallFlag: lastCall(),
      };
    }
    default:
      return state;
  }
};
