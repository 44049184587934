import gql from 'graphql-tag';
import { CallListResultFragment } from '../../../queries/shared/call.list/app.btp.call.list.query';

export const addMyContactBTPToCallListMutation = gql`
  mutation addMyContactBTPToCallList($updatableCallList: [UpdatableCallList]) {
    addMyContactBTPToCallList(updatableCallList: $updatableCallList) {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;
