import jwtDecode from 'jwt-decode';
import * as yup from 'yup';
import { ProspectPhone, NewContactPhone } from '../__generated__/globalTypes';

export const emailSchema = yup.object().shape({
  email: yup.string().strict().trim().email('Invalid email'),
});
export const nameSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

const digitsOnly = (value: any) => /^\d+$/.test(value);
export const phoneNumberSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required('Insert phone number')
    .test('Numbers only', 'Only numbers are allowed', digitsOnly),
});

export const countryCodeSchema = yup.object().shape({
  countryPublicId: yup.string().matches(/^(?!\s*$).+/, {
    message: 'Select country code',
    excludeEmptyString: false,
  }),
});

/* LEGACY for Material-table, use contactPhoneListValidation */
export const contactPhoneListSchema = yup.array().of(
  yup.object().shape({
    countryPublicId: yup.string().matches(/^(?!\s*$).+/, {
      message: 'Select country code',
      excludeEmptyString: false,
    }),
    phoneNumber: yup
      .number()
      .required('Insert phone number')
      .test('Numbers only', 'Only numbers are allowed', digitsOnly),
    publicId: yup.string(),
  })
);

export const contactPhoneListValidation = (
  contactPhoneList: NewContactPhone[] | (ProspectPhone | null)[]
) => {
  let completePhoneValidation: string = 'VALID';
  const phoneError: string[] = [];
  const countryError: string[] = [];

  contactPhoneList.forEach(
    (phone: NewContactPhone | (ProspectPhone | null), index: number) => {
      try {
        phoneNumberSchema.validateSync(phone);
        phoneError[index] = '';
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          phoneError[index] = error.message;
          completePhoneValidation = 'INVALID';
        }
      }
      try {
        countryCodeSchema.validateSync(phone);
        countryError[index] = '';
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          countryError[index] = error.message;
          completePhoneValidation = 'INVALID';
        }
      }
    }
  );
  return [completePhoneValidation, phoneError, countryError];
};

export const typeSelectSchema = yup.object().shape({
  type: yup.string().required('Required'),
});

export const NOTE_MAX_LENGTH = 2000;
export const noteSchema = yup
  .string()
  .max(NOTE_MAX_LENGTH, 'This note is too long')
  .test(
    'at-least-one-character',
    'This note must have at least one character',
    (value) => {
      if (!value) {
        return true;
      }
      const regex = /^\s*$/;
      return !regex.test(value);
    }
  );

export const PACKING_INSTRUCTIONS_MAX_LENGTH = 4000;
export const packingInstructionsSchema = yup
  .string()
  .max(PACKING_INSTRUCTIONS_MAX_LENGTH, 'This note is too long');

export const getForbiddenChars = (testValue: string) => {
  // eslint-disable-next-line no-control-regex
  const found = testValue.match(/([^\x00-\x7F]|')+/g);
  const foundArray = found?.flatMap((s: string) => s.split(''));
  return foundArray ? foundArray.join('') : '';
};

export const errorForbidden = (forbiddenChars: string) => {
  const verb = forbiddenChars.length > 1 ? 'are' : 'is';
  return `${forbiddenChars} ${verb} forbidden`;
};

export const isEmailEnable = (): boolean => {
  const emailsEnabled: string[] = [
    'maribel.moreno@bestseller.com',
    'taxiarchoula.mourmoura@bestseller.com',
    'rikke.byrgesen@bestseller.com',
    'rikke.byrgesen@Bestseller.com',
    'jacob.espenhain@bestseller.com',
  ];
  const idToken = localStorage.getItem('id_token') || '';
  const user: any = jwtDecode(idToken);
  return emailsEnabled.includes(user.email);
};
