import { apiAddExternalContactToCallList } from './api/bestone.add.external.contact.to.call.list.api';
import { UpdatableCallList } from '../../shared/call.list/interfaces';
import { addExternalContactToCallListInterface } from '../../../mutations/app/add.external.contact.to.call.list/add.external.contact.to.call.list.mutation.interface';

const addExternalContactToCallListService = (
  queryParams: UpdatableCallList
): Promise<addExternalContactToCallListInterface | null | undefined> => {
  return apiAddExternalContactToCallList(queryParams).then((response) => {
    return response;
  });
};

export default addExternalContactToCallListService;
