import gql from 'graphql-tag';

export const setImpersonateUserMutation = gql`
  mutation setImpersonateUser($emailValue: String!) {
    impersonateUser(email: $emailValue) {
      userName
      sourceId
      sourceDb
      sourceTable
      sourceAd
      environment
      queryUserId
      queryUserName
      queryUserMail
    }
  }
`;
