import gql from 'graphql-tag';
import { CallListResultFragment } from '../../../queries/shared/call.list/app.btp.call.list.query';

export const addExternalContactToCallListMutation = gql`
  mutation AddExternalContactToCallList(
    $updatableCallList: [UpdatableCallList]
  ) {
    AddExternalContactToCallList(updatableCallList: $updatableCallList) {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;
