import gql from 'graphql-tag';
import { CallListResultFragment } from '../../../queries/shared/call.list/app.btp.call.list.query';

export const removeContactsFromCallListMutation = gql`
  mutation RemoveContactsFromCallList($selectedContacts: [SelectedContacts]) {
    removeFromCallList(selectedContacts: $selectedContacts) {
      ...CallListResult
    }
  }
  ${CallListResultFragment}
`;
