import {
  apiGetBTPShipTos,
  BTPshipTosQueryProps,
} from './api/bestone.BTP.shipTos.api';

const getBTPShipTos = (queryParams: BTPshipTosQueryProps) => {
  return apiGetBTPShipTos(queryParams).then((response) => {
    return response;
  });
};

export default getBTPShipTos;
