import { apiAddContactShipToToCallList } from './api/bestone.add.contact.ship.to.to.call.list.api';
import { UpdatableCallList } from '../interfaces';
import { addShipToToCallListInterface } from '../../../../mutations/app/add.ship.to.to.call.list/app.add.ship.to.to.call.list.mutation.interface';

const addContactShipToToCallList = (
  queryParams: UpdatableCallList
): Promise<addShipToToCallListInterface | null | undefined> => {
  return apiAddContactShipToToCallList(queryParams).then((response) => {
    return response;
  });
};

export default addContactShipToToCallList;
