import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import { history } from './history';
import { subscriptionMiddleware } from './helpers/subscriptionMiddleware';
import { promiseMiddleware } from './helpers/promiseMiddleware';
import reducer from './helpers/combineApplicationReducers';

// Build the middleware for intercepting and dispatching navigation actions
const applcationReoutherMiddleware = createRouterMiddleware(history);

const getMiddleware = () => {
  if (process.env.REACT_APP_ENV !== 'development') {
    return applyMiddleware(
      subscriptionMiddleware,
      thunk,
      applcationReoutherMiddleware,
      promiseMiddleware
    );
  }
  // Enable additional logging in non-production environments.
  const logger = createLogger({
    predicate: (getState, action) => {
      switch (action.type) {
        default: {
          return true;
        }
      }
    },
    // ...other options
  });
  return applyMiddleware(
    subscriptionMiddleware,
    thunk,
    applcationReoutherMiddleware,
    promiseMiddleware,
    logger
  );
};

export const store = createStore(
  reducer(history),
  composeWithDevTools(getMiddleware())
);
