import React, { useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  TextField,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  DialogActions,
  Button,
} from '@mui/material';
import {
  IMPERSONATE_USER,
  SET_NOTIFICATION,
} from '../../../../constants/shared/bestone.global.constants';
import getUserList from '../../../../services/shared/user.list/bestone.user.list.services';
import { rootStateInterface } from '../../../../reducers';
import {
  RESET_BTP_DATA,
  SHOW_CALL_LIST,
  SHOW_KEY_FIGURES,
} from '../../../../constants/app/app.show.btp.mycustomers.page.constants';
import StopImpersonateMenu from './mui.stop.impersonate.menu';
import stopUserImpersonate from '../../../../services/shared/user.stop.impersonate/bestone.stop.user.impersonate.services';
import { INotificationSimple } from '../../../../reducers/shared/bestone.common.reducer';
import {
  userImpersonateInterface,
  startImpersonate,
  updateCallListAndFigures,
  stopImpersonate,
} from './impersonate.utils';
import retry from '../../../../utility/promise.utils';
import { createErrorNotification } from '../notifications/util';
import { User } from '../../../../@types/User/user.types';
import { userListqueryProps } from '../../../../services/shared/user.list/api/bestone.user.list.api';
import PathConstants from '../../../../routes/pathConstants';
import { getKeyFigures } from '../../../app/_CustomerMainPage/__generated__/getKeyFigures';

interface IStateProps {
  showImpersonateDialog: boolean;
  handleClose: () => void;
  userImpersonating: boolean;
  keyFiguresData: getKeyFigures | undefined;
}

// The refactor for this component will be completed ¡when the big refactor of impersonation will be done

const UserToImpersonateMenu: FC<IStateProps> = ({
  showImpersonateDialog,
  handleClose,
  userImpersonating,
  keyFiguresData,
}) => {
  const dispatch = useDispatch();

  const commonProps = useSelector((state: rootStateInterface) => ({
    ...state.application.shared.bestoneCommon,
  }));

  const impersonateUser = (payload: any) =>
    dispatch({ type: IMPERSONATE_USER, payload });

  const loadCallList = (payload: any) =>
    dispatch({ type: SHOW_CALL_LIST, payload });

  const keyFigures = (payload: any) =>
    dispatch({ type: SHOW_KEY_FIGURES, payload });

  const notifyError = (payload: INotificationSimple) =>
    dispatch({ type: SET_NOTIFICATION, payload });

  const resetBTPData = () => dispatch({ type: RESET_BTP_DATA });

  const impersonateFunctions: userImpersonateInterface = {
    impersonateUser,
    loadCallList,
    keyFigures,
  };

  const navigate = useNavigate();

  const [searchText, setSearchText] = useState<string | null>(null);
  const [userList, setUserList] = useState<User[]>([]);

  const onChangeText = (text: string) => {
    setSearchText(text);
    if (text !== '') {
      const queryParams = {
        searchValue: text,
        searchOnlyWithBTP: true,
        isIncludingMyself: false,
      };
      onUserSearch(queryParams);
    } else {
      setUserList([]);
    }
  };

  const onUserSearch = async (queryParams: userListqueryProps) => {
    try {
      const response = await getUserList(queryParams);
      setUserList(response.users.result);
    } catch {
      setUserList([]);
    }
  };

  const impersonate = async (userValue: User) => {
    const emailValue = {
      emailValue: userValue.email,
    };

    const previousEmailValue = {
      emailValue: commonProps.currentUserWholeData.queryUserMail,
    };
    let somethingSucceed: boolean = false;

    try {
      await startImpersonate(emailValue, impersonateFunctions);
      somethingSucceed = true;
      await updateCallListAndFigures(impersonateFunctions, keyFiguresData);
    } catch (e) {
      if (!somethingSucceed) {
        notifyError(createErrorNotification(new Error('Impersonation failed')));
      } else {
        if (userImpersonating) {
          retry(
            [
              stopImpersonate(impersonateFunctions),
              startImpersonate(previousEmailValue, impersonateFunctions),
              updateCallListAndFigures(impersonateFunctions, keyFiguresData),
            ],
            3
          );
        } else {
          retry(
            [
              stopImpersonate(impersonateFunctions),
              updateCallListAndFigures(impersonateFunctions, keyFiguresData),
            ],
            3
          );
        }
        notifyError(createErrorNotification(new Error('Impersonation failed')));
      }
    }
  };

  const handleClick = (userValue: User) => {
    if (userImpersonating) {
      stopUserImpersonate().then(() => {
        resetBTPData();
        impersonate(userValue);
      });
    } else {
      resetBTPData();
      impersonate(userValue);
    }
    handleClose();
    navigate(PathConstants.HOME);
  };

  const avatarLetters = (avatarName: string) => {
    const acronym = avatarName.split(/\s/).reduce((accumulator, word) => {
      return accumulator + word.slice(0, 1);
    }, '');
    return acronym.substring(0, 2).toUpperCase();
  };

  const renderStopImpersonate = () => {
    return userImpersonating ? (
      <StopImpersonateMenu
        handleClose={handleClose}
        notifyError={notifyError}
        impersonateUser={impersonateUser}
        loadCallList={loadCallList}
        keyFigures={keyFigures}
        keyFiguresData={keyFiguresData}
      />
    ) : null;
  };

  return (
    <Dialog open={showImpersonateDialog} onClose={() => handleClose()}>
      <TextField
        autoFocus
        sx={{ width: '80%', alignSelf: 'center' }}
        id="standard-search"
        label="Find user to impersonate"
        type="search"
        name="searchValue"
        value={searchText}
        margin="normal"
        onChange={(event) => onChangeText(event.target.value)}
      />
      <DialogContent sx={{ width: '26rem', height: '29rem' }}>
        <List>
          {userList.map((value: User) => (
            <ListItem
              key={value.publicId + value.name}
              onClick={() => handleClick(value)}
            >
              <ListItemAvatar>
                <Avatar>{avatarLetters(value.name)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                id={value.publicId}
                primary={value.name}
                secondary={value.title}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        {renderStopImpersonate()}
        <Button onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserToImpersonateMenu;
