import apiAddShipToToCallList from './api/bestone.add.ship.to.to.call.list.api';
import { UpdatableCallList } from '../interfaces';
import { addShipToToCallListInterface } from '../../../../mutations/app/add.ship.to.to.call.list/app.add.ship.to.to.call.list.mutation.interface';

const addShipToToCallList = (
  queryParams: UpdatableCallList
): Promise<addShipToToCallListInterface | null | undefined> => {
  return apiAddShipToToCallList(queryParams).then((response) => {
    return response;
  });
};

export default addShipToToCallList;
