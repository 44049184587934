import { ApolloQueryResult } from '@apollo/client';
import { apolloClient } from '../../../utility/ApolloConfig';
import { getBTPCallListQuery } from '../../../queries/shared/call.list/app.btp.call.list.query';
import { getBTPCallListQueryResultInterface } from '../../../queries/shared/call.list/app.btp.call.list.query.result.interface';

export const apiGetBTPCallList = () => {
  return apolloClient
    .query({
      query: getBTPCallListQuery,
    })
    .then(
      (response: ApolloQueryResult<getBTPCallListQueryResultInterface>) =>
        response.data
    );
};

export default apiGetBTPCallList;
