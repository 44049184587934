import gql from 'graphql-tag';

export const stopImpersonateUserMutation = gql`
  mutation stopImpersonateUser {
    stopImpersonateUser {
      userName
      sourceId
      sourceDb
      sourceTable
      sourceAd
      environment
      queryUserId
      queryUserName
      queryUserMail
    }
  }
`;
