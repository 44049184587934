import { ApolloQueryResult } from '@apollo/client';
import { apolloClient } from '../../../../../utility/ApolloConfig';
import { getMarketQuery } from '../../../../../queries/shared/organization/market/app.market.query';
import { getMarketQueryResultInterface } from '../../../../../queries/shared/organization/market/app.market.query.result.interface';

export const apiGetMarket = () => {
  return apolloClient
    .query({
      query: getMarketQuery,
    })
    .then(
      (response: ApolloQueryResult<getMarketQueryResultInterface>) =>
        response.data
    );
};
