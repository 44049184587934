import { FetchResult } from 'apollo-link';
import { apolloClient } from '../../../../../utility/ApolloConfig';
import { removeContactsFromCallListMutation } from '../../../../../mutations/app/remove.BTP.from.call.list/app.remove.BTP.from.call.list.mutation';
import { removeBTPFromCallListInterface } from '../../../../../mutations/app/remove.BTP.from.call.list/app.remove.BTP.from.call.list.mutation.interface';

export type SelectedContacts = {
  callListRelationPublicId: string;
  callListRelationName: string;
};

export const apiRemoveContactsFromCallList = (
  queryParams: SelectedContacts[]
) => {
  return apolloClient
    .mutate({
      mutation: removeContactsFromCallListMutation,
      variables: { selectedContacts: queryParams },
    })
    .then(
      (response: FetchResult<removeBTPFromCallListInterface>) => response.data
    );
};
