import setUserImpersonate from '../../../../services/shared/user.impersonate/bestone.user.impersonate.services';
import stopUserImpersonate from '../../../../services/shared/user.stop.impersonate/bestone.stop.user.impersonate.services';
import getBTPCallList from '../../../../services/call.list/bestone.btp.call.list.services';
import { getKeyFigures } from '../../../app/_CustomerMainPage/__generated__/getKeyFigures';

export interface userImpersonateInterface {
  impersonateUser: (userImpersonated: any) => any;
  loadCallList: (promisseAll: any) => any;
  keyFigures: (indexes: any) => any;
}

export const startImpersonate = async (
  emailValue: any,
  props: userImpersonateInterface
) => {
  const impersonated = await setUserImpersonate(emailValue);
  props.impersonateUser(impersonated);
};

export const updateCallListAndFigures = async (
  props: userImpersonateInterface,
  keyFiguresData: getKeyFigures | undefined
) => {
  const promiseResults = await Promise.all([getBTPCallList()]);
  props.loadCallList(promiseResults[0]);
  props.keyFigures(keyFiguresData);
};

export const stopImpersonate = async (props: userImpersonateInterface) => {
  const stopImpersonateResult = await stopUserImpersonate();
  if (stopImpersonateResult) {
    const payloadImpersonate = {
      impersonateUser: {
        userName: stopImpersonateResult.stopImpersonateUser.userName,
        queryUserName: stopImpersonateResult.stopImpersonateUser.queryUserName,
        queryUserId: stopImpersonateResult.stopImpersonateUser.queryUserId,
        sourceId: stopImpersonateResult.stopImpersonateUser.sourceId,
      },
    };
    props.impersonateUser(payloadImpersonate);
  }
};
