import { FetchResult } from 'apollo-link';
import { apolloClient } from '../../../../utility/ApolloConfig';
import { addExternalContactToCallListMutation } from '../../../../mutations/app/add.external.contact.to.call.list/add.external.contact.to.call.list.mutation';
import { addExternalContactToCallListInterface } from '../../../../mutations/app/add.external.contact.to.call.list/add.external.contact.to.call.list.mutation.interface';
import { UpdatableCallList } from '../../../shared/call.list/interfaces';

export const apiAddExternalContactToCallList = (
  queryParams: UpdatableCallList
): Promise<addExternalContactToCallListInterface | null | undefined> => {
  const { updatableCallList } = queryParams;
  return apolloClient
    .mutate({
      mutation: addExternalContactToCallListMutation,
      variables: { updatableCallList },
    })
    .then(
      (response: FetchResult<addExternalContactToCallListInterface>) =>
        response.data
    );
};
