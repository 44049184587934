import { combineReducers } from 'redux';
import {
  bestoneCommonReducer,
  bestOneCommonStateInterface,
} from './bestone.common.reducer';
import {
  bestoneLoginPageReducer,
  bestOneLoginPageStateInterface,
} from './bestone.login.page.reducer';
import {
  bestoneCommonAuthReducer,
  bestOneCommonAuthStateInterface,
} from './bestone.common.auth.reducer';
import {
  bestOneMainPageReducer,
  bestOneMainPageStateInterface,
} from './bestone.main.page.reducer';

export const sharedReducer = combineReducers({
  bestoneCommon: bestoneCommonReducer,
  bestoneLoginPage: bestoneLoginPageReducer,
  bestoneCommonAuth: bestoneCommonAuthReducer,
  bestoneMainPage: bestOneMainPageReducer,
});

export interface sharedStateInterface {
  bestoneCommon: bestOneCommonStateInterface;
  bestoneLoginPage: bestOneLoginPageStateInterface;
  bestoneCommonAuth: bestOneCommonAuthStateInterface;
  bestoneMainPage: bestOneMainPageStateInterface;
}
