const {
  REACT_APP_ENV,
  REACT_APP_CONFIG_BFF_URL,
  REACT_APP_CONFIG_SHARED_BFF_URL,
  REACT_APP_CONFIG_SHARED_SUBSCRIPTION_URL,
  REACT_APP_CONFIG_JAVA_BFF_URL,
  REACT_APP_CONFIG_JAVA_SUBSCRIPTION_URL,
  REACT_APP_PUBLIC_URL,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_TENANT_ID,
  REACT_APP_AUTH_SERVER,
  REACT_APP_AUTH_REDIRECT_URL,
  REACT_APP_MAIN_CHECK_INTERVAL,
  REACT_APP_MAIN_SYNC_INTERVAL,
  REACT_APP_DD_CLIENT_TOKEN,
  REACT_APP_HOST,
  REACT_APP_SERVICE,
  REACT_APP_APP_VERSION,
  REACT_APP_GOOGLE_MAPS_API_KEY,
} = process.env;

interface Config {
  env: string;
  appAreaName: string;
  publicUrl: string;
  bffUrl: string;
  sharedBffUrl: string;
  sharedSubscriptionUrl: string;
  javaBffUrl: string;
  javaSubscriptionUrl: string;
  auth: {
    authority: string;
    clientId: string;
    tenantId: string;
    scopes: Array<string>;
    redirectUri: string;
  };
  mainApp: {
    checkInterval: number;
    syncInterval: number;
  };
  dataDog: {
    clientToken: string;
    site: string;
    forwardErrorsToLogs: boolean;
    sampleRate: number;
    version: string;
  };
  host: string;
  service: string;
  googleMapsApiKey: string;
}

let configLoaded = false;
let config: Config;
const loadConfig = async () => {
  if (
    !REACT_APP_AUTH_CLIENT_ID ||
    !REACT_APP_AUTH_TENANT_ID ||
    !REACT_APP_AUTH_SERVER ||
    !REACT_APP_AUTH_REDIRECT_URL
  ) {
    throw new Error(
      'Missing required environment variables for authentication'
    );
  }
  if (
    !REACT_APP_CONFIG_BFF_URL ||
    !REACT_APP_CONFIG_SHARED_BFF_URL ||
    !REACT_APP_CONFIG_SHARED_SUBSCRIPTION_URL ||
    !REACT_APP_CONFIG_JAVA_BFF_URL ||
    !REACT_APP_CONFIG_JAVA_SUBSCRIPTION_URL
  ) {
    throw new Error('Missing required environment variables for bff urls');
  }

  config = {
    env: REACT_APP_ENV || 'production',
    appAreaName: 'SALES',
    publicUrl: REACT_APP_PUBLIC_URL || 'localhost:3001',
    bffUrl: REACT_APP_CONFIG_BFF_URL,
    sharedBffUrl: REACT_APP_CONFIG_SHARED_BFF_URL,
    sharedSubscriptionUrl: REACT_APP_CONFIG_SHARED_SUBSCRIPTION_URL,
    javaBffUrl: REACT_APP_CONFIG_JAVA_BFF_URL,
    javaSubscriptionUrl: REACT_APP_CONFIG_JAVA_SUBSCRIPTION_URL,
    auth: {
      authority: REACT_APP_AUTH_SERVER,
      clientId: REACT_APP_AUTH_CLIENT_ID,
      tenantId: REACT_APP_AUTH_TENANT_ID,
      scopes: ['openid', 'https://graph.microsoft.com/Calendars.ReadWrite'],
      redirectUri: REACT_APP_AUTH_REDIRECT_URL,
    },
    mainApp: {
      checkInterval: Number(REACT_APP_MAIN_CHECK_INTERVAL || 15000),
      syncInterval: Number(REACT_APP_MAIN_SYNC_INTERVAL || 40000),
    },
    dataDog: {
      clientToken: REACT_APP_DD_CLIENT_TOKEN as string,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      version: REACT_APP_APP_VERSION as string,
    },
    host: REACT_APP_HOST as string,
    service: REACT_APP_SERVICE as string,
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY as string,
  };

  configLoaded = true;
};

const getConfig = () => {
  if (configLoaded === false) {
    loadConfig();
  }
  return config;
};

export default {
  getConfig,
};
