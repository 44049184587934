import { createRouterReducer } from '@lagunovsky/redux-react-router';
import { combineReducers } from 'redux';

import { rootReducer } from '../../reducers';

// The import of the History type from 'history' kept
// giving us all sorts of problems. Also, the type is not
// really used for anything anyway, so I think it's okay
// to take the easy approach and replace it with any here
export default (history: any) =>
  combineReducers({
    application: rootReducer,
    router: createRouterReducer(history),
  });
