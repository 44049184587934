import { apiAddBTPToCallList } from './api/bestone.add.BTP.to.call.list.api';
import { UpdatableCallList } from '../interfaces';
import { addBTPToCallListInterface } from '../../../../mutations/app/add.BTP.to.call.list/app.add.BTP.to.call.list.mutation.interface';

const addBTPtoCallList = (
  queryParams: UpdatableCallList
): Promise<addBTPToCallListInterface | null | undefined> => {
  return apiAddBTPToCallList(queryParams).then((response) => {
    return response;
  });
};

export default addBTPtoCallList;
