import { createLogger, format, LogEntry } from 'winston';
import jwtDecode from 'jwt-decode';
import DataDogTransport from './dataDogTransport';

const { combine, timestamp, printf, json } = format;
const { NODE_ENV } = process.env;

const logFormat = printf(
  (info) => `${info.timestamp} [${info.level}]: ${info.message}`
);

// Log in JSON but if you are running locally use the nice readable format
const currentFormat = NODE_ENV === 'development' ? logFormat : json();
// const currentFormat = json();

const getLevel = (): 'debug' | 'warn' | 'info' => {
  switch (NODE_ENV) {
    case 'development':
      return 'debug';
    case 'test':
      return 'warn';
    default:
      return 'info';
  }
};

const dataDogTransport = new DataDogTransport({});
const realLogger = createLogger({
  level: getLevel(),
  format: combine(timestamp(), currentFormat),
  transports: [dataDogTransport],
  exceptionHandlers: [dataDogTransport],
});

export const privateLogger = async ({
  level,
  message,
  context: c,
  ...other
}: LogEntry): Promise<void> => {
  const context = c || {};

  const idToken = localStorage.getItem('id_token') || '';
  const user = jwtDecode(idToken) as unknown as any;
  const { userName } = user.preferred_username;

  try {
    realLogger.log({
      level,
      message,
      context: {
        environmentName: NODE_ENV,
        component: context.component || null,
        user: userName,
        error: context.error || null,
        response: context.response || null,
        ...context,
      },
      ...other,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Unable to log message: ${e}`);
  }
};

const logger = {
  log: (entry: LogEntry): Promise<void> => privateLogger(entry),
  logRedux:
    (store: any) =>
    (next: any) =>
    async (a: any): Promise<any> => {
      const action = a;
      const oldState = store.getState();
      const result = next(action);
      if (action.modalProps) {
        delete action.modalProps.anchorEl;
      }
      const newState = store.getState();

      try {
        realLogger.log({
          level: 'info',
          message: `[REDUX] - ${action.type}`,
          context: {
            environmentName: NODE_ENV,
            redux: {
              oldState,
              action,
              newState,
            },
          },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Unable to log message: ${e}`);
      }

      return result;
    },
};

export default logger;
