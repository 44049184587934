import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React, { FC, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';

type AccordionCustomProps = {
  accordionNameToExpand: string;
  accordionDetails: any;
  title: string;
  collapsedTitle?: string;
};

const AccordionCustom: FC<AccordionCustomProps> = ({
  accordionNameToExpand,
  accordionDetails,
  title,
  collapsedTitle,
}) => {
  const [expanded, setExpanded] = useState<string | false>(
    accordionNameToExpand
  );

  const toggle =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      expanded={expanded === accordionNameToExpand}
      onChange={toggle(accordionNameToExpand)}
      sx={{
        border: '1px solid rgb(0 0 0 / 5%)',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        borderRadius: '3px',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <Typography variant="h3" sx={{ mr: 3, fontWeight: 'bold' }}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3">
              {expanded === accordionNameToExpand ? undefined : collapsedTitle}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ maxHeight: '78vh', overflowY: 'auto' }}>
        {accordionDetails}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionCustom;
